import { t } from "i18next";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { Card } from "react-bootstrap";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const FrizdaAdsHistory = () => {
    const navigate = useNavigate();

    return (
        <div className="w-100 h-100">
            <Card className="business-dashboard-card">
                <div className="ad-history-header">
                    <FaArrowLeftLong
                        size={20}
                        color="#7A38FE"
                        cursor="pointer"
                        onClick={() => navigate(-1)}
                    />{" "}
                    Ad History
                </div>

                <div className="">
                    <DataTable value={[]} emptyMessage={t("no_data_found")} pt={{
                        column: {
                            bodyCell: {
                                style: {
                                    fontFamily: "'DM Sans', sans-serif",
                                    fontWeight: 600,
                                },
                            }
                        }
                    }}>
                        <Column field="adType" header="Ad Type" />
                        <Column field="paymentDate" align="center" header="Payment Date" />
                        <Column field="rolloutDate" align="center" header="Rollout Date" />
                        <Column field="rolloutTime" align="center" header="Rollout Time" />
                        <Column field="impactedUser" align="center" header="Impacted Users" />
                        <Column field="revenue" align="center" header="Revenue" bodyStyle={{ color: "#006D43"}} body={(data) => `+${data.revenue}%`} />
                    </DataTable>
                </div>
            </Card>
        </div>
    );
};

export default FrizdaAdsHistory;
