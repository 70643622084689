import moment from "moment";
import { InputSwitch } from "primereact/inputswitch";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPen } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import {
    ticketDanceClub,
    ticketDiscoBall,
    ticketNightClub,
} from "../../../assets/images";
import { ApiPut } from "../../../utils/ApiData";
import { TicketBackground } from "../../../utils/constants";

const TicketPreview = ({ ticket, showSuccess, isEditMode = false }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const [isEnabled, setIsEnabled] = useState(false);
    const [bgImage, setBgImage] = useState(null);

    const changeTicketAvailability = async (e) => {
        setIsEnabled(e.value);
        try {
            const ticketData = await ApiPut(`ticket/update/${ticket?.id}`, {
                ...ticket,
                isActive: ticket?.isActive ? false : true,
            });

            if (ticketData) {
                showSuccess(
                    "success",
                    t("tickets_module.ticket_status_updated_successfully")
                );
            }
        } catch (error) {
            showSuccess("danger", t("something_went_wrong"));
        }
    };

    useEffect(() => {
        if (ticket?.background === TicketBackground.drink) {
            setBgImage(ticketNightClub);
        } else if (ticket?.background === TicketBackground.bulb) {
            setBgImage(ticketDiscoBall);
        } else if (ticket?.background === TicketBackground.dance) {
            setBgImage(ticketDanceClub);
        }

        setIsEnabled(ticket?.isActive);
    }, [ticket]);

    return (
        <>
            <div className="ticket-preview-container">
                <div
                    className={`ticket-main ${
                        ticket?.theme ? ticket?.theme?.toLowerCase() : "default"
                    }-theme`}
                >
                    <p className="ticket-name">{ticket?.name || t("ticket")}</p>
                    <p className="ticket-date-vanue">
                        {(ticket?.eventDate &&
                            moment(ticket?.eventDate).format("DD/MM/YYYY")) ||
                            t("date")}
                    </p>
                    {bgImage ? (
                        <img
                            src={bgImage}
                            alt={ticket?.name}
                            className="ticket-bg-image"
                        />
                    ) : null}
                </div>
                <div className="ticket-separator"></div>
                <div
                    className={`ticket-extra ${
                        ticket?.theme ? ticket?.theme?.toLowerCase() : ""
                    }-extra`}
                >
                    {isEditMode && (
                        <>
                            <div
                                className="d-flex align-items-center justify-content-center gap-1 cursor-pointer"
                                onClick={() =>
                                    navigate(
                                        `/business/dashboard/tickets/edit/${ticket?.id}`
                                    )
                                }
                            >
                                <p>{t("EDIT")}</p>
                                <FaPen size={15} color="#545454" />
                            </div>
                            {ticket?.isNeverEnd ? (
                                <p
                                    style={{
                                        fontSize: "12px",
                                        textAlign: "center",
                                        fontFamily: "'DM Sans', sans-serif",
                                        color: "#000000",
                                    }}
                                >
                                    {ticket?.sellOn?.length > 0
                                        ? ticket?.sellOn
                                              .map((day) =>
                                                  day
                                                      ?.toUpperCase()
                                                      ?.slice(0, 3)
                                              )
                                              ?.join(", ")
                                        : ""}
                                </p>
                            ) : (
                                ticket?.sellingEndDate && (
                                    <div className="text-center">
                                        <p style={{ fontSize: "12px" }}>
                                            {t("end_date")}
                                        </p>
                                        <p>
                                            {(ticket?.sellingEndDate &&
                                                moment(
                                                    ticket?.sellingEndDate
                                                ).format("DD/MM/YYYY")) ||
                                                ""}
                                        </p>
                                    </div>
                                )
                            )}
                            <div className="ticket-extra-switch-wrapper">
                                <InputSwitch
                                    className="ticket-switch"
                                    checked={isEnabled}
                                    onChange={(e) =>
                                        changeTicketAvailability(e)
                                    }
                                />
                                <p
                                    className={`${
                                        isEnabled ? "" : "ticket-inactive"
                                    }`}
                                >
                                    {isEnabled ? t("enabled") : t("disabled")}
                                </p>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default TicketPreview;
