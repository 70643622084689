import React, { useEffect } from "react";


import Aos from 'aos';
import "aos/dist/aos.css";


import '../src/styling/main.css'; // ← Main CSS file
// import 'primereact/resources/themes/saga-blue/theme.css';  // Choose the theme you prefer
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import { Provider } from "react-redux";
import { store } from "./redux/store";
import AppRouter from "./routes/AppRouter";

// ↓ Data providers

export default function App() {

    useEffect(() => {
        Aos.init();
    }, []);

    return (
        <Provider store={store}>
            {/* <Router>
                <div>
                    <Routes>
                        <Route exact path='/' element={<HomePage />} />
                        <Route path='/map' element={<FrizdaMap />} />
                        <Route path='/signup' element={<SignUp />} />
                        <Route path='/login' element={<Login />} />
                        <Route path='/auth' element={<PostAuth />} />
                        <Route path='/business/*' element={<BusinessRoutes />} />
                    </Routes>
                </div>
            </Router> */}
            <AppRouter />
        </Provider>
    );
};
