import React, { useEffect, useRef } from 'react';

export default function DrinksPrice({ setDrinkPriceRating, drinkPriceRating, useMenu, setUseMenu, pdfValid, setPdfMenuValid, handleMenuUpload, pdfSrc, setSellsDrinks, sellsDrinks }) {

    const inputRef = useRef();

    useEffect(() => {
        if (!sellsDrinks) {
            setDrinkPriceRating(null);
        }
    }, [sellsDrinks]);

    useEffect(() => {
        if (!useMenu && inputRef.current) {
            inputRef.current.value = ""; // ← Clears the file input
        }

        !useMenu && setPdfMenuValid(true);
    }, [useMenu]);

    return (
        <div className='p-4 position-relative w-100 row'>
            <h5 className='d-flex justify-content-center'><i className='bi bi-cup-straw me-3' />Average drink price</h5>
            <p className='explanation-text'>As the owner, you will give the initial rating for the price of the drinks. The rating might get modified when users rate their price.</p>

            <div className='form-check px-3 pb-3'>
                <input className='form-check-input' id='sellsDrinksInput' type='checkbox' checked={sellsDrinks} onChange={() => setSellsDrinks(!sellsDrinks)} />
                <label>My business sells drinks and/or food</label>
            </div>

            <div className='col-5'>
                <div className='form-check'>
                    <input className='form-check-input' type='radio' id='one€Price' name='drinkPriceGroup' onClick={() => setDrinkPriceRating('€')} checked={drinkPriceRating === '€'} disabled={!sellsDrinks} />
                    <label className='form-check-label' htmlFor='one€Price'>€</label>
                </div>

                <div className='form-check'>
                    <input className='form-check-input' type='radio' id='two€Price' name='drinkPriceGroup' onClick={() => setDrinkPriceRating('€€')} checked={drinkPriceRating === '€€'} disabled={!sellsDrinks} />
                    <label className='form-check-label' htmlFor='two€Price'>€€</label>
                </div>

                <div className='form-check'>
                    <input className='form-check-input' type='radio' id='three€Price' name='drinkPriceGroup' onClick={() => setDrinkPriceRating('€€€')} checked={drinkPriceRating === '€€€'} disabled={!sellsDrinks} />
                    <label className='form-check-label' htmlFor='three€Price'>€€€</label>
                </div>

                <div className='form-check'>
                    <input className='form-check-input' type='radio' id='four€Price' name='drinkPriceGroup' onClick={() => setDrinkPriceRating('€€€€')} checked={drinkPriceRating === '€€€€'} disabled={!sellsDrinks} />
                    <label className='form-check-label' htmlFor='four€Price'>€€€€</label>
                </div>
            </div>
            <div className='col-7'>
                <label className='form-label d-flex' htmlFor='menuInput'>
                    Upload the menu
                    <div className='form-check form-switch d-flex align-items-center ms-2'>
                        <input
                            className='form-check-input'
                            role='switch'
                            type='checkbox'
                            id='opcionalMenu'
                            onClick={() => setUseMenu(!useMenu)}
                            checked={useMenu && sellsDrinks}
                            accept='.pdf'
                            disabled={!sellsDrinks}
                        />
                    </div>
                </label>
                <input
                    ref={inputRef} // Attach the ref to your file input
                    className={`${useMenu && sellsDrinks ? (!pdfValid ? 'is-invalid' : (pdfSrc ? 'is-valid' : '')) : ''} form-control`}
                    type='file'
                    id='menuInput'
                    onChange={handleMenuUpload}
                    required={useMenu && sellsDrinks}
                    disabled={!useMenu || !sellsDrinks}
                />

                <div className='form-text'>{useMenu && sellsDrinks ? (pdfValid ? 'PDF only' : <div className='set-text-to-red'>Select a valid PDF file.</div>) : ''}</div>
            </div>

        </div>
    )
}