import React, { useEffect, useRef, useState } from "react";
import {
    redsys,
    square,
    stripe,
    sumup,
    verifone,
} from "../../../../assets/images";
import { FaArrowRight, FaArrowRightLong } from "react-icons/fa6";
import "../settings.css";
import { useTranslation } from "react-i18next";
import { Toast } from "primereact/toast";
import { IoIosCloseCircle } from "react-icons/io";
import { ApiGet, ApiPost } from "../../../../utils/ApiData";
import { Modal } from "react-bootstrap";
import { DATA_PER_PAGE } from "../../../../utils/constants";
import SpinLoader from "../../../../components/home-page/loaders/SpinLoader";

const CardReadersTab = () => {
    const CARD_READERS = [
        // {
        //     id: 1,
        //     name: "Stripe",
        //     type: "STRIPE",
        //     image: stripe,
        // },
        {
            id: 2,
            name: "SumUp",
            type: "SUMUP",
            image: sumup,
            credsType: "API_KEY",
        },
        // {
        //     id: 3,
        //     name: "Redsys",
        //     type: "REDSYS",
        //     image: redsys,
        // },
        // {
        //     id: 4,
        //     name: "Square",
        //     type: "SQUARE",
        //     image: square,
        // },
        // {
        //     id: 5,
        //     name: "Verifone",
        //     type: "VERIFONE",
        //     image: verifone,
        // },
    ];

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [selectedCardReader, setSelectedCardReader] = useState([]);
    const [showCardReaderModal, setShowCardReaderModal] = useState(false);
    const [tempSelectedReader, setTempSelectedReader] = useState(null);

    const handleSelectCardReader = (reader) => {
        if (tempSelectedReader?.id === reader?.id) {
            setTempSelectedReader(null);
            return;
        }
        setTempSelectedReader(reader);
        // setShowCardReaderModal(true);
    };

    const handleApiSuccess = (reader) => {
        setSelectedCardReader([...selectedCardReader, reader]);
        setTempSelectedReader(null);
    };

    const getAllUserCardReaders = async () => {
        setIsLoading(true);
        try {
            const { data } = await ApiGet(
                `cardreader/get-all-by-user?page=1&limit=${DATA_PER_PAGE}`
            );
            if (data && data?.cardReaders) {
                let cardReaders = [];

                if (data?.cardReaders?.length > 0) {
                    data?.cardReaders?.map((item) => {
                        CARD_READERS.forEach((reader) => {
                            console.log(
                                reader?.type === item?.type,
                                reader,
                                item
                            );
                            if (
                                reader?.type?.toUpperCase() ===
                                item?.type?.toUpperCase()
                            ) {
                                cardReaders.push({
                                    id: reader?.id,
                                    type: reader?.type,
                                    name: reader?.name,
                                    image: reader?.image,
                                    credsType: reader?.credsType,
                                });
                            }
                        });
                    });
                }

                setSelectedCardReader(cardReaders);
                setIsLoading(false);
            }
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getAllUserCardReaders();
    }, []);

    return (
        <>
            {isLoading ? (
                <SpinLoader />
            ) : (
                <div className="card-readers-container">
                    <div className="active-card-readers-container">
                        <h3>{t("settings_module.card_readers_in_use")}</h3>
                        <div className="card-reader-brand-container">
                            {selectedCardReader.length > 0 &&
                                selectedCardReader.map((reader) => (
                                    <div
                                        key={reader.id}
                                        className="card-reader-brand active-card-reader-brand"
                                    >
                                        <img
                                            src={reader.image}
                                            alt={reader.name}
                                        />
                                    </div>
                                ))}
                        </div>
                    </div>

                    <div className="add-card-readers-container">
                        <h3>
                            {t("settings_module.select_brand_of_card_readers")}
                        </h3>
                        <div className="card-reader-brand-container">
                            {CARD_READERS.map((cardReader) => (
                                <div
                                    key={cardReader.id}
                                    className={`card-reader-brand ${
                                        tempSelectedReader?.id === cardReader.id
                                            ? "active-card-reader-brand"
                                            : ""
                                    }`}
                                    onClick={() =>
                                        handleSelectCardReader(cardReader)
                                    }
                                >
                                    <img
                                        src={cardReader.image}
                                        alt={cardReader.name}
                                    />
                                </div>
                            ))}
                            <button
                                className={`add-card-readers-btn ${
                                    tempSelectedReader === null
                                        ? "not-allowed"
                                        : ""
                                }`}
                                onClick={() => setShowCardReaderModal(true)}
                                disabled={tempSelectedReader === null}
                            >
                                {t("settings_module.add_card_btn")}{" "}
                                <FaArrowRightLong
                                    size={20}
                                    color="white"
                                    style={{ marginLeft: "10px" }}
                                />
                            </button>
                        </div>
                    </div>

                    <CardReaderDetails
                        showCardReaderModal={showCardReaderModal}
                        setShowCardReaderModal={setShowCardReaderModal}
                        selectedReader={tempSelectedReader}
                        onApiSuccess={handleApiSuccess}
                    />
                </div>
            )}
        </>
    );
};

export default CardReadersTab;

// Modal for adding card reader details
const CardReaderDetails = ({
    showCardReaderModal,
    setShowCardReaderModal,
    selectedReader,
    onApiSuccess,
}) => {
    const toastRef = useRef(null);
    const { t } = useTranslation();
    const [cardReaderAPIKey, setCardReaderAPIKey] = useState("");

    const AddCardReader = async (e) => {
        e.preventDefault();

        if (!cardReaderAPIKey) {
            toastRef.current.show({
                severity: "error",
                summary: "Error",
                detail: `${t("card_reader_modal.enter_card_reader_api_key")}!`,
                life: 2000,
            });
            return;
        }

        try {
            const data = {
                type: selectedReader?.type,
                credsType: selectedReader?.credsType,
                apiKey: cardReaderAPIKey,
            };

            const response = await ApiPost("cardreader/create", data);

            if (response) {
                toastRef.current.show({
                    severity: "success",
                    summary: "Success",
                    detail: `${t(
                        "card_reader_modal.card_reader_added_successfully"
                    )}`,
                    life: 2000,
                });

                setTimeout(() => {
                    onApiSuccess(selectedReader);
                    setShowCardReaderModal(false);
                    setCardReaderAPIKey("");
                }, 2000);
            }
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Error",
                detail: error.toString(),
                life: 3000,
            });
        }
    };

    return (
        <Modal
            show={showCardReaderModal}
            onHide={() => {
                setShowCardReaderModal(false);
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="card-reader-modal"
        >
            <Modal.Body>
                <div>
                    <p
                        className="redeem-modal-title"
                        style={{ color: "#555555" }}
                    >
                        {t("card_reader_modal.add_card_details", {
                            cardReaderName: selectedReader?.name,
                        })}
                    </p>

                    <form className="card-reader-data-form">
                        <div className="card-reader-input-container">
                            <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                                style={{ color: "#838383" }}
                            >
                                API Key
                            </label>
                            <input
                                value={cardReaderAPIKey}
                                onChange={(e) =>
                                    setCardReaderAPIKey(e.target.value)
                                }
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder={t(
                                    "card_reader_modal.enter_api_key"
                                )}
                            />
                        </div>

                        <button
                            type="submit"
                            className="redeem-amount-btn w-100 position-relative justify-content-center"
                            onClick={(e) => AddCardReader(e)}
                        >
                            <span>
                                {t("card_reader_modal.add_card_reader")}
                            </span>{" "}
                            <FaArrowRight
                                className="position-absolute"
                                style={{
                                    top: "50%",
                                    right: "20px",
                                    transform: "translateY(-50%)",
                                }}
                                size={20}
                            />
                        </button>
                    </form>
                </div>

                <div
                    className="position-absolute d-flex justify-content-center align-items-center cursor-pointer"
                    style={{ top: 10, right: 10 }}
                    onClick={() => setShowCardReaderModal(false)}
                >
                    <IoIosCloseCircle size={25} color="#7A38FE" />
                </div>
                <Toast ref={toastRef} position="top-right" />
            </Modal.Body>
        </Modal>
    );
};
