import React, { useEffect, useState } from 'react';
import '../styling/styles/authentication/authentication.css';

export default function PostAuth(props) {
    const [count, setCount] = useState(90);
    const [timeOut, setTimeOut] = useState(false);

    useEffect(() => {
        let interval = setInterval(() => {
            setCount((prevCount) => (prevCount > 0 ? prevCount - 1 : prevCount));
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []); // Empty dependency array to run the effect only once on mount

    useEffect(() => {
        if (count <= 1) {
            setTimeOut(true);
        }
    }, [count]);

    return (
        <div className='d-flex flex-column align-items-center justify-content-center vh-100' id='post-auth'>

            <a href='/'><h1 className='brand-post-auth'>Frizda</h1></a>

            <div className='card text-center col-lg-5' data-bs-theme='dark'>
                <div className='card-header'>Verify your Email address</div>
                <div className='card-body'>
                    <h5 className='card-title mb-5 mt-3'>We sent a 4 digit code to your inbox</h5>

                    <form>
                        <div className='d-flex gap-4'>
                            <input
                                type='text'
                                inputMode='numeric'
                                className='form-control'
                                autoComplete='off'
                                min={0}
                                max={9}
                                maxLength={1}
                            />
                            <input
                                type='text'
                                inputMode='numeric'
                                className='form-control'
                                autoComplete='off'
                                min={0}
                                max={9}
                                maxLength={1}
                            />
                            <input
                                type='text'
                                inputMode='numeric'
                                className='form-control'
                                autoComplete='off'
                                min={0}
                                max={9}
                                maxLength={1}
                            />
                            <input
                                type='text'
                                inputMode='numeric'
                                className='form-control'
                                autoComplete='off'
                                min={0}
                                max={9}
                                maxLength={1}
                            />
                        </div>

                        <button className='btn btn-outline-success mt-5'>Verify</button>
                    </form>
                </div>
                <div className='card-footer text-body-secondary'>
                    Code will expire in {count} second{count > 1 || count === 0 ? 's' : ''}.
                </div>
            </div>
        </div>
    );

}
