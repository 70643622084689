import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FaArrowUp, FaCalendar } from "react-icons/fa6";
import DatePicker from "react-datepicker";

const Standout = () => {

    const [startDate, setStartDate] = useState(null);

    return (
        <div className="frizda-ads-plan-card">
            <div className="">
                <div className="frizda-ads-plan-header">
                    <h4>Stand out From the Rest</h4>
                    <p>
                        31%
                        <FaArrowUp color="green" /> User Interest
                    </p>
                </div>
                <div className="frizda-ads-card-text">
                    <p>
                        Add a{" "}
                        <span style={{ color: "#7A38FE" }}>
                            sparkling effect
                        </span>{" "}
                        around your Nightspot marker on the Frizda Map, let
                        users know that your business is a Banger tonight
                    </p>
                </div>
            </div>

            <div className="frizda-standout-plan-content">
                <Row className="h-100 align-items-center">
                    <Col xs={12} md={8}>
                        <div className="w-100 h-100">
                            <div className="traffic-heatmap"></div>
                            <div className="heatmap-legend">
                                <p>More People</p>
                                <div className=""></div>
                                <p>Less People</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={4} className="d-flex flex-column align-items-center justify-content-end">
                        <div className="standout-date">
                            <label htmlFor="startDate">Select Date</label>
                            <div className="">
                                <FaCalendar color="#7A38FE" size={20} />
                                <DatePicker className="standout-datepicker" id="startDate" minDate={new Date()} placeholderText="DD/MM/YYYY" dateFormat="dd/MM/yyyy" selected={startDate} onChange={(date) => setStartDate(date)} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <p className="note-text" style={{ color: "#555555" }}>
                    *The sparkling effect will be active for 18 hrs, starting
                    from 12:00 of the selected date.{" "}
                </p>
            </div>

            <div className="frizda-ads-plan-footer">
                <p>€ 54.96</p>
                <button>Activate</button>
            </div>
        </div>
    );
};

export default Standout;
