import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { ReactComponent as Neutral } from '../../../assets/svg/netural.svg';
import PrimaryButton from '../components/PrimaryButton';
import SecondaryButton from '../components/SecondaryButton';
import '../dashboard.css';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const ToiletFacility = ({
    nextSlide,
    prevSlide,
    toiletOptions,
    setToiletModes,
    toiletModes,
    isFreeEntry,
    setIsFreeEntry,
    entranceFee,
    setEntranceFee,
    isGenderNeutralToiletAvailable,
    setIsGenderNeutralToiletAvailable,
    delta,
    errors,
    setValue,
    isEditMode,
    editModeSaveHandler,
    isMediumOrSmallScreen,
    apiLoading
}) => {
    const { t } = useTranslation();

    const toggleToiletMode = (id) => {
        setToiletModes(id);
    };

    const toggleIsGenderNeutralToiletAvailable = () => {
        if (toiletModes === 'available') {
            setIsGenderNeutralToiletAvailable(prev => !prev);
        } else {
            setIsGenderNeutralToiletAvailable(false);
        }
    };
    const handleFreeEntryChange = () => {
        setIsFreeEntry(!isFreeEntry);
        if (!isFreeEntry) {
            setEntranceFee({ euros: '', cents: '' });
        }
    };

    const handleFeeChange = (e, type) => {
        let value = e.target.value;
        if (type === 'cents' && value > 99) { // to ensure cents do not exceed 99
            value = 99;
        }
        setEntranceFee(prevFee => ({ ...prevFee, [type]: value }));
    };

    useEffect(() => {
        setValue('toilets.cost', (entranceFee.euros || '0') + '.' + (entranceFee.cents || '00'));
    }, [entranceFee]);

    useEffect(() => {
        setValue('toilets.free', isFreeEntry);
        if (isFreeEntry) {
            setValue('toilets.cost', '0.00');
        } else {
            setValue('toilets.cost', (entranceFee.euros || '0') + '.' + (entranceFee.cents || '00'));
        }
    }, [isFreeEntry]);

    useEffect(() => {
        setValue('toilets.genderNeutral', isGenderNeutralToiletAvailable);
    }, [isGenderNeutralToiletAvailable]);

    useEffect(() => {
        if (toiletModes === 'available') {
            setValue('toilets.available', true);
        } else {
            setIsFreeEntry(true);
            setIsGenderNeutralToiletAvailable(false);
            setEntranceFee({ euros: '', cents: '' });
            setValue('toilets.available', false);
        }
    }, [toiletModes]);

    return (
        <motion.div
            initial={{ x: delta >= 0 ? '10%' : '-10%', opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            className={`h-100  d-flex flex-column ${isEditMode ? 'edit-mode-padding' : 'py-4 nightspot-detail-side-padding'}`}
            style={{ animation: isEditMode ? 'none' : '' }}
        >
            <div className='d-flex flex-fill flex-column justify-content-around' style={{ minHeight: isEditMode ? '350px' : '' }}>
                {
                    !isEditMode &&
                    <div className='d-flex  justify-content-center mb-5'>
                        <span className='title text-black fs-4 '>{t('nightspot_details_module.toilet_facility')}</span>
                    </div>
                }

                <div className='d-flex flex-row flex-wrap justify-content-between w-100 h-50'>
                    {toiletOptions.map((option) => (
                        <div
                            key={option.id}
                            className={`payment-mode-type-option fw-bold ${toiletModes === (option.id) ? 'selected-payment-mode' : ''} `}
                            style={{ width: '48%', height: 60, cursor: 'pointer', fontSize: '16px' }}
                            onClick={() => toggleToiletMode(option.id)}
                        >
                            {option.icon(option.label)}
                            <span className={`text-secondary ms-3 ${toiletModes === (option.id) ? 'selected-payment-mode-text' : ''}`}>{option.label}</span>
                        </div>
                    ))}

                    <div
                        className={`payment-mode-type-option fw-bold ${isGenderNeutralToiletAvailable ? 'selected-payment-mode' : ''} ${toiletModes === 'not_available' ? 'disabled-payment-mode' : ''} w-100 `}
                        style={{ width: '47%', height: 60, cursor: 'pointer' }}
                        onClick={toggleIsGenderNeutralToiletAvailable}
                    >
                        <Neutral />
                        <span className={`text-secondary text-desc ms-4 ${isGenderNeutralToiletAvailable ? 'selected-payment-mode-text' : ''}`}>{t('nightspot_details_module.gender_neutral_toilets_available')}</span>
                    </div>
                </div>
                {/* Payment options */}
                <div className='d-flex h-100 flex-row w-100 align-items-center justify-content-between'>
                    <div>
                        <div className='mt-4'>
                            <input
                                type='radio'
                                className='form-check-input custom-radio'
                                checked={!isFreeEntry}
                                onChange={handleFreeEntryChange}
                                disabled={toiletModes === 'not_available'}
                            />
                            <span className='text-secondary fw-semibold ms-2' style={{ fontSize: '16px' }}>{t('nightspot_details_module.paid_toilets')}</span>
                        </div>
                        <div className='mt-4'>
                            <input
                                type='radio'
                                className='form-check-input custom-radio'
                                checked={isFreeEntry}
                                onChange={handleFreeEntryChange}
                                disabled={toiletModes === 'not_available'}
                            />
                            <span className='text-secondary fw-semibold ms-2' style={{ fontSize: '16px' }}>{t('nightspot_details_module.free_toilets')}</span>
                        </div>
                    </div>
                    <div className='w-50'>
                        <span className='text-black fs-6 fw-semibold'>{t('nightspot_details_module.entrance_fee')}</span>
                        <div className='d-flex flex-row mt-3'>
                            <div className='fee-payment-wrapper'>
                                <span className='fees-span'>€</span>
                                <input
                                    className='fees-input'
                                    value={entranceFee.euros}
                                    onChange={(e) => handleFeeChange(e, 'euros')}
                                    onKeyDown={(e) => { if (e.key === '.' || e.key === '-' || e.key === '+') e.preventDefault() }}
                                    disabled={isFreeEntry || toiletModes === 'not_available'}
                                    type='number'
                                >
                                </input>
                                <div className='dot-span'>
                                    <span style={{ color: '#000' }}>.</span>
                                </div>
                                <input className='fees-input-last'
                                    value={entranceFee.cents}
                                    onChange={(e) => handleFeeChange(e, 'cents')}
                                    onKeyDown={(e) => { if (e.key === '.' || e.key === '-' || e.key === '+') e.preventDefault() }}
                                    disabled={isFreeEntry || toiletModes === 'not_available'}
                                    type='number'
                                >
                                </input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="">
                {errors.toilets?.cost && <span className='input-error'>{errors.toilets.cost.message}</span>}
            </div>
            {
                !isMediumOrSmallScreen
                    ?
                    isEditMode ?
                        <div className="mt-2">
                            <button onClick={() => editModeSaveHandler(9)} className='edit-mode-save-btn'>{t('save')}</button>
                        </div>
                        :
                        <div className='d-flex flex-row justify-content-between'>
                            <SecondaryButton title={t('previous')} onClick={prevSlide} />
                            <PrimaryButton type='submit' title={'Finish'} loading={apiLoading} disabled={apiLoading} onClick={nextSlide} />
                        </div>
                    :
                    <></>
            }
        </motion.div>
    )
}

export default ToiletFacility