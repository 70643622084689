import React, { useEffect } from 'react';
import PrimaryButton from '../components/PrimaryButton';
import SecondaryButton from '../components/SecondaryButton';
import { bar, nightClub } from '../../../assets/images';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const nightspotTypeEnum = {
    Bar: 'Bar',
    Nightclub: 'Nightclub'
};

const NightspotType = ({
    nextSlide,
    prevSlide,
    setNightspotType,
    nightspotType,
    setSelectedType,
    delta,
    register,
    errors,
    setValue,
    isEditMode,
    editModeSaveHandler,
    isMediumOrSmallScreen
}) => {
    const { t } = useTranslation();

    // Bar types and club types
    const barType = [
        'Sports',
        'Live Music',
        'Cocktail',
        'Dive',
        'Pub',
        'Wine',
        'Tiki',
        'Karaoke',
        'Speakeasy',
        'Biker',
        'Beach',
        'LGBTQ+',
        'Women only',
        'Men only'
    ];

    const clubType = [
        'Dance',
        'Karaoke',
        'After Hours',
        'LGBTQ+',
        'Women only',
        'Men only',
        'Live Music',
        'Comedy',
        'Striptease',
        'Private',
        'Sports-Themed'
    ];

    // Determine the options based on the selected nightspot type
    const options = nightspotType === nightspotTypeEnum.Bar ? barType :
        nightspotType === nightspotTypeEnum.Nightclub ? clubType :
            [];

    // Handle selection change
    const handleOptionChange = (event) => {
        const value = event.target.value;
        setSelectedType(value);
        setValue('nightspotType.nightstpotOrientation', value);
    };

    useEffect(() => {
        // if (!isEditMode) {
        //     setValue('nightspotType.nightclub', true);
        // }
    }, []);

    return (
        <motion.div
            initial={{ x: delta >= 0 ? '10%' : '-10%', opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            className={`h-100 d-flex flex-column ${isEditMode ? 'edit-mode-padding' : 'py-4 nightspot-detail-side-padding'}`}
            style={{ animation: isEditMode ? 'none' : '' }}
        >
            <div className='d-flex flex-fill flex-column'>
                {
                    !isEditMode &&
                    <div className='d-flex justify-content-center mb-5'>
                        <span className='title text-black fs-4'>{t('nightspot_details_module.nightspot_type')}</span>
                    </div>
                }
                <div className='d-flex h-50 flex-row justify-content-between'>
                    <div
                        className={`position-relative image-wrapper-type border-secondary-subtle ${nightspotType === nightspotTypeEnum.Nightclub ? 'selected-nightspot-type' : 'border-secondary-subtle'}`}
                        onClick={() => {
                            setNightspotType(nightspotTypeEnum.Nightclub);
                            setValue('nightspotType.nightclub', true);
                            setSelectedType(''); // Reset selected type
                            setValue('nightspotType.nightstpotOrientation', '');
                        }}
                    >
                        <span className='nightspot-type'>{t('nightclub')}</span>
                        <img className='h-100 w-100 rounded' src={nightClub} alt='Night Club' />
                    </div>
                    <div
                        className={`position-relative image-wrapper-type border-secondary-subtle ${nightspotType === nightspotTypeEnum.Bar ? 'selected-nightspot-type' : 'border-secondary-subtle'}`}
                        onClick={() => {
                            setNightspotType(nightspotTypeEnum.Bar);
                            setValue('nightspotType.nightclub', false);
                            setSelectedType(''); // Reset selected type
                            setValue('nightspotType.nightstpotOrientation', '');
                        }}
                    >
                        <span className='nightspot-type'>{t('bar')}</span>
                        <img className='h-100 w-100 rounded' src={bar} alt='Bar' />
                    </div>
                </div>

                {/* Dropdown for Nightspot Options */}
                <div className='d-flex flex-column py-3 w-60 h-50'>
                    <select {...register('nightspotType.nightstpotOrientation')} className='form-select nightspot-type-select options-font' id="nightspotOptions" onChange={handleOptionChange}>
                        <option value="">Select {nightspotType === nightspotTypeEnum.Bar ? 'Bar' : 'Club'} Type</option>
                        {options.map(type => (
                            <option key={type} value={type}>{type}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="">
                {errors.nightspotType?.nightstpotOrientation && (
                    <span className='input-error'>
                        {errors.nightspotType.nightstpotOrientation.message}
                    </span>
                )}
            </div>
            {
                !isMediumOrSmallScreen
                    ?
                    isEditMode ?
                        <div className="mt-2">
                            <button onClick={() => editModeSaveHandler(4)} className='edit-mode-save-btn'>{t('save')}</button>
                        </div>
                        :
                        <div className='d-flex flex-row justify-content-between'>
                            <SecondaryButton title={t('previous')} onClick={prevSlide} />
                            <PrimaryButton type='submit' title={t('next')} onClick={nextSlide} />
                        </div>
                    :
                    <></>
            }
        </motion.div>
    );
};

export default NightspotType;
