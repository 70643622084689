import moment from "moment";
import React from "react";
import { FaArrowUp, FaBatteryFull, FaWifi } from "react-icons/fa6";

const EventFeed = () => {
    return (
        <div className="frizda-ads-plan-card">
            <div className="">
                <div className="frizda-ads-plan-header">
                    <h4>Your Event on Frizda Feed</h4>
                    <p>
                        148%
                        <FaArrowUp color="green" /> User Interest
                    </p>
                </div>
                <div className="frizda-ads-card-text">
                    <p>
                        Upload an <span style={{ color: "#7A38FE" }}>Ad</span>{" "}
                        (image, title, description) that will pop as soon as
                        users click on your business' profile. In order to boost
                        the ad, you can also choose to add a sparkling effect
                        around your nightspot marker, send a notification to
                        users around, or both!
                    </p>
                </div>
            </div>

            <div className="nightspot-feed-form">
                <div className="ads-mobile-display">
                    <div className="mobile-display-navbar-container">
                        <div className="mobile-time-indicator">
                            {moment(new Date()).format("HH:mm")}
                        </div>
                        <div className="float-island-navbar"></div>
                        <div className="mobile-battery">
                            <FaWifi size={15} /> <FaBatteryFull size={15} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="frizda-ads-plan-footer">
                <p>
                    € 255.36 <span style={{ fontSize: "0.9rem", color: "#A3A3A3" }}>€ 64.92</span>
                </p>
                <button>Activate</button>
            </div>
        </div>
    );
};

export default EventFeed;
