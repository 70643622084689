import React, { useEffect, useContext, useState, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { LoggedInContext } from "../businessAuthState";
import "../auth-components/auth-styles/signup.css"; // ← CSS file for comnponent
import { useNavigate } from "react-router-dom";

import logoImage from "../auth-components/auth-styles/images/frizdausual.png";
import googleImage from "../auth-components/auth-styles/images/google.png";
import hide from "../auth-components/auth-styles/images/email2.png";
import bullet from "../auth-components/auth-styles/svgs/bullet.svg";
import show from "../auth-components/auth-styles/images/eye.png";

import Spinner from "./auth-styles/spinners/Spinner";
import { ApiGet, ApiPost, ApiPostNoAuth } from "../../../utils/ApiData";
import { Toast } from "primereact/toast";
import { Trans, useTranslation } from "react-i18next";

export default function SignUp(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const toast = useRef(null);

    const [signupPassword, setsignupPassword] = useState("");
    const [confirmPassword, setconfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const [passwordsMatch, setPasswordsMatch] = useState(true);
    // ↓ For password requirements
    const [lacksLength, setLacksLength] = useState(false);
    const [lacksChar, setLacksChar] = useState(false);
    const [lacksUpper, setLacksUpper] = useState(false);
    const [lacksNumber, setLacksNumber] = useState(false);
    const [lacksLower, setLacksLower] = useState(false);

    function validatePassword(password) {
        // Reset all state variables
        setLacksLength(false);
        setLacksChar(false);
        setLacksUpper(false);
        setLacksNumber(false);
        setLacksLower(false);

        // Check password requirements
        if (password.length < 8) setLacksLength(true);
        else if (!/[!@#$%^&*]/.test(password)) setLacksChar(true);
        else if (!/[A-Z]/.test(password)) setLacksUpper(true);
        else if (!/[0-9]/.test(password)) setLacksNumber(true);
        else if (!/[a-z]/.test(password)) setLacksLower(true);
        else return true;

        return false;
    }

    async function submit(event) {
        event.preventDefault();

        const email = document.getElementById("emailBusiness").value;
        const password = document.getElementById("passwordBusiness").value;
        const confirmedPassword = document.getElementById(
            "passwordConfirmBusiness"
        ).value;
        let name = document.getElementById("nameBusiness").value;
        name = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();

        let lastName = document.getElementById("lastnameBusiness").value;
        lastName =
            lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase();

        const account = {
            // ownerAccount: true,
            firstName: name,
            lastName: lastName,
            email: email,
            password: password,
            // confirmedPassword: confirmedPassword
        };

        try {
            setShowLoader(true);

            if (signupPassword === confirmPassword) {
                setPasswordsMatch(true);
                const validationRestult = validatePassword(signupPassword);
                if (validationRestult) {
                    // axios.post('http://localhost:8000/createOwnerAccount', account)
                    const userData = await ApiPostNoAuth(
                        "user/signup",
                        account
                    );
                    if (userData) {
                        // const accountId = userData.data.insertId;
                        // setName(account.name);
                        // setLastName(account.lastname);
                        // setEmail(account.email);
                        // setAccountId(accountId)
                        // setLoggedIn(true);
                        // navigate('/business/check-email');
                        toast.current.show({
                            severity: "success",
                            summary: "Success",
                            detail: "Account created successfully",
                            life: 2000,
                        });
                        navigate("/business/login");
                    } else {
                        toast.current.show({
                            severity: "error",
                            summary: "Error",
                            detail: "Something went wrong",
                            life: 2000,
                        });
                    }
                }
            } else if (signupPassword !== confirmPassword) {
                setPasswordsMatch(false);
                toast.current.show({
                    severity: "error",
                    summary: "Error",
                    detail: "Passwords do not match",
                    life: 2000,
                });
            }
            setTimeout(() => setShowLoader(false), 1000);
        } catch (error) {
            setShowLoader(false);
            toast.current.show({
                severity: "error",
                summary: "Error",
                detail: error,
                life: 2000,
            });
        }
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
        setShowConfirmPassword(!showConfirmPassword);
    };

    const openGoogleSSO = async () => {
        const apiResponse = await ApiGet("user/google/get-auth-url");
        const googleSSOUrl = apiResponse?.data;

        if (apiResponse) {
            window.location.href = googleSSOUrl;
        }
    };

    return (
        <div className="business-signup-parent">
            <div className="business-signup-child">
                <div className="business-signup-content">
                    <div className="business-signup-left-div">
                        <img
                            src={logoImage}
                            alt="logo"
                            width={88}
                            className="signup-logo"
                        />
                        <h1 className="business-signup-heading">
                            {t("auth.create_owner_account")}
                        </h1>
                        <p className="signup-login-link">
                            {t("auth.already_member")}&nbsp;
                            <Link to="/business/login">
                                <span className="signup-link">
                                    {t("auth.login")}
                                </span>
                            </Link>
                        </p>
                        <div className="or-div">
                            <hr className="or-hr" />
                            <p className="or-para">OR</p>
                            <hr className="or-hr" />
                        </div>
                        <button
                            className="continue-with-google-div"
                            onClick={openGoogleSSO}
                        >
                            <img
                                src={googleImage}
                                width="19px"
                                height="19px"
                                alt="google"
                                className="google-image"
                            />

                            <p className="continue-with-google">
                                {t("auth.continue_with_google")}
                            </p>
                        </button>
                    </div>
                    <div className="business-signup-right-div">
                        <form className="signupform" onSubmit={submit}>
                            <div className="row gap-2">
                                <input
                                    className="signupinputname col"
                                    type="text"
                                    placeholder={t("auth.first_name")}
                                    id="nameBusiness"
                                    required
                                />
                                <input
                                    className="signupinputname col"
                                    type="text"
                                    placeholder={t("auth.last_name")}
                                    id="lastnameBusiness"
                                    required
                                />
                            </div>

                            <input
                                className="signupinputemail"
                                id="emailBusiness"
                                type="email"
                                placeholder={t("auth.email")}
                                required
                            />

                            <div
                                className="hide-div-signup"
                                onClick={togglePasswordVisibility}
                            >
                                <img
                                    src={showPassword ? show : hide}
                                    width="20px"
                                    height="20px"
                                    alt="hide"
                                />
                            </div>

                            <input
                                className="signupinputpass"
                                id="passwordBusiness"
                                placeholder={t("auth.password")}
                                type={showPassword ? "text" : "password"}
                                value={signupPassword}
                                onChange={(e) =>
                                    setsignupPassword(e.target.value)
                                }
                                required
                            />

                            <div className="bullet-parent-div">
                                <div className="bullet-group-1">
                                    <div className="bullet-div-upper">
                                        <img src={bullet} alt="" />
                                        <p
                                            className={`bullet-para ${
                                                lacksLength && "text-red"
                                            }`}
                                        >
                                            {t("auth.8_or_more_characters")}
                                        </p>
                                    </div>
                                    <div className="bullet-div">
                                        <img src={bullet} alt="" />
                                        <p
                                            className={`bullet-para ${
                                                lacksChar && "text-red"
                                            }`}
                                        >
                                            {t("auth.1_special_character")}
                                        </p>
                                    </div>
                                </div>
                                <div className="bullet-group-2">
                                    <div className="bullet-div-upper">
                                        <img src={bullet} alt="" />
                                        <p
                                            className={`bullet-para ${
                                                lacksNumber && "text-red"
                                            }`}
                                        >
                                            {t("auth.1_number")}
                                        </p>
                                    </div>
                                    <div className="bullet-div">
                                        <img src={bullet} alt="" />

                                        <p
                                            className={`bullet-para ${
                                                lacksUpper && "text-red"
                                            }`}
                                        >
                                            {t("auth.1_uppercase_character")}
                                        </p>
                                    </div>
                                </div>
                                <div className="bullet-group-3">
                                    <div className="bullet-div">
                                        <img src={bullet} alt="" />
                                        <p
                                            className={`bullet-para ${
                                                lacksLower && "text-red"
                                            }`}
                                        >
                                            {t("auth.1_lowercase_character")}
                                        </p>
                                    </div>
                                    <div className="bullet-div">
                                        <p className="bullet-para"></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="hide-div-signup-confirmPass"
                                onClick={togglePasswordVisibility}
                            >
                                <img
                                    src={showConfirmPassword ? show : hide}
                                    width="20px"
                                    height="20px"
                                    alt="hide"
                                />
                            </div>

                            <input
                                className="signupconfirminput"
                                id="passwordConfirmBusiness"
                                type={showConfirmPassword ? "text" : "password"}
                                value={confirmPassword}
                                placeholder={t("auth.confirm_password")}
                                onChange={(e) =>
                                    setconfirmPassword(e.target.value)
                                }
                                required
                            />

                            <p className="terms-para">
                                <Trans i18nKey="auth.terms_and_policy">
                                    By creating an account, you agree to
                                    the&nbsp;
                                    <span className="terms-span">
                                        Terms of use
                                    </span>
                                    &nbsp;and&nbsp;
                                    <span className="terms-span">
                                        Privacy Policy.
                                    </span>
                                </Trans>
                            </p>

                            <Spinner
                                text={t("auth.create_an_account")}
                                onSubmit={submit}
                                loading={showLoader}
                                disabled={showLoader}
                            />
                        </form>
                    </div>
                </div>
            </div>
            <Toast ref={toast} position="top-right" />
        </div>
    );
}
