import React, { useState, useEffect } from 'react';
import '../styling/styles/authentication/authentication.css'; // ← CSS file for this React component
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../components/home-page/Footer';
import SyncLoader from "react-spinners/SyncLoader";
// ↓ Background Video
import PartyVideo from '../styling/images/commercial/nightclub-vid.mp4';
// ↓ Authentication
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { FacebookProvider, LoginButton } from 'react-facebook';
// ↓ Backend
import axios from 'axios';
// ↓ Email verification
import validator from 'validator';

export default function SignUp(props) {

    const [clicked, setClicked] = useState(false);

    useEffect(() => {
        // Adjust the video playback rate after the component mounts
        const video = document.getElementById('backgroundVideo');
        if (video) {
            video.playbackRate = 0.5;
        }
    }, []);

    const responseFacebook = (response) => {
        console.log(response);
    }

    return (

        <>

            <div className='w-100 d-flex justify-content-center align-content-center' id='auth-section'>

                <video autoPlay muted loop playsInline id='backgroundVideo'>
                    <source src={PartyVideo} />
                    Your browser does not support the video tag
                </video>

                <Link to='/'><h1 className='brand-title'>Frizda</h1></Link>

                <div className="card bg-dark text-white my-auto col-lg-3 col-sm-9">
                    <div className="card-body">
                        <h1 className='mt-5 mb-4'>Sign up {clicked ? 'with Email' : null}</h1>

                        {clicked ? <SignUpEmail
                            onBackClicked={() => setClicked(false)}
                        /> :
                            <>

                                <p className='sign-up-info'>Join the Nightclub Experience!</p>

                                <button className='btn btn-outline-light w-100 mb-4 mt-5' onClick={() => setClicked(true)}>Sign up with Email <i className="bi-envelope-fill" /></button>

                                <GoogleOAuthProvider clientId='987703423035-7a8a3l0dkrul53cko7m8oc4h5iavuni0.apps.googleusercontent.com'>
                                    <GoogleButton
                                        authType='Sign up with Google'
                                    />
                                </GoogleOAuthProvider>

                                <FacebookProvider appId='655595690070827' >
                                    <LoginButton
                                        scope='email'
                                        onSuccess={res => console.log(res.status)}
                                        onError={err => console.log(err)}
                                        className='btn facebook-button mt-4'
                                    >
                                        Sign up with Facebook <i className='bi-facebook' />
                                    </LoginButton>
                                </FacebookProvider>

                                <Link to='/login'>
                                    <button className='btn btn-outline-light mt-5'>I already have an account</button>
                                </Link>

                                <p className='sign-up-copyright'>&copy; Frizda Company</p>
                            </>
                        }



                    </div>
                </div>
            </div>

            <Footer />
        </>

    )
}

function SignUpEmail({ onBackClicked }) {

    const navigate = useNavigate();

    const [confirmed, setConfirmed] = useState(true);  // ← To modify password frontend accordingly
    const [loading, setLoading] = useState(false);  // ← To display the SyncLoader and Disable buttons accordingly
    const [invalidEmail, setEmailInvalidity] = useState(false); // ← Modifies frontend if Email is valid or not

    async function submit(event) {
        event.preventDefault();

        let email = document.getElementById('emailSignUp').value;
        let password = document.getElementById('passwordSignUp').value;
        let confirmedPassword = document.getElementById('passwordConfirmSignUp').value;

        try {
            setLoading(true);

            if (password === confirmedPassword && validator.isEmail(email)) {
                const account = {
                    ownerAccount: false,
                    email: email,
                    password: password
                };

                const response = await axios.post('https://polar-hamlet-90480-1e735d673784.herokuapp.com/data', account); // replace with your server URL

                if (response.status !== 200) {
                    throw new Error(response.statusText);
                } else {
                    console.log('Success:', response.data);
                    navigate('/auth');
                }
            } else if (!validator.isEmail(email)) {
                setEmailInvalidity(true);
                setLoading(false);
            } else {
                setConfirmed(false);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }


    return (
        <>
            <form>
                <div className='mb-4'>
                    <label htmlFor='emailSignUp' className='form-label' style={{ color: invalidEmail && 'red' }}>Email {invalidEmail && <p>Invalid Email</p>}</label>
                    <input id='emailSignUp' type='email' className='form-control' placeholder='Enter your email adress' aria-describedby='email-info' data-bs-theme="dark" />
                    <div className='form-text' id='email-info'>Your Email is safe with Frizda</div>
                </div>

                <div className='mb-4'>
                    <label htmlFor='passwordSignUp' className='form-label d-flex' style={{ color: !confirmed && 'red' }}>Password <div className='form-text my-auto ms-2'>must be 8-16 charachters long</div></label>
                    <input id='passwordSignUp' type='password' className='form-control' placeholder='Create a password' data-bs-theme="dark" />
                </div>

                <div>
                    <label htmlFor='passwordConfirmSignUp' className='form-label' style={{ color: !confirmed && 'red' }}>Confirm Password</label>
                    <input id='passwordConfirmSignUp' type='password' className='form-control' placeholder='Enter the created password' data-bs-theme="dark" />
                </div>
                <div className='form-check mt-3'>
                    <input className='form-check-input' type='checkbox' value='' id='showPasswordCheckbox' onClick={() => showPassword()} data-bs-theme="dark" />
                    <label className={`form-check-label ${!confirmed && 'underline-show-password'}`} htmlFor='showPasswordChekbox'>Show Password</label>
                </div>
                {!confirmed && <p className='passwords-unmatch'>Passwords don't match</p>}

                <div className='d-flex align-items-center gap-3 sign-email-button'>
                    {loading &&
                        <SyncLoader
                            color="grey"
                            margin={2}
                            size={4}
                            speedMultiplier={0.8}
                        />}
                    <button className='btn btn-outline-info' id='signupSubmit' onClick={submit} disabled={loading}>
                        <p className='sign-email-next my-auto'>Next</p>
                        <i className='bi bi-caret-right ms-2' />
                    </button>
                </div>

            </form>
            <i className='bi bi-caret-left-square' onClick={onBackClicked} />
        </>
    )
}

function showPassword() {
    let passwordInput = document.getElementById('passwordSignUp');
    let confirmPasswordInput = document.getElementById('passwordConfirmSignUp');

    if (passwordInput.type === 'password') {

        passwordInput.type = 'text';
        confirmPasswordInput.type = 'text';

    } else if (passwordInput.type === 'text') {

        passwordInput.type = 'password';
        confirmPasswordInput.type = 'password';

    }
}

export function GoogleButton(props) {  // ← Sign Up with Google button, outside component for customization requirements
    const signUp = useGoogleLogin(
        {
            onSuccess: tokenResponse => console.log(tokenResponse),
            onError: error => console.log(error),
        }
    );

    return (
        <button className='btn google-sign-up' onClick={signUp}>
            {props.authType} <i className='bi-google' />
        </button>
    )
}