import React from "react";

import "../service-page-plans/service-plans.css";

import basiclogo from "../../styling/imgs/basic-logo.png";
import prologo from "../../styling/imgs/diamond.png";
import check from "../../styling/imgs/check-mark .png";
import lockcheck from "../../styling/imgs/bullet-lock.png";

function ServicePlans() {
    return (
        <div className="service-plan-parent">
            <div className="service-plans-child-div">
                <div className="service-plans-content-div">
                    {/* basic */}
                    <div className="service-basic-plan">
                        {/* <div className="service-pro-badge">
                            <p className="service-pro-badge-para">
                                Current Plan
                            </p>
                        </div> */}
                        <div className="service-basic-plan-header">
                            <h1 className="service-basic-plan-header-heading">
                                Frizda Basic
                            </h1>
                            <img
                                src={basiclogo}
                                alt="basic logo"
                                className="service-basic-plan-header-logo"
                            />
                        </div>

                        <p className="service-basic-plan-para">
                            If you have trouble with any of the following:
                        </p>
                        <div className="service-basic-plan-bullet">
                            <img
                                src={check}
                                alt="check mark"
                                width={15}
                                height={15}
                            />
                            <p className="service-basic-plan-bullet-para">
                                Grabbing and holding your audience's attention
                            </p>
                        </div>
                        <div className="service-basic-plan-bullet">
                            <img
                                src={check}
                                alt="check mark"
                                width={15}
                                height={15}
                            />
                            <p className="service-basic-plan-bullet-para">
                                Turning your audience into potential sales leads
                            </p>
                        </div>
                        <div className="service-basic-plan-bullet">
                            <img
                                src={check}
                                alt="check mark"
                                width={15}
                                height={15}
                            />
                            <p className="service-basic-plan-bullet-para">
                                Crafting appealing offers and services
                            </p>
                        </div>
                        <div className="service-basic-plan-bullet">
                            <img
                                src={lockcheck}
                                alt="check mark"
                                width={15}
                                height={15}
                            />
                            <p className="service-basic-plan-bullet-color-varient-para">
                                Building your email subscriber list
                            </p>
                        </div>
                        <div className="service-basic-plan-bullet">
                            <img
                                src={lockcheck}
                                alt="check mark"
                                width={15}
                                height={15}
                            />
                            <p className="service-basic-plan-bullet-color-varient-para">
                                Enhancing your sales skills and mindset
                            </p>
                        </div>
                        <button className="service-plan-basic-btn disabled-btn" disabled>
                            View Details
                        </button>
                    </div>
                    {/* pro */}
                    <div className="service-pro-plan">
                        <div className="service-pro-badge">
                            <p className="service-pro-badge-para">
                                Current
                            </p>
                        </div>
                        <div className="service-basic-plan-header">
                            <h1 className="service-basic-plan-header-heading">
                                Frizda Pro
                            </h1>
                            <img
                                src={prologo}
                                alt="basic logo"
                                className="service-basic-plan-header-logo"
                            />
                        </div>

                        <p className="service-basic-plan-para">
                            If you have trouble with any of the following:
                        </p>
                        <div className="service-basic-plan-bullet">
                            <img
                                src={check}
                                alt="check mark"
                                width={15}
                                height={15}
                            />
                            <p className="service-basic-plan-bullet-para">
                                Grabbing and holding your audience's attention
                            </p>
                        </div>
                        <div className="service-basic-plan-bullet">
                            <img
                                src={check}
                                alt="check mark"
                                width={15}
                                height={15}
                            />
                            <p className="service-basic-plan-bullet-para">
                                Turning your audience into potential sales leads
                            </p>
                        </div>
                        <div className="service-basic-plan-bullet">
                            <img
                                src={check}
                                alt="check mark"
                                width={15}
                                height={15}
                            />
                            <p className="service-basic-plan-bullet-para">
                                Crafting appealing offers and services
                            </p>
                        </div>
                        <div className="service-basic-plan-bullet">
                            <img
                                src={check}
                                alt="check mark"
                                width={15}
                                height={15}
                            />
                            <p className="service-basic-plan-bullet-color-varient-para">
                                Building your email subscriber list
                            </p>
                        </div>
                        <div className="service-basic-plan-bullet">
                            <img
                                src={check}
                                alt="check mark"
                                width={15}
                                height={15}
                            />
                            <p className="service-basic-plan-bullet-color-varient-para">
                                Enhancing your sales skills and mindset
                            </p>
                        </div>
                        <button className="service-plan-pro-btn disabled-btn" disabled>
                            View Details
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServicePlans;
