export const TicketBackground = {
    drink: "DRINK",
    dance: 'DANCE',
    bulb: 'BULB'
}

export const TicketTheme = {
    gold: "GOLD",
    silver: "SILVER",
    purple: 'PURPLE'
}

// default data size per page
export const DATA_PER_PAGE = 10;