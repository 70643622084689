import React, { useContext } from 'react'
import './styles/FAQ.css'
import { Accordion, AccordionContext, Card, useAccordionButton } from 'react-bootstrap'
import { FaPlus, FaMinus } from 'react-icons/fa6'
import { useTranslation } from 'react-i18next'

const CustomToggleBtn = ({ children, eventKey, callback }) => {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <div className="faq-accordion-header" onClick={decoratedOnClick}>
            {isCurrentEventKey ? <FaMinus size={20} /> : <FaPlus size={20} />} {children}
        </div>
    );
}

const FAQ = () => {

    const { t } = useTranslation();

    const question = [
        {
            id: 1,
            question: "q1",
            answer: "ans1"
        },
        {
            id: 2,
            question: "q2",
            answer: "ans2"
        },
        {
            id: 3,
            question: "q3",
            answer: "ans3"
        },
        {
            id: 4,
            question: "q4",
            answer: "ans4"
        },
        {
            id: 5,
            question: "q5",
            answer: "ans5"
        },
        {
            id: 6,
            question: "q6",
            answer: "ans6"
        },
        {
            id: 7,
            question: "q7",
            answer: "ans7"
        },
        {
            id: 8,
            question: "q8",
            answer: "ans8"
        }
    ]

    return (
        <div className="faq-section">
            <div className="faq-header">
                <p className='d-none d-md-block'>{t('landing_page.faq_header')}</p>
                <p className='d-block d-md-none'>FAQs</p>
            </div>
            <div className="faq-accordion-wrapper">
                <Accordion defaultActiveKey="0">
                    {
                        question.slice(0, 4).map((item) => {
                            return (
                                <Card key={item.id} className='mb-3'>
                                    <Card.Header>
                                        <CustomToggleBtn eventKey={item.id}>{t(`landing_page.faq.${item.question}`)}</CustomToggleBtn>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={item.id}>
                                        <Card.Body>{t(`landing_page.faq.${item.answer}`)}</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            )
                        })
                    }
                </Accordion>
                <Accordion defaultActiveKey="0">
                    {
                        question.slice(4, 8).map((item) => {
                            return (
                                <Card key={item.id} className='mb-3'>
                                    <Card.Header>
                                        <CustomToggleBtn eventKey={item.id}>{t(`landing_page.faq.${item.question}`)}</CustomToggleBtn>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={item.id}>
                                        <Card.Body>{t(`landing_page.faq.${item.answer}`)}</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            )
                        })
                    }
                </Accordion>
            </div>
        </div>
    )
}

export default FAQ