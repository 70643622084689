import React, { useState } from 'react';
import '../../../styling/styles/home-page-styling/outstandingClubs.css'; // ← CSS styles for these React components

export function FilterOptionSelect(props) {

    const [selectedValue, setSelectedValue] = useState(null);

    function handleSelectChange(event) {
        const value = event.target.value;

        // Check if the selected value is not the default option
        if (value !== props.selectObjectText) {
            setSelectedValue(value);
            props.onSelectChange(value);
        } else {
            // Handle the case when the default option is selected
            setSelectedValue(null);
            props.onSelectChange(null);  // You can use null or any other value to represent no selection
        }
    }


    return (
        <div className='d-flex align-items-center mt-3' data-bs-theme='dark'>
            <i className={props.iconClass} />
            <select
                className={`form-select form-select-sm dark-mode ${props.extraClassNames}`}
                value={selectedValue}
                onChange={handleSelectChange}
                disabled={props.disabled}
                required={props.required}
            >
                <option>{props.selectObjectText}</option>
                {props.array.map((object, index) => (
                    <option key={index} value={object}>
                        {object}
                    </option>
                ))}
                {props.extraCustomOption && <option disabled>
                    {props.extraCustomOptionText}
                </option>}
            </select>
        </div>
    )
}

export function FilterOptionRange(props) {
    return (
        <div>
            <div className='d-flex align-items-center mt-3'>
                <i className={props.iconClass} />

                <input type="range"
                    class="form-range w-75 mx-auto"
                    min="5" max="30" step="5"
                    id="distanceRange"
                    onChange={updateValue}
                />

            </div>
            <div className='d-flex justify-content-center'><span id='rangeValue'>Select a distance on</span>&nbsp;<span className='distanceUnit'> Km</span></div>
        </div>
    )
}

function updateValue(event) {  // ← for function FilterOptionRange
    const value = event.target.value;
    document.getElementById('rangeValue').innerText = value;
    event.target.value = value;
}

export function FilterOptionCheckbox(props) {

    const [isClicked, setClicked] = useState(true);

    return (
        <div className='d-flex align-items-center mt-3'>
            <i className={props.iconClass} />
            <div className='d-flex flex-column align-items-start ms-4'>

                {props.array.map((object, index) => {

                    return (
                        <div className="form-check" data-bs-theme="dark">
                            <label className="form-check-label">
                                {object}
                            </label>
                            <input className="form-check-input" value='' type={props.inputType} onClick={() => setClicked(!isClicked)} />
                        </div>
                    )

                })}
                <div className="form-check" data-bs-theme="dark">
                    <label className="form-check-label">
                        Frizda Tokens
                    </label>
                    <input className="form-check-input" type="checkbox" disabled />
                </div>

            </div>
        </div>
    )
}

export function FilterOptionSwitch(props) {
    return (
        <div className='d-flex align-items-center mt-3' data-bs-theme="dark">
            <i className={props.iconClass} />
            <div class="form-check form-switch ms-4">
                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                <label class="form-check-label" for="flexSwitchCheckChecked">{props.switchText}</label>
            </div>
        </div>
    )
}

export function FilterTimePicker(props) {
    return (
        <div className={`${props.classNames}`}>
            <label htmlFor="props.htmlFor" className='form-label'>{props.timePickerLabel}</label>
            <input
                id='props.id'
                type='time'
                className={`form-control ${props.classNames}`}
                disabled={props.disabled}
                onChange={props.onChange}
                defaultValue={props.value}
                key={props.value || 'defaultKey'}
            />
        </div>
    )
}
