import React from "react";

import { useState } from "react";

import { useNavigate } from "react-router-dom";

import ForgotSpinner from "./auth-styles/spinners/ForgotSpinner";

import { ApiPostNoAuth } from "../../../utils/ApiData";
import "../auth-components/auth-styles/forgotPassword.css";
import logo from "../auth-components/auth-styles/images/frizdausual.png";
import { useTranslation } from "react-i18next";

function ForgotPassword() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(false);
    const [email, setEmail] = useState("");
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleSignupForm = (e) => {
        e.preventDefault();
        setShowLoader(true);
        setIsEmailSent(false);
        setErrorMessage("");

        try {
            const data = ApiPostNoAuth("user/forgot-password-link", {
                email: email,
            });

            if (data) {
                setIsEmailSent(true);
                setShowLoader(false);
            }
        } catch (error) {
            setErrorMessage(error.response.data.message);
            setIsEmailSent(false);
            setShowLoader(false);
            console.error(error);
        }
    };
    return (
        <div className="business-signup-parent">
            <div className="business-signup-child">
                <div className="business-forgot-content">
                    <div className="business-forgot-left-div">
                        <img
                            src={logo}
                            alt="logo"
                            width={88}
                            className="forgot-logo"
                        />
                        <h1 className="business-login-heading">
                            {t("auth.forgot_password")}
                        </h1>
                        <p className="forgot-pass-para">
                            {t("auth.email_instruction_for_forgot_password")}
                        </p>
                    </div>
                    <div className="business-forgot-right-div">
                        <form
                            className="signupform"
                            onSubmit={handleSignupForm}
                        >
                            <input
                                className="forgotinputemail"
                                type="email"
                                placeholder={t("auth.email")}
                                required
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                            />

                            {errorMessage ? (
                                <p style={{ margin: "4rem 0 0" }}>
                                    {errorMessage}
                                </p>
                            ) : isEmailSent ? (
                                <p style={{ margin: "4rem 0 0" }}>
                                    Email sent successfully. Check your inbox.
                                </p>
                            ) : (
                                <ForgotSpinner
                                    className="login-button"
                                    text={t("auth.send")}
                                    onSubmit={handleSignupForm}
                                    loading={showLoader}
                                    disabled={showLoader}
                                />
                            )}

                            <button
                                type="submit"
                                className="forgot-login-button"
                                onClick={() => {
                                    navigate("/business/login");
                                }}
                            >
                                {t("auth.back_to_login")}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;
