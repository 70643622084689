import React, { useState, useEffect } from 'react';
import { FilterOptionSelect } from '../../../components/home-page/OutstandingClubs-components/FilterOptions';

export default function ClubType({ selectedNightspot, setSelectedNightSpot, nightspotType, setNightspotType, setEntryCost }) {

    const [solidEuro, setSolidEuro] = useState(null);
    const [euroCents, setEuroCents] = useState(null);

    const [isMainTypeSelected, setMainTypeSelected] = useState(false);
    const [isEntranceFree, setEntranceFree] = useState(false);

    useEffect(() => {
        solidEuro > 99 && setSolidEuro(solidEuro.substring(0, 2));
        solidEuro < 0 && setSolidEuro(0);

        euroCents > 99 && setEuroCents(euroCents.substring(0, 2));
        euroCents < 0 && setEuroCents(0);

        euroCents != null && setEntryCost(prevData => ({ ...prevData, euroCents: euroCents }));
        solidEuro != null && setEntryCost(prevData => ({ ...prevData, solidEuro: solidEuro }));
    }, [euroCents, solidEuro]);

    useEffect(() => {
        if (isEntranceFree) {
            setEntryCost(prevData => ({ ...prevData, solidEuro: null, euroCents: null, free: isEntranceFree }));
            setEuroCents(0);
            setSolidEuro(0);
        } else {
            setEntryCost(prevData => ({ ...prevData, free: isEntranceFree }));
        }
    }, [isEntranceFree]);

    useEffect(() => {
        selectedNightspot == 'Bar' && setEntranceFree(true);
        selectedNightspot == 'Nightclub' && setEntranceFree(false);
        selectedNightspot == 'Bar' && barType.includes(nightspotType) == false && setNightspotType(null);
        selectedNightspot == 'Nightclub' && clubType.includes(nightspotType) == false && setNightspotType(null);
    }, [nightspotType, selectedNightspot]);

    const clubType = [
        'Dance', // Clubs with a focus on dancing, often with a DJ
        'Karaoke', // Clubs where patrons can sing along to music
        'After Hours', // Clubs that stay open well into the morning
        'LGBTQ+', // Clubs catering to the LGBTQ+ community
        'Women only', // Clubs exclusively for women
        'Men only', // Clubs exclusively for men
        'Live Music', // Clubs featuring live music performances
        'Comedy', // Clubs that feature live comedy performances
        'Striptease', // Adult clubs featuring striptease performances
        'Private', // Exclusive clubs with membership requirements
        'Sports-Themed', // Clubs centered around watching sports, often with large screens
    ];

    const barType = [
        'Sports', // Bars that broadcast live sports games
        'Live Music', // Bars that feature live music performances
        'Cocktail', // Bars that specialize in mixed drinks
        'Dive', // Casual, unpretentious bars often serving cheap drinks
        'Pub', // Traditional bars serving a variety of alcoholic beverages
        'Wine', // Bars specializing in wines and often offering a food menu
        'Tiki', // Bars with a tropical theme, known for exotic rum cocktails
        'Karaoke', // Bars where patrons can sing along to music
        'Speakeasy', // Prohibition-era themed bars with a focus on cocktails
        'Biker', // Bars that cater to the biker community
        'Beach', // Bars located on the beach, often serving tropical drinks
        'LGBTQ+', // Bars catering to the LGBTQ+ community
        'Women only', // Bars exclusively for women
        'Men only', // Bars exclusively for men
    ];

    return (
        <div className='p-4 position-relative w-100'>
            <h5 className='d-flex justify-content-center align-items-center'><i className='bi bi-house-gear-fill me-3' />Nightspot type</h5>

            <div className='w-100 d-flex justify-content-center my-4'>
                <div className='btn-group mx-auto'>
                    <input id='placeTypeNightclub' type='radio' className='btn-check' name='club-type-button' onChange={() => setSelectedNightSpot('Nightclub')} checked={selectedNightspot == 'Nightclub'} />
                    <label className='btn btn-outline-frizda club-types-button' htmlFor='placeTypeNightclub' onClick={() => setMainTypeSelected(true)}>Nightclub</label>

                    <input id='placeTypeBar' type='radio' className='btn-check' name='club-type-button' onChange={() => setSelectedNightSpot('Bar')} checked={selectedNightspot == 'Bar'} />
                    <label className='btn btn-outline-frizda club-types-button' htmlFor='placeTypeBar' onClick={() => setMainTypeSelected(true)}>Bar</label>
                </div>
            </div>

            <FilterOptionSelect
                array={selectedNightspot == 'Nightclub' ? clubType : barType}
                selectObjectText={`Select ${selectedNightspot} Type`}
                disabled={!isMainTypeSelected}
                onSelectChange={value => setNightspotType(value)}
                required={true}
            />

            <div className='d-flex justify-content-start mt-3'>

                <div className='col-6 my-auto'>
                    <h6 className='d-inline'>Set the entrance fee:</h6>

                    <div className='mb-3 mt-3 d-flex gap-2 align-items-center'>



                        {/* <h5 className='my-auto'>€</h5> */}


                        <div className='input-group'>
                            <span className='input-group-text'>€</span>
                            <input className='form-control' type='number'
                                onChange={event => setSolidEuro(event.target.value)}
                                value={solidEuro || ''}
                                min={0}
                                max={99}
                                disabled={!isMainTypeSelected || isEntranceFree}
                                required={!isEntranceFree}
                            />
                            <span className='input-group-text px-1 bg-dark'>.</span>
                            <input className='form-control' type='number'
                                onChange={event => setEuroCents(event.target.value)}
                                value={euroCents || ''}
                                min={0}
                                max={99}
                                disabled={!isMainTypeSelected || isEntranceFree}
                                required={!isEntranceFree}
                            />
                        </div>
                    </div>
                </div>
                <div className='col-6 my-auto'>
                    <div className='form-check ms-4 mt-2'>
                        <input className='form-check-input position-relative' type='checkbox' id='freeClubCheckbox' disabled={!isMainTypeSelected}
                            checked={isEntranceFree}
                            onChange={() => setEntranceFree(!isEntranceFree)}
                        />
                        <label htmlFor='freeClubCheckbox' className='form-check-label'>Free entrance</label>
                    </div>
                </div>

            </div>
        </div>

    )

}