import React, { useContext, useEffect } from "react";
import { useState } from "react";

import "../plan-page/plan.css";

import PlanNav from "./planNav";
import { LoggedInContext } from "../businessStates&Auth/businessAuthState";

import basicSvg from "../plan-page/planNav/styling/imgs/BasicIcon.svg";
import proSvg from "../plan-page/planNav/styling/imgs/proIcon.svg";
import starSvg from "../plan-page/planNav/styling/imgs/Star.svg";
import axios from "axios";

function PlanPage() {
  const { accountId } = useContext(LoggedInContext);
  const [activeButton, setActiveButton] = useState("monthly");
  const [dbPlan, setDbPlan] = useState('undefined');

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  useEffect(() => {
    axios.get(`http://localhost:8000/getAccountInfo/${accountId}/frizdaPlan`)
      .then(res => setDbPlan(res.data.field)).catch(err => console.error(err));
  }, []);

  const handleGetStarted = async (plan) => {
    await axios.put(`http://localhost:8000/updateOwnerAccount/${accountId}/frizdaPlan/${plan == 'pro' ? 'Pro' : 'Basic'}`)
      .then(res => console.log(res)).catch(err => console.log(err));

    setDbPlan(plan == 'pro' ? 'Pro' : 'Basic');
  }

  return (
    <div className="plan-page-parent">
      <div className="plan-page-child">
        {/* <PlanNav /> */}
        <div className="plan-page-content">
          <h1 className="plan-page-heading">
            Find Your <span className="plan-page-heading-span"> Perfect </span>{" "}
            Plan
          </h1>
          <div className="button-group">
            <button
              className={`toggle-button ${activeButton === "monthly" ? "active" : ""
                }`}
              onClick={() => handleButtonClick("monthly")}
            >
              Monthly
            </button>
            <button
              className={`toggle-button ${activeButton === "yearly" ? "active" : ""
                }`}
              onClick={() => handleButtonClick("yearly")}
            >
              Yearly
            </button>
          </div>
          <div className="plan-page-cards">
            <div className="plan-page-basic-card">
              <div className="plan-page-basic-card-header">
                <img src={basicSvg} alt="" width={35} height={35} />
                <h1 className="plan-page-basic-card-heading">
                  Frizda Basic{" "}
                  <span className="plan-page-basic-card-heading-span">
                    {" "}
                    6 Months Free{" "}
                  </span>
                </h1>
              </div>
              <p className="plan-page-basic-para">
                Take Your Business to the Next Level with Frizda Basic.
              </p>
              <div className="plan-page-basic-card-checks-main-div">
                <div className="plan-page-basic-card-check">
                  <img src={starSvg} alt="" width={9} height={9} />
                  <p className="plan-page-basic-card-check-para">
                    Access to Frizda Map
                  </p>
                </div>
                <div className="plan-page-basic-card-check">
                  <img src={starSvg} alt="" width={9} height={9} />
                  <p className="plan-page-basic-card-check-para">
                    Get minimum client data
                  </p>
                </div>
                <div className="plan-page-basic-card-check">
                  <img src={starSvg} alt="" width={9} height={9} />
                  <p className="plan-page-basic-card-check-para">
                    Access to Frizda Feed
                  </p>
                </div>
                <div className="plan-page-basic-card-check">
                  <img src={starSvg} alt="" width={9} height={9} />
                  <p className="plan-page-basic-card-check-para">
                    Sell your tickets online on Frizda
                  </p>
                </div>
                {/* <div className="plan-page-basic-card-check">
                  <img src={starSvg} alt="" width={9} height={9} />
                  <p className="plan-page-basic-card-check-para">
                    Access to Frizda Search
                  </p>
                </div> */}
              </div>
              <div className="plan-page-basic-card-get-started-div">
                <h1 className="basic-plan-price">
                  €119.99 <span className="basic-plan-price-span">/ month</span>
                </h1>
                <button className="plan-page-basic-card-get-started-btn" onClick={() => handleGetStarted('basic')} disabled={dbPlan == 'Basic'}>
                  {dbPlan == 'Basic' ? 'Current Plan' : 'Get Started'}
                </button>
              </div>
            </div>
            {/* pro card */}

            <div className="plan-page-pro-card">
              <div className="pro-plan-bage-mob">
                <p className="pro-plan-bage-content-mob">x1.37 better success</p>
              </div>

              <div className="plan-page-basic-card-header">
                <img src={proSvg} alt="" width={35} height={35} />
                <h1 className="plan-page-basic-card-heading">
                  Frizda Pro{" "}
                  <span className="plan-page-basic-card-heading-span">
                    {" "}
                    6 Months Free{" "}
                  </span>
                </h1>
                <div className="pro-plan-bage">
                  <p className="pro-plan-bage-content">x1.37 better success</p>
                </div>
              </div>
              <p className="plan-page-pro-para">
                Take Your Business to the Next Level with Frizda Pro.
              </p>
              <div className="plan-page-pro-card-checks-main-div">
                <div className="plan-page-pro-card-checks-child-div">
                  <div className="plan-page-basic-card-check">
                    <img src={starSvg} alt="" width={9} height={9} />
                    <p className="plan-page-basic-card-check-para">
                      Access to Frizda Map
                    </p>
                  </div>
                  <div className="plan-page-basic-card-check">
                    <img src={starSvg} alt="" width={9} height={9} />
                    <p className="plan-page-basic-card-check-para">
                      Access to Frizda Feed
                    </p>
                  </div>
                  {/* <div className="plan-page-basic-card-check">
                    <img src={starSvg} alt="" width={9} height={9} />
                    <p className="plan-page-basic-card-check-para">
                      Access to Frizda Search
                    </p>
                  </div> */}
                  <div className="plan-page-basic-card-check">
                    <img src={starSvg} alt="" width={9} height={9} />
                    <p className="plan-page-basic-card-check-para">
                      Monetary statistics
                    </p>
                  </div>
                </div>
                <div className="plan-page-pro-card-checks-child-div">
                  <div className="plan-page-basic-card-check">
                    <img src={starSvg} alt="" width={9} height={9} />
                    <p className="plan-page-basic-card-check-para">
                      Get maximum client data
                    </p>
                  </div>
                  <div className="plan-page-basic-card-check">
                    <img src={starSvg} alt="" width={9} height={9} />
                    <p className="plan-page-basic-card-check-para">
                      Affiliation Marketing Systems
                    </p>
                  </div>
                  <div className="plan-page-basic-card-check">
                    <img src={starSvg} alt="" width={9} height={9} />
                    <p className="plan-page-basic-card-check-para">
                      Advertise your business using Frizda
                    </p>
                  </div>
                  <div className="plan-page-basic-card-check">
                    <img src={starSvg} alt="" width={9} height={9} />
                    <p className="plan-page-basic-card-check-para">
                      Sell tickets online on Frizda market
                    </p>
                  </div>
                </div>
              </div>

              <div className="plan-page-pro-card-get-started-div">
                <h1 className="basic-plan-price">
                  €149.99 <span className="basic-plan-price-span">/ month</span>
                </h1>
                <button className="plan-page-pro-card-get-started-btn" onClick={() => handleGetStarted('pro')} disabled={dbPlan == 'Pro'}>
                  {dbPlan == 'Pro' ? 'Current Plan' : 'Get Started'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlanPage;
