import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Col, Row } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import { Chart } from "primereact/chart";
import "./clientData.css";
import { ResponsiveContainer, Treemap } from "recharts";

// to set doughnut chart configuration
const doughnutChartOptions = {
    maintainAspectRatio: false,
    plugins: {
        legend: {
            align: "center",
            position: "bottom",
            labels: {
                font: {
                    size: 12,
                    family: '"DM Sans", sans-serif',
                },
                usePointStyle: true,
                pointStyle: "circle",
                boxWidth: 6,
                boxHeight: 6,
            },
        },
        tooltip: {
            callbacks: {
                label: function (context) {
                    return `€ ${context.raw}`;
                },
            },
            titleFont: {
                family: "DM Sans",
                size: 12,
                weight: "normal",
            },
            bodyFont: {
                family: "DM Sans",
                size: 12,
                weight: "normal",
                lineHeight: 1.2,
            },
            displayColors: false,
            backgroundColor: "#585858",
            titleColor: "#ffffff",
            bodyColor: "#ffffff",
            padding: 10,
            cornerRadius: 4,
        },
    },
    cutout: "60%", // value to set thickness of doughnut ring
};

const genderData = {
    labels: ["Women", "Men"],
    datasets: [
        {
            label: "Population",
            data: [5426, 8965],
            backgroundColor: ["#7E3FF2", "#4D4D4D"],
            hoverBackgroundColor: ["#9A5EF2", "#707070"],
            borderWidth: 0,
        },
    ],
};

const genderOptions = {
    maintainAspectRatio: false,
    cutout: "0%",
    plugins: {
        legend: {
            position: "bottom",
            labels: {
                usePointStyle: true,
                pointStyle: "circle",
                boxWidth: 6,
                boxHeight: 6,
            },
        },
        tooltip: {
            callbacks: {
                label: function (context) {
                    return `${context.raw}`;
                },
            },
            titleFont: {
                family: "DM Sans",
                size: 12,
                weight: "normal",
            },
            bodyFont: {
                family: "DM Sans",
                size: 12,
                weight: "normal",
                lineHeight: 1.2,
            },
            displayColors: false,
            backgroundColor: "#585858",
            titleColor: "#ffffff",
            bodyColor: "#ffffff",
            padding: 10,
            cornerRadius: 4,
        },
    },
};

const treeMapData = [
    { name: "Spanish", value: 50, fill: "#c1a9f9" }, // Custom colors for each block
    { name: "French", value: 25, fill: "#7E3FF2" },
    { name: "German", value: 15, fill: "#d4bffb" },
    { name: "English", value: 10, fill: "#ede1fc" },
];

const ClientData = () => {
    const { t } = useTranslation();

    const filterDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    const [activeFilterDay, setActiveFilterDay] = useState("Mon");

    // Dummy data
    const [doughnutChartData, setDoughnutChartData] = useState({
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: ["#7A38FE", "#8E56FE"],
                hoverBackgroundColor: ["#7A38FE", "#8E56FE"],
                borderWidth: 0,
            },
        ],
    });

    const [barChartData, setBarChartData] = useState({});
    const [barChartOptions, setBarChartOptions] = useState({});

    const getChartData = () => {
        // dummy data
        const data = [
            { label: "Spanish", value: 30 },
            { label: "French", value: 20 },
            { label: "German", value: 15 },
            { label: "British", value: 10 },
            { label: "Other", value: 5 },
        ];

        const labels = data.map((item) => item.label);
        const values = data.map((item) => item.value);

        setDoughnutChartData({
            labels: labels,
            datasets: [
                {
                    data: values,
                    backgroundColor: [
                        "#7A38FE",
                        "#8E56FE",
                        "#B692FE",
                        "#D1A8FE",
                        "#ECE9FF",
                    ],
                    hoverBackgroundColor: [
                        "#7A38FE",
                        "#8E56FE",
                        "#B692FE",
                        "#D1A8FE",
                        "#ECE9FF",
                    ],
                    borderWidth: 0,
                },
            ],
        });
    };

    const getBarChartData = () => {
        // dummy data
        const data = [65, 59, 80, 81, 56, 55, 40];
        const maxValue = Math.max(...data);
        const barChartData = {
            labels: ["00:00", "06:00", "12:00", "18:00", "23:00"],
            datasets: [
                {
                    label: "Sample Data",
                    backgroundColor: data.map((value) =>
                        value === maxValue ? "#7A38FE" : "#C8C8C8"
                    ),
                    borderColor: "#C8C8C8",
                    data: data,
                    barThickness: 14, // Fixed bar width
                    maxBarThickness: 14,
                    borderRadius: 10,
                },
            ],
        };

        setBarChartData(barChartData);
    };

    useEffect(() => {
        // dummy data
        getChartData();
        getBarChartData();

        const barChartOptions = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            return `${context.raw}`;
                        },
                    },
                    titleFont: {
                        family: "DM Sans",
                        size: 12,
                        weight: "normal",
                    },
                    bodyFont: {
                        family: "DM Sans",
                        size: 12,
                        weight: "normal",
                        lineHeight: 1.2,
                    },
                    displayColors: false,
                    backgroundColor: "#585858",
                    titleColor: "#ffffff",
                    bodyColor: "#ffffff",
                    padding: 10,
                    cornerRadius: 4,
                },
            },
            scales: {
                x: {
                    ticks: {
                        font: {
                            weight: 500,
                            family: '"DM Sans", sans-serif',
                        },
                    },
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                },
                y: {
                    ticks: {
                        display: false,
                    },
                    grid: {
                        borderDash: [5, 5],
                    },
                },
            },
        };
        setBarChartOptions(barChartOptions);
    }, []);

    return (
        <div className="w-100 h-100 p-4 overflow-y-auto">
            <div className="client-data-container">
                <Row
                    className="client-data-upper-row"
                    style={{ minHeight: "300px" }}
                >
                    {/* remove min-height when done */}
                    <Col xs={12} md={3}>
                        <Card className="business-dashboard-card d-flex flex-column justify-content-between disabled-card-section">
                            <h4>{t("client_data_module.nationality")}</h4>
                            <div className="">
                                <Doughnut
                                    data={doughnutChartData}
                                    options={doughnutChartOptions}
                                    height={200}
                                    width={200}
                                />
                            </div>
                        </Card>
                    </Col>
                    <Col xs={12} md={3}>
                        <Card className="business-dashboard-card d-flex flex-column justify-content-between disabled-card-section">
                            <h4>{t("client_data_module.gender_split")}</h4>
                            <div className="">
                                <Doughnut
                                    data={genderData}
                                    options={genderOptions}
                                    height={200}
                                    width={200}
                                />
                            </div>
                        </Card>
                    </Col>
                    <Col xs={12} md={6}>
                        <Card className="business-dashboard-card d-flex flex-column justify-content-between disabled-card-section">
                            <h4>{t("client_data_module.languages")}</h4>
                            <ResponsiveContainer width={"100%"} height={200}>
                                <Treemap
                                    width={200}
                                    height={200}
                                    data={treeMapData}
                                    dataKey="value"
                                    aspectRatio={4 / 3}
                                    content={({
                                        depth,
                                        x,
                                        y,
                                        width,
                                        height,
                                        index,
                                        name,
                                        value,
                                    }) => (
                                        <g>
                                            <rect
                                                x={x}
                                                y={y}
                                                width={width}
                                                height={height}
                                                style={{
                                                    fill: treeMapData[index]
                                                        .fill,
                                                    stroke: "#ffffff",
                                                    strokeWidth: 4,
                                                    borderRadius: "8px",
                                                }}
                                            />
                                            {depth === 1 ? (
                                                <text
                                                    x={x + 10}
                                                    y={y + 20}
                                                    fill="#0000000"
                                                    fontSize={14}
                                                    fontWeight="bold"
                                                >
                                                    {name}
                                                </text>
                                            ) : null}
                                            {depth === 1 ? (
                                                <text
                                                    x={x + 10}
                                                    y={y + 40}
                                                    fill="#fff"
                                                    fontSize={12}
                                                >
                                                    {value}%
                                                </text>
                                            ) : null}
                                        </g>
                                    )}
                                />
                            </ResponsiveContainer>
                        </Card>
                    </Col>
                </Row>

                <Row
                    className="client-data-lower-row"
                    style={{ minHeight: "300px" }}
                >
                    {/* remove min-height from this */}
                    <Col xs={12} md={7}>
                        <Card className="business-dashboard-card popular-times-card disabled-card-section">
                            <h4>{t("client_data_module.popular_times")}</h4>
                            <div className="popular-times-filter">
                                <ul>
                                    {filterDays.map((day) => (
                                        <li
                                            key={day}
                                            className={`${
                                                activeFilterDay === day
                                                    ? "active"
                                                    : ""
                                            }`}
                                            onClick={() =>
                                                setActiveFilterDay(day)
                                            }
                                        >
                                            {day}
                                        </li>
                                    ))}
                                </ul>
                                <div className="">
                                    <p>
                                        <span>Live:</span> Too Busy
                                    </p>
                                </div>
                            </div>
                            <Chart
                                type="bar"
                                data={barChartData}
                                options={barChartOptions}
                                height="80%"
                            />
                        </Card>
                    </Col>
                    <Col xs={12} md={5}>
                        <Card className="business-dashboard-card user-feedback-card">
                            <h4>{t("client_data_module.user_feedback")}</h4>
                            <div className="h-100 d-flex justify-content-center align-items-center">
                                <p>No Feedback Available</p>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ClientData;
