import React, { useEffect, useState, useContext } from 'react';
import '../styling/styles/business/add-club.css'; // ← CSS file for this React component
import { useNavigate, useResolvedPath } from 'react-router-dom';
// ↓ External frontend components
import BarLoader from "react-spinners/BarLoader";
// ↓ Contexts
import { LoggedInContext } from './businessStates&Auth/businessAuthState';
import { NewNightspotContext } from './businessStates&Auth/newNighstpotState';
// ↓ CSS file for children components
import '../styling/styles/business/add-nightclub.css';
// ↓ components
import BusinessNavbar from './BusinessNavbar';
// ↓ Backend comunicator
import axios from 'axios';

export default function AddClub(props) {

    const navigate = useNavigate();
    const { loggedIn, selectedCardReader, setOwnedNightspots, ownedNightspots, accountId } = useContext(LoggedInContext);
    const { selectedPlan, nightspot, setCostPerTicket, costPerTicket, schedule, musicalGenres, locationAddress, locationCoords, toilets, entryFee, images } = useContext(NewNightspotContext);

    const [actualCardReaderBrand, setActualCardReaderBrand] = useState('');
    const [actualNightspotImages, setActualNightspotImages] = useState([]);

    const [isSubmitting, setSubmitting] = useState(false);

    useEffect(() => {
        !loggedIn && navigate('/business/signup');
    }, [loggedIn]);


    function getProgressBarWidth() {
        if (actualCardReaderBrand !== '') {
            return '60%';
        } else if (ownedNightspots && ownedNightspots.length > 0) {
            return '40%';
        } else if (selectedPlan !== null) {
            return '20%';
        } else {
            return '5%';
        }
    }

    useEffect(() => {
        nightspot.cardReaders.length > 0 && setActualCardReaderBrand(nightspot.cardReaders[0].brand);
        setActualNightspotImages(nightspot.selectedImages);
    }, [nightspot]);

    /// ↓ nightspot card

    const [ticketToBuyCost, setTicketToBuyCost] = useState({
        solid: null,
        cents: null
    });

    useEffect(() => {
        ticketToBuyCost.solid < 0 && setTicketToBuyCost(prevData => ({ ...prevData, solid: 0 }));
        ticketToBuyCost.cents > 99 && setTicketToBuyCost(prevData => ({ ...prevData, cents: 99 }));
        ticketToBuyCost.cents < 0 && setTicketToBuyCost(prevData => ({ ...prevData, cents: 0 }));
        ticketToBuyCost.solid === null && setTicketToBuyCost(prevValues => ({ ...prevValues, solid: 0 }));
    }, [ticketToBuyCost])

    function submitPricePerTicket() {
        if (ticketToBuyCost.solid !== null) {
            let total = (Number(ticketToBuyCost.solid) + Number(ticketToBuyCost.cents / 100)).toFixed(2);
            setCostPerTicket(total);
            console.log('total : ', total);
        } else {
            // TODO: Create the else statement for this logic
        }
    }

    // useEffect for testing purposes ↓ (brocoli)
    useEffect(() => {
        console.log('schedule:', nightspot);
    }, [schedule]);

    let imageUrl = '';
    if (nightspot.images[0] instanceof File) {
        imageUrl = URL.createObjectURL(nightspot.images[0]);
    }

    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const dayName = days[new Date().getDay()];

    const [showCompleteSchedule, setShowCompleteSchedule] = useState(false);

    function getGoogleMapsDirections() {
        let googleMapsUrl;
        if (locationAddress && locationCoords.latitude) {
            googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${locationCoords.latitude},${locationCoords.longitude}`;
        } else if (locationAddress) {
            let formattedAddress = locationAddress.split(',')[0].replace(/ /g, '+');
            googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${formattedAddress}`;
        }

        window.open(googleMapsUrl, '_blank');
    }

    async function submitToDatabase() {
        setSubmitting(true);
        try {
            const formData = new FormData();

            // Append the nightspot object as a JSON string
            formData.append('nightspot', JSON.stringify(nightspot));

            // Append the files
            for (let i = 0; i < images.length; i++) {
                formData.append('photos', images[i]);
            }

            await axios.post('https://polar-hamlet-90480-1e735d673784.herokuapp.com/upload', formData)
                .then(response => response.status === 200 && navigate('/business/'))// navigate
                .catch(err => console.log(err));
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <>
            <div id='add-club' data-bs-theme='dark'>
                <BusinessNavbar />

                <div className='row px-5'>
                    <div className='col-5'>
                        <p className='heading my-5 d-flex justify-content-center'>Upload your nightspot</p>
                    </div>
                    <div className='col-7 d-flex align-items-center'>
                        <div className="progress" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            <div className="progress-bar" style={{ width: getProgressBarWidth() }} />
                        </div>
                    </div>
                    {selectedPlan !== 'Frizda Basic' && selectedPlan !== 'Frizda Pro' &&
                        <div className='col-10 offset-1 mt-5'>
                            <p className='text-light fs-5'>
                                Boost your business with Frizda, the only platform for nightlife business owners. Register your nightspot today and reach millions of potential customers who are looking for the best places to party. Nightspot helps you showcase your venue, promote your events, and attract more loyal fans. Don’t miss this opportunity to join the most exciting network of nightspots in the world. Sign up now and get ready to rock! 🎉
                            </p>
                        </div>}
                </div>

                <div id='add-club-steps-container' className='row'>

                    <div className='col-6'>
                        <div className='d-flex'>
                            <div className='form-check d-flex align-items-center col-8'>
                                <input className="form-check-input big-checkbox" type="checkbox" value="" id="businessPlan" checked={selectedPlan === 'Frizda Pro' || selectedPlan === 'Frizda Basic'} />
                                <label className="form-check-label fs-2 ms-5" for="businessPlan">
                                    Frizda Plan <span className='fs-6 text-secondary ms-1'>2 minutes</span>
                                </label>
                            </div>
                            <div className='col-2 d-flex align-items-center'>
                                <button className='btn btn-outline-light my-auto' onClick={() => navigate('/business/add/plans')}>Plans <i className='bi bi-arrow-right ms-2' /></button>
                            </div>
                        </div>

                        <div className='d-flex'>
                            <div className='form-check d-flex align-items-center mt-5 col-8'>
                                <input className="form-check-input big-checkbox" type="checkbox" value="" id="nightspotDetails" checked={nightspot.nightspotName !== null} />
                                <label className="form-check-label fs-2 ms-5" for="businessPlan">
                                    Nightspot details <span className='fs-6 text-secondary ms-1'>5 minutes</span>
                                </label>
                            </div>
                            <div className='col-2 d-flex align-items-center'>
                                <button disabled={selectedPlan !== 'Frizda Pro' && selectedPlan !== 'Frizda Basic'} className='btn btn-outline-light my-auto' onClick={() => navigate('/business/add/nightclub-details')}>Nightpost details <i className='bi bi-arrow-right ms-2' /></button>
                            </div>
                        </div>

                        {selectedPlan === 'Frizda Pro' &&
                            <div className='d-flex'>
                                <div className='form-check d-flex align-items-center mt-5 col-8'>
                                    <input className="form-check-input big-checkbox" type="checkbox" value="" id="cardReaders" checked={nightspot.cardReaders[0].brand !== null} />
                                    <label className="form-check-label fs-2 ms-5" for="businessPlan">
                                        Link Card Readers <span className='fs-6 text-secondary ms-1'>4 minutes</span>
                                    </label>
                                </div>
                                <div className='col-2 d-flex align-items-center'>
                                    <button disabled={ownedNightspots && ownedNightspots.length > 0 && false} className='btn btn-outline-light my-auto' onClick={() => navigate('/business/add/card-readers')}>Link <i className='bi bi-arrow-right ms-2' /></button>
                                </div>
                            </div>
                        }

                        <div className='d-flex'>
                            <div className='form-check d-flex align-items-center mt-5 col-8'>
                                <input className="form-check-input big-checkbox" type="checkbox" value="" id="VatProof" checked={false} />
                                <label className="form-check-label fs-2 ms-5" for="businessPlan">
                                    VAT proof <span className='fs-6 text-secondary ms-1'>3 minutes</span>
                                </label>
                            </div>
                        </div>

                        <div className='d-flex'>
                            <div className='form-check d-flex align-items-center mt-5 col-8'>
                                <input class="form-check-input big-checkbox" type="checkbox" value="" id="Terms&Conditions" checked={false} />
                                <label class="form-check-label fs-2 ms-5" for="businessPlan">
                                    Terms and conditions <span className='fs-6 text-secondary ms-1'>1 minute</span>
                                </label>
                            </div>
                        </div>

                    </div>

                    {/* // */}

                    <div className='col-5 offset-1 px-3'>
                        {nightspot.nightspotName &&
                            <div className='card'>
                                <img className='card-img-top' src={imageUrl} />
                                <div className='card-body position-relative'>

                                    <div className='get-directions-container'>
                                        <button className='btn btn-frizda' onClick={getGoogleMapsDirections}>Get directions</button>
                                        <p className='fs-6 mt-2 mb-0'>{locationAddress.split(',')[0]}</p>
                                    </div>

                                    <div className='selected-plan-container'>
                                        <p className='my-auto'>{selectedPlan}</p>
                                    </div>

                                    <div className='d-flex gap-3'>
                                        <h5 className='card-title rounded border my-auto p-1 '>{nightspot.nightspotName} - {nightspot.nightspotKind}</h5>

                                        <p className='text-secondary'>
                                            {nightspot.drinks?.ratedCost === 1 && '€'}
                                            {nightspot.drinks?.ratedCost === 2 && '€€'}
                                            {nightspot.drinks?.ratedCost === 3 && '€€€'}
                                            {nightspot.drinks?.ratedCost === 4 && '€€€€'}
                                            {!nightspot.drinks && 'Does not sell drinks/food'}
                                        </p>

                                        <p className='fw-bold'>
                                            {musicalGenres.genre1}
                                            {musicalGenres.genre2 !== null && `, ${musicalGenres.genre2}`}
                                            {musicalGenres.genre3 !== null && `, ${musicalGenres.genre3}`} &nbsp;
                                            <i className='bi bi-music-note-beamed' />
                                        </p>
                                    </div>
                                    <div>
                                        Entrance: €{entryFee.cost !== null ? entryFee.cost.toFixed(2) : 'FREE'}
                                    </div>

                                    {nightspot.nightspotDescription ?
                                        <p className='card-text m-1 w-75'>{nightspot.nightspotDescription}</p> : <p className='card-text mb-1 text-secondary'>No description</p>
                                    }
                                    <p className='fs-4 mt-3 mb-1'>Payment methods:</p>
                                    <div className='d-flex align-items-center fs-3 gap-3 mt-0 text-light'>
                                        <p className='my-auto'>{nightspot.acceptedPayments.includes('Cash') && <i className='bi bi-cash' />}</p>
                                        <p className='my-auto'>{nightspot.acceptedPayments.includes('Cards') && <i className='bi bi-credit-card' />}</p>
                                        {costPerTicket !== null &&
                                            <div className='fs-5 d-flex mt-0 cost-per-ticket-containe p-1 rounded border'>
                                                <p className='my-auto'>{nightspot.nightspotName}'s tokens: € {costPerTicket}</p>
                                                <button className='btn btn-outline-frizda btn-sm ms-3' onClick={() => setCostPerTicket(null)}>Change price</button>
                                            </div>
                                        }
                                    </div>
                                    {/* <p></p> Complete for Frizda coins */}
                                    {nightspot.acceptedPayments.includes(`${nightspot.nightspotName}'s tickets`) ?
                                        costPerTicket === null &&
                                        <div className='card'>
                                            <h5 className='fw-bold card-header'>{nightspot.nightspotName}'s tokens</h5>
                                            <div className='card-body'>

                                                <div className='col-6'>
                                                    <label className='form-label'>Cost per unit</label>
                                                    <div className='input-group'>
                                                        <span className='input-group-text'>€</span>
                                                        <input
                                                            type='number'
                                                            className='form-control'
                                                            onChange={event => setTicketToBuyCost(prevData => ({ ...prevData, solid: Math.floor(Number(event.target.value)) }))}
                                                            value={ticketToBuyCost.solid || ''}
                                                        />
                                                        <span className='input-group-text bg-dark'>.</span>
                                                        <input
                                                            type='number'
                                                            className='form-control'
                                                            onChange={event => setTicketToBuyCost(prevData => ({ ...prevData, cents: Math.floor(Number(event.target.value)) }))}
                                                            value={ticketToBuyCost.cents || ''}
                                                        />
                                                    </div>
                                                    <button className='btn btn-outline-frizda btn-sm mt-3 submit-price-button' onClick={submitPricePerTicket}>Set price</button>
                                                </div>
                                            </div>
                                        </div> : <></>}

                                    <p className='fs-4 mt-3 mb-1'>Schedule:</p>
                                    <div>
                                        {showCompleteSchedule ?
                                            days.map(day => {
                                                let openingHour = schedule[day].opening.hour;
                                                let openingMinutes = schedule[day].opening.minutes ? schedule[day].opening.minutes.toString().padStart(2, '0') : '00';
                                                let closingHour = schedule[day].closing.hour;
                                                let closingMinutes = schedule[day].closing.minutes ? schedule[day].closing.minutes.toString().padStart(2, '0') : '00';

                                                return (
                                                    <p key={day} className='m-1'>
                                                        {`${day.charAt(0).toUpperCase() + day.slice(1)}: ${openingHour}:${openingMinutes} - ${closingHour}:${closingMinutes}`}
                                                    </p>
                                                );
                                            })
                                            :
                                            <p>
                                                {`${dayName.charAt(0).toUpperCase() + dayName.slice(1)}: ${schedule[dayName].opening.hour}:${schedule[dayName].opening.minutes ? schedule[dayName].opening.minutes.toString().padStart(2, '0') : '00'} - ${schedule[dayName].closing.hour}:${schedule[dayName].closing.minutes ? schedule[dayName].closing.minutes.toString().padStart(2, '0') : '00'}`}
                                            </p>
                                        }
                                        <a href='#' className='fw-bold' onClick={(event) => {
                                            event.preventDefault();
                                            setShowCompleteSchedule(!showCompleteSchedule);
                                        }}>
                                            {showCompleteSchedule ? 'Only show for today' : 'Show for all days'}
                                        </a>
                                    </div>

                                    <p className='fs-4 mt-3 mb-1'>Toilets:</p>
                                    <div className='d-flex gap-2'>
                                        {toilets.toiletsAvailable ?
                                            <p className='my-auto fw-bolder fs-5'>
                                                {toilets.free ?
                                                    'Free'
                                                    :
                                                    `€ ${parseFloat(toilets.cost).toFixed(2)}`
                                                }
                                            </p>
                                            :
                                            <p className='text-secondary my-auto'>This establishment does not have toilets</p>
                                        }
                                        <i className='bi bi-badge-wc' />
                                        {toilets.genderNeutralToilets && <p className="d-flex align-items-center lgbt-colored rounded border px-1">Gender Neutral</p>}
                                    </div>

                                </div>
                            </div>
                        }

                    </div>
                </div>

                <div className='d-flex align-items-center container-fluid justBarLoaderify-content-end px-5 pb-5'>
                    {isSubmitting && <BarLoader color="#B637FB" speedMultiplier={3} width={50} />}
                    <button disabled={!nightspot.nightspotName} onClick={submitToDatabase} className='btn btn-frizda btn-lg ms-3'>Proceed</button>
                </div>
            </div>
        </>
    )
}