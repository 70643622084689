import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import PrimaryButton from '../components/PrimaryButton';
import SecondaryButton from '../components/SecondaryButton';
import { useTranslation } from 'react-i18next';

const OpeningHours = ({
    nextSlide,
    prevSlide,
    closesAt,
    setOpensAt,
    opensAt,
    setSelectedDays,
    selectedDays,
    setClosesAt,
    delta,
    register,
    errors,
    control,
    setValue,
    getValues,
    isEditMode,
    editModeSaveHandler,
    isMediumOrSmallScreen
}) => {
    const { t } = useTranslation();

    const days = [
        'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
    ];

    const handleDayChange = (day) => {
        setSelectedDays(prevDays =>
            prevDays.includes(day)
                ? prevDays.filter(d => d !== day)
                : [...prevDays, day]
        );

        setOpensAt(null);
        setClosesAt(null);

        // To remove selected from form object
        const formDays = getValues('schedule');
        if (formDays && Object.keys(formDays).includes(day.toLowerCase())) {
            delete formDays[day.toLowerCase()];
            setValue('schedule', formDays);
        }
    };

    useEffect(() => {
        if (!isEditMode && selectedDays.length === 0) {
            setSelectedDays([days[new Date().getDay()]]);
        }
    }, [])

    return (
        <motion.div
            initial={{ x: delta >= 0 ? '10%' : '-10%', opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            className={`opening-hours-wrapper h-100 d-flex flex-column ${isEditMode ? 'edit-mode-padding' : 'py-4 nightspot-detail-side-padding'}`}
            style={{ animation: isEditMode ? 'none' : '' }}
        >
            <div className='d-flex flex-fill flex-column'>
                {
                    !isEditMode &&
                    <div className='d-flex  justify-content-center mb-1'>
                        <span className='title text-black fs-4'>{t('nightspot_details_module.opening_hours')}</span>
                    </div>
                }
                <div className={`d-flex h-100 ${isEditMode ? '' : 'mt-2'}`}>
                    {/* Working Days */}
                    <div className='d-flex flex-column w-100'>
                        {
                            !isEditMode &&
                            <div className=''>
                                <span className='text-black text-desc fw-semibold mb-2' style={{ width: "50%" }}>{t('nightspot_details_module.enter_working_days')}</span>
                            </div>
                        }

                        {days.map((day, index) => (
                            <div key={index} className='form-check open-hours-wrapper'>
                                <div className='w-25'>
                                    <input
                                        type='checkbox'
                                        className='form-check-input checkbox'
                                        id={`radio-${day}`}
                                        checked={selectedDays.includes(day)}
                                        onChange={() => handleDayChange(day)}
                                    />
                                    <label className='form-check-label text-desc' style={{ color: '#444444', fontSize: '16px', fontWeight: 'bold' }} htmlFor={`radio-${day}`}>
                                        {day}
                                    </label>
                                </div>

                                {selectedDays.includes(day) &&
                                    <>
                                        <div className='w-25 custom-time-picker-wrapper'>
                                            <Controller
                                                name={`schedule.${day.toLowerCase()}.opens`}
                                                control={control}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        selected={field.value}
                                                        onChange={date => { field.onChange(date); setOpensAt(date) }}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={30}
                                                        timeCaption="Time"
                                                        dateFormat="HH:mm"
                                                        className='form-control custom-time-picker'
                                                        placeholderText="_ _ : _ _"
                                                    />
                                                )}
                                            />
                                            <span className='timepicker-control-label'>{t('nightspot_details_module.opens_at')}
                                                {errors.schedule?.[day.toLowerCase()]?.opens && (
                                                    <span className='input-error' style={{ fontSize: '10px' }}>
                                                        &nbsp;{errors.schedule[day.toLowerCase()].opens.message}
                                                    </span>
                                                )}
                                            </span>
                                        </div>

                                        <div className='w-25 custom-time-picker-wrapper'>
                                            <Controller
                                                name={`schedule.${day.toLowerCase()}.closes`}
                                                control={control}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        selected={field.value}
                                                        onChange={date => { field.onChange(date); setClosesAt(date); setValue(`schedule.${day.toLowerCase()}.closed`, false) }}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={30}
                                                        timeCaption="Time"
                                                        dateFormat="HH:mm"
                                                        className='form-control custom-time-picker'
                                                        placeholderText="_ _ : _ _"
                                                    />
                                                )}
                                            />
                                            <span className='timepicker-control-label'>
                                                {t('nightspot_details_module.closes_at')}
                                                {errors.schedule?.[day.toLowerCase()]?.closes && (
                                                    <span className='input-error' style={{ fontSize: '10px' }}>
                                                        &nbsp;{errors.schedule[day.toLowerCase()].closes.message}
                                                    </span>
                                                )}
                                            </span>
                                        </div>
                                    </>
                                }
                                {/* <div className='w-25' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '10px' }}>
                                    <input
                                        type='checkbox'
                                        id={`checkbox`}
                                        className='form-check-input checkbox'
                                        style={{ margin: '0px' }}
                                        {...register(`schedule.${day}.closed`)}
                                    />
                                    <label className='form-check-label text-black text-desc' style={{ marginLeft: '10px', marginBottom: '0px' }} htmlFor={`checkbox`}>
                                        Closed
                                    </label>
                                </div> */}

                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="">
                {errors.schedule && <span className="input-error">{errors.schedule.message}</span>}
            </div>

            {
                !isMediumOrSmallScreen
                    ?
                    isEditMode ?
                        <div className="mt-2">
                            <button onClick={() => editModeSaveHandler(5)} className='edit-mode-save-btn'>{t('save')}</button>
                        </div>
                        :
                        <div className='d-flex flex-row justify-content-between'>
                            <SecondaryButton title={t('previous')} onClick={prevSlide} />
                            <PrimaryButton type='submit' title={t('next')} onClick={nextSlide} />
                        </div>
                    :
                    <></>
            }

        </motion.div>
    )
}

export default OpeningHours