import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styling/styles/business/plan-selection.css';  // ← CSS file for the styling of this file
// ↓ Contexts
import { NewNightspotContext } from '../businessStates&Auth/newNighstpotState';
import { LoggedInContext } from '../businessStates&Auth/businessAuthState';
// ↓ Backend comunicator
import axios from 'axios';

export default function BusinessPlans() {
    const navigate = useNavigate();
    const { selectedPlan, setSelectedPlan } = useContext(NewNightspotContext);
    const { loggedIn, accountId, ownedNightspots } = useContext(LoggedInContext);

    const [proShowMoreContent, setProShowMoreContent] = useState(false);

    const [basicSelected, setBasicSelected] = useState(false);
    const [proSelected, setProSelected] = useState(false);
    const [anySelected, setAnySelected] = useState(false);

    const [isButtonShaking, setButtonShaking] = useState(false);
    const [showFailNextText, setShowFailNextText] = useState(false);

    async function handleNext() {
        if (selectedPlan === null) {
            setButtonShaking(true);
            setShowFailNextText(true);
        } else {
            navigate('/business/add');
        }

        setTimeout(() => { setButtonShaking(false) }, 500);
    }


    useEffect(() => {
        !loggedIn && navigate('/business');
    }, [loggedIn]);


    return (
        <div className='container-fluid m-0 p-5' id='frizdaPlans' data-bs-theme='dark'>

            <a href='/business' className='d-inline-block'><h1 className='d-flex text-white justify-content-start mb-5'>Frizda for Business</h1></a>

            <p className='fs-2'>Click on the plan that best suits your requirements.</p>

            <div className='row d-flex align-items-stretch mx-5 my-3 p-5 gap-5'>
                <div className='col'>
                    <div className={`card ${selectedPlan === 'Frizda Basic' && 'selected-border'} plan-selected h-100`} onClick={() => setSelectedPlan('Frizda Basic')} id='basic-plan-card'>
                        <div className='card-body'>

                            <div className='rounded border basic-plan-card-header px-3 py-2'>
                                <h3 className='fs-3'>Frizda Basic</h3>
                                <h4>€59,99 <span className='fs-6 text-body-secondary'>/ 4 weeks</span></h4>
                            </div>

                            <ul className='mt-4'>
                                <li>Max nightspot information</li>
                                <li>Access to Frizda Map</li>
                                <li>Frizda Tokens</li>
                                <li>Frizda ID</li>
                                <li>Allow reservations</li>
                                <li>Live DJ playlist</li>
                                <li>Client feedback</li>
                            </ul>
                        </div>

                        {basicSelected && <i className={`bi bi-check-circle-fill ${selectedPlan === 'Frizda Basic' ? 'show' : 'd-none'} fs-5`} />}
                    </div>
                </div>

                <div className='col'>
                    <div className={`card ${selectedPlan === 'Frizda Pro' && 'selected-border'} plan-selected`} onClick={() => setSelectedPlan('Frizda Pro')}>

                        <div className='card-body m-0'>

                            <div className='rounded border pro-plan-card-header px-3 py-2'>
                                <div className='d-flex align-items-center'><h3 className='fs-3 m-0'>Frizda Pro</h3> <div className='fw-light text-info-emphasis h-25 rounded border border-info-subtle p-1 mx-3'>Recommended</div></div>
                                <h4 className='mt-2'>€89,99 <span className='fs-6 text-body-secondary'>/ 4 weeks</span></h4>
                            </div>

                            <ul className='mt-4 p-3'>
                                <li>Max nightspot information</li>
                                <li>Access to Frizda Map</li>
                                <li>Frizda Tokens</li>
                                <li>Frizda ID</li>
                                <li>Allow Reservations</li>
                                <li>Live DJ playlist</li>

                                <hr className='border my-4' />

                                <h4 className='fw-bolder mb-2'>Frizda Statistics</h4>
                                <h5>Get a full live analysis of the data of your business without doing any work!</h5>

                                <div className={`mt-3 show-more-content ${proShowMoreContent && 'show'}`}>
                                    <h5>You will get live information about your business:</h5>
                                    <ul>
                                        <li>Consumption per client</li>
                                        <li>Client Attendance</li>
                                        <li>Profit margin</li>
                                        <li>Average client satisfaction</li>
                                        <li>Client feedback</li>
                                    </ul>
                                    <h5 className='mt-3'>Know everything about your average client:</h5>
                                    <ul className='fs-5'>
                                        <li>Why did they visit your business</li>
                                        <li>How did they know about your business</li>
                                        <li>What did they like about your business</li>
                                        <li>Their preferences</li>
                                        <li>Their languages</li>
                                        <li>Their age</li>
                                        <li>Their nationality</li>
                                    </ul>
                                </div>

                            </ul>

                            {
                                !proShowMoreContent ?
                                    <a onClick={() => setProShowMoreContent(true)} className='link-opacity-100-hover position-relative'>show details</a>
                                    :
                                    <a onClick={() => setProShowMoreContent(false)} className='link-opacity-100-hover position-relative'>hide details</a>
                            }

                            {proSelected && <i className={`bi bi-check-circle-fill ${selectedPlan === 'Frizda Pro' ? 'show' : 'd-none'} fs-5`} />}
                        </div>
                    </div>
                </div>
            </div>

            <div className='d-flex flex-column align-items-center'>
                <button onClick={handleNext} className={`btn btn-lg button-plan-next w-25 mx-5 my-3 ${isButtonShaking && 'shake'}`}>Next</button>
                {showFailNextText && <p className='text-danger'>Click on one plan to proceed.</p>}
            </div>
        </div>
    )
}