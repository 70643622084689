import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaChevronDown } from 'react-icons/fa';
import { FaArrowLeft } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

// temp data table data
const SAMPLE_DATA = [
    {
        txnId: 12051,
        id: 15204,
        customerName: 'Morgan Jobs',
        paymentDate: 'June 20, 2024',
        paymentTime: '10:00 AM',
        paymentAmount: '20',
    },
    {
        txnId: 12052,
        id: 15205,
        customerName: 'Morgan Jobs',
        paymentDate: 'June 20, 2024',
        paymentTime: '10:00 AM',
        paymentAmount: '20',
    },
    {
        txnId: 12053,
        id: 15206,
        customerName: 'Morgan Jobs',
        paymentDate: 'June 20, 2024',
        paymentTime: '10:00 AM',
        paymentAmount: '20',
    },
    {
        txnId: 12054,
        id: 15207,
        customerName: 'Morgan Jobs',
        paymentDate: 'June 20, 2024',
        paymentTime: '10:00 AM',
        paymentAmount: '20',
    },
    {
        txnId: 12055,
        id: 15208,
        customerName: 'Morgan Jobs',
        paymentDate: 'June 20, 2024',
        paymentTime: '10:00 AM',
        paymentAmount: '20',
    },
    {
        txnId: 12056,
        id: 15209,
        customerName: 'Morgan Jobs',
        paymentDate: 'June 20, 2024',
        paymentTime: '10:00 AM',
        paymentAmount: '20',
    },
    {
        txnId: 12057,
        id: 15210,
        customerName: 'Morgan Jobs',
        paymentDate: 'June 20, 2024',
        paymentTime: '10:00 AM',
        paymentAmount: '20',
    },
    {
        txnId: 12058,
        id: 15211,
        customerName: 'Morgan Jobs',
        paymentDate: 'June 20, 2024',
        paymentTime: '10:00 AM',
        paymentAmount: '20',
    },
    {
        txnId: 12059,
        id: 15212,
        customerName: 'Morgan Jobs',
        paymentDate: 'June 20, 2024',
        paymentTime: '10:00 AM',
        paymentAmount: '20',
    },
    {
        txnId: 12060,
        id: 15213,
        customerName: 'Morgan Jobs',
        paymentDate: 'June 20, 2024',
        paymentTime: '10:00 AM',
        paymentAmount: '20',
    },
    {
        txnId: 12061,
        id: 15214,
        customerName: 'Morgan Jobs',
        paymentDate: 'June 20, 2024',
        paymentTime: '10:00 AM',
        paymentAmount: '20',
    },
    {
        txnId: 12062,
        id: 15215,
        customerName: 'Morgan Jobs',
        paymentDate: 'June 20, 2024',
        paymentTime: '10:00 AM',
        paymentAmount: '20',
    },
    {
        txnId: 12063,
        id: 15216,
        customerName: 'Morgan Jobs',
        paymentDate: 'June 20, 2024',
        paymentTime: '10:00 AM',
        paymentAmount: '20',
    },
];

// default data size per page
const DATA_PER_PAGE = 10;

export const TicketDetails = () => {
    const { t } = useTranslation();

    const [tableData, setTableData] = useState(SAMPLE_DATA);
    const navigate = useNavigate();

    const loadMoreData = () => {
        const newData = SAMPLE_DATA.slice(tableData.length, tableData.length + DATA_PER_PAGE);
        setTableData([...tableData, ...newData]);
    };

    const navigateToHome = () => {
        navigate('/business/dashboard/tickets');
    };

    useEffect(() => {
        setTableData(SAMPLE_DATA.slice(0, DATA_PER_PAGE));
    }, []);

    return (
        <div>
            <div className="ticket-details-header">
                <div className="px-1 cursor-pointer" onClick={navigateToHome}>
                    <FaArrowLeft size={20} color='#7A38FE' />
                </div>
                <p className='m-0'>{t('ticket')} #15204</p>
            </div>

            <Row className='mt-2 ticket-widget-row'>
                <Col sm={12} md={3} lg={3}>
                    <Card className='ticket-widget-card'>
                        <p>{t('total_sales')}</p>
                        <div className='ticket-sell-count'>
                            <p>
                                <span style={{ fontSize: '2.25rem' }}>2,500</span>
                                <span style={{ color: '#555555' }}>&nbsp;/ 3000</span> &nbsp;Tickets
                            </p>
                        </div>
                    </Card>
                </Col>
                <Col sm={12} md={3} lg={3}>
                    <Card className='ticket-widget-card ticket-revenue-card'>
                        <p>{t('total_revenue')}</p>
                        <div className='ticket-revenue'>€ 125000</div>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={6}>
                    <Card className='ticket-widget-card ticket-details-card'>
                        <div className="ticket-detail-header">
                            <p>Splendid Nights</p>
                            <p>€ 20</p>
                        </div>
                        <div className="ticket-detail-body">
                            <div className="ticket-detail-description">
                                Grab a ticket to visit your favorite
                                nightspot at no-cost entry, Heavy
                                discounts on Carbonated Drinks.
                            </div>
                            <ul>
                                <li>Bar Discount</li>
                                <li>50% on Drinks</li>
                            </ul>
                        </div>
                    </Card>
                </Col>
            </Row>

            <Card className='data-table-card'>
                <p className='data-table-header'>{t('history')}</p>
                <div className="data-table-wrapper">
                    <DataTable
                        value={tableData}
                        className='responsive-table ticket-data-table'
                        resizableColumns={false}
                        emptyMessage="No Data Found"
                        readOnly
                    >
                        <Column field="txnId" header="Txn ID" body={(rowData) => `#${rowData.txnId}`}></Column>
                        <Column field="customerName" header={t("tickets_module.customer_name")}></Column>
                        <Column field="paymentDate" header={t("tickets_module.payment_date")}></Column>
                        <Column field="paymentTime" header={t("tickets_module.payment_time")} headerClassName='payment-time-col' bodyClassName='payment-time-col'></Column>
                        <Column field="paymentAmount" header={t("tickets_module.payment_amount")} body={(rowData) => `€ ${rowData.paymentAmount}`}></Column>
                    </DataTable>
                    <div className="data-table-footer">
                        <span style={{ cursor: 'pointer' }} onClick={loadMoreData}>
                            {t('show_more')} <FaChevronDown />
                        </span>
                    </div>
                </div>
            </Card>
        </div>
    )
}
