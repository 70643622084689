import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { FaArrowLeftLong, FaCircleChevronRight } from "react-icons/fa6";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

const AdsPlanTemplateWrapper = () => {
    const navigate = useNavigate();

    const { pathname } = useLocation();

    const Frizda_ads_plan = [
        {
            id: 1,
            name: "Notify Customers",
            href: "notify-customers",
        },
        {
            id: 2,
            name: "Standout From the Rest",
            href: "stand-out",
        },
        {
            id: 3,
            name: "Your Nightspot on Frizda Feed",
            href: "nightspot-feed",
        },
        {
            id: 4,
            name: "Your Events on Frizda Feed",
            href: "events-feed",
        },
    ];

    return (
        <div className="w-100 h-100">
            <Row className="h-100">
                <Col xs={12} md={8} className="h-100">
                    <Card className="business-dashboard-card">
                        <div className="ads-header">
                            <FaArrowLeftLong
                                size={20}
                                color="#7A38FE"
                                cursor="pointer"
                                onClick={() => navigate(-1)}
                            />
                        </div>
                        <Outlet />
                    </Card>
                </Col>
                <Col xs={12} md={4}>
                    <Card className="ads-template-card">
                        <h5>Checkout Other Frizda Ads Plans</h5>
                        <ul>
                            {Frizda_ads_plan.filter((plan) => {
                                return !pathname.includes(plan.href);
                            }).map((plan) => (
                                <li key={plan.id}>
                                    <Link to={plan.href}>{plan.name}</Link>
                                </li>
                            ))}
                        </ul>
                    </Card>

                    <Card className="ads-template-card">
                        <div
                            className="d-flex justify-content-between align-items-center"
                            onClick={() =>
                                navigate(
                                    "/business/dashboard/frizda-ads/history"
                                )
                            }
                            style={{ padding: "0 20px", color: "#7A38FE", cursor: "pointer" }}
                        >
                            <h4>ADS HISTORY</h4>
                            <FaCircleChevronRight size={20} />
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default AdsPlanTemplateWrapper;
