import React, { createContext, useState, useEffect, useContext, useMemo } from 'react';
// ↓ contexts
import { LoggedInContext } from './businessAuthState';

export const NewNightspotContext = createContext({
    selectedPlan: null, // string
    nightspotName: null, // string
    nightspotDescription: null, // string
    nightspotEmail: null, // string
    images: [], // array of strings
    locationCoords: {
        longitude: null, // number
        latitude: null // number
    },
    locationAddress: null, // string
    nightspotKind: null, // string
    nightspotTheme: null, // string
    entryFee: {
        cost: null, // number
        free: null // boolean
    },
    acceptedPayments: [], // array of strings
    costPerTicket: null, // number
    musicalGenres: {
        genre1: null, // string
        genre2: null, // string
        genre3: null // string
    },
    schedule: { // hour and closing are numbers, closed is a boolean
        monday: {
            opening: { hour: null, minutes: null },
            closing: { hour: null, minutes: null },
            closed: null // boolean
        },
        tuesday: {
            opening: { hour: null, minutes: null },
            closing: { hour: null, minutes: null },
            closed: null // boolean
        },
        wednesday: {
            opening: { hour: null, minutes: null },
            closing: { hour: null, minutes: null },
            closed: null // boolean
        },
        thursday: {
            opening: { hour: null, minutes: null },
            closing: { hour: null, minutes: null },
            closed: null // boolean
        },
        friday: {
            opening: { hour: null, minutes: null },
            closing: { hour: null, minutes: null },
            closed: null // boolean
        },
        saturday: {
            opening: { hour: null, minutes: null },
            closing: { hour: null, minutes: null },
            closed: null // boolean
        },
        sunday: {
            opening: { hour: null, minutes: null },
            closing: { hour: null, minutes: null },
            closed: null // boolean
        }
    },
    drinks: {
        ratedCost: null, // number
        menuAvailable: null, // boolean
        menuFile: null // buffer
    },
    toilets: {
        toiletsAvailable: null, // boolean
        genderNeutralToilets: null, // boolean
        cost: null, // number
        free: null // boolean
    },
    ownedBy: null, // number (owner account ID from MySQL)
    cardReaders: [{ // if Frizda Pro is the plan selected
        brand: null, // string
        details: {} // object
    }],

    /// ↓ Set states variables

    setSelectedPlan: () => { },
    setNightspotName: () => { },
    setNightspotDescription: () => { },
    setNightspotEmail: () => { },
    setImages: () => { },
    setLocationCoords: () => { },
    setLocationAddress: () => { },
    setNightspotKind: () => { },
    setNightspotTheme: () => { },
    setEntryFee: () => { },
    setAcceptedPayments: () => { },
    setCostPerTicket: () => { },
    setMusicalGenres: () => { },
    setSchedule: () => { },
    setDrinks: () => { },
    setToilets: () => { },
    setOwnedBy: () => { },
    setCardReaders: () => { }
});


export function NewNightspotProvider({ children }) {

    const { loggedIn } = useContext(LoggedInContext);

    const [selectedPlan, setSelectedPlan] = useState(localStorage.getItem('selectedPlan') || '');
    const [nightspotName, setNightspotName] = useState(localStorage.getItem('nightspotName') || '');
    const [nightspotDescription, setNightspotDescription] = useState(localStorage.getItem('nightspotDescription') || '');
    const [nightspotEmail, setNightspotEmail] = useState(localStorage.getItem('nightspotEmail') || '');
    const [images, setImages] = useState(JSON.parse(localStorage.getItem('images')) || []);
    const [locationCoords, setLocationCoords] = useState(JSON.parse(localStorage.getItem('locationCoords')) || { longitude: null, latitude: null });
    const [locationAddress, setLocationAddress] = useState(localStorage.getItem('locationAddress') || '');
    const [nightspotKind, setNightspotKind] = useState(localStorage.getItem('nightspotKind') || '');
    const [nightspotTheme, setNightspotTheme] = useState(localStorage.getItem('nightspotTheme') || '');
    const [entryFee, setEntryFee] = useState(JSON.parse(localStorage.getItem('entryFee')) || { cost: null, free: null });
    const [acceptedPayments, setAcceptedPayments] = useState(JSON.parse(localStorage.getItem('acceptedPayments')) || []);
    const [costPerTicket, setCostPerTicket] = useState(localStorage.getItem('costPerTicket') || null);
    const [musicalGenres, setMusicalGenres] = useState(JSON.parse(localStorage.getItem('musicalGenres')) || { genre1: '', genre2: '', genre3: '' });
    const [schedule, setSchedule] = useState(JSON.parse(localStorage.getItem('schedule')) || {
        monday: { opening: { hour: null, minutes: null }, closing: { hour: null, minutes: null }, closed: null },
        tuesday: { opening: { hour: null, minutes: null }, closing: { hour: null, minutes: null }, closed: null },
        wednesday: { opening: { hour: null, minutes: null }, closing: { hour: null, minutes: null }, closed: null },
        thursday: { opening: { hour: null, minutes: null }, closing: { hour: null, minutes: null }, closed: null },
        friday: { opening: { hour: null, minutes: null }, closing: { hour: null, minutes: null }, closed: null },
        saturday: { opening: { hour: null, minutes: null }, closing: { hour: null, minutes: null }, closed: null },
        sunday: { opening: { hour: null, minutes: null }, closing: { hour: null, minutes: null }, closed: null }
    });
    const [drinks, setDrinks] = useState(JSON.parse(localStorage.getItem('drinks')) || { ratedCost: null, menuAvailable: null, menuFile: null });
    const [toilets, setToilets] = useState(JSON.parse(localStorage.getItem('toilets')) || { toiletsAvailable: null, genderNeutralToilets: null, cost: null, free: null });
    const [ownedBy, setOwnedBy] = useState(localStorage.getItem('ownedBy') || null);
    const [cardReaders, setCardReaders] = useState(JSON.parse(localStorage.getItem('cardReaders')) || [{ brand: '', details: {} }]);

    // ↓ whole nightspot variable (as an object)

    const nightspot = useMemo(() => ({
        selectedPlan,
        nightspotName,
        nightspotDescription,
        nightspotEmail,
        images,
        locationCoords,
        locationAddress,
        nightspotKind,
        nightspotTheme,
        entryFee,
        acceptedPayments,
        costPerTicket,
        musicalGenres,
        schedule,
        drinks,
        toilets,
        ownedBy,
        cardReaders,
    }), [selectedPlan, nightspotName, nightspotDescription, nightspotEmail, images, locationCoords, locationAddress, nightspotKind, nightspotTheme, entryFee, acceptedPayments, costPerTicket, musicalGenres, schedule, drinks, toilets, ownedBy, cardReaders]);

    useEffect(() => {
        localStorage.setItem('selectedPlan', selectedPlan);
        localStorage.setItem('nightspotName', nightspotName);
        localStorage.setItem('nightspotDescription', nightspotDescription);
        localStorage.setItem('nightspotEmail', nightspotEmail);
        localStorage.setItem('images', JSON.stringify(images));
        localStorage.setItem('locationCoords', JSON.stringify(locationCoords));
        localStorage.setItem('locationAddress', locationAddress);
        localStorage.setItem('nightspotKind', nightspotKind);
        localStorage.setItem('nightspotTheme', nightspotTheme);
        localStorage.setItem('entryFee', JSON.stringify(entryFee));
        localStorage.setItem('acceptedPayments', JSON.stringify(acceptedPayments));
        localStorage.setItem('costPerTicket', costPerTicket);
        localStorage.setItem('musicalGenres', JSON.stringify(musicalGenres));
        localStorage.setItem('schedule', JSON.stringify(schedule));
        localStorage.setItem('drinks', JSON.stringify(drinks));
        localStorage.setItem('toilets', JSON.stringify(toilets));
        localStorage.setItem('ownedBy', ownedBy);
        localStorage.setItem('cardReaders', JSON.stringify(cardReaders));
        localStorage.setItem('nightspot', JSON.stringify(nightspot));
    }, [selectedPlan, nightspotName, nightspotDescription, nightspotEmail, images, locationCoords, locationAddress, nightspotKind, nightspotTheme, entryFee, acceptedPayments, costPerTicket, musicalGenres, schedule, drinks, toilets, ownedBy, cardReaders]);

    useEffect(() => { // if !logged in, set all the variables back to null
        if (!loggedIn) {
            setSelectedPlan(null);
            setNightspotName(null);
            setNightspotDescription(null);
            setNightspotEmail(null);
            setImages([]);
            setLocationCoords({ longitude: null, latitude: null });
            setLocationAddress(null);
            setNightspotKind(null);
            setNightspotTheme(null);
            setEntryFee({ cost: null, free: null });
            setAcceptedPayments([]);
            setCostPerTicket(null);
            setMusicalGenres({ genre1: null, genre2: null, genre3: null });
            setSchedule({
                monday: { opening: { hour: null, minutes: null }, closing: { hour: null, minutes: null }, closed: null },
                tuesday: { opening: { hour: null, minutes: null }, closing: { hour: null, minutes: null }, closed: null },
                wednesday: { opening: { hour: null, minutes: null }, closing: { hour: null, minutes: null }, closed: null },
                thursday: { opening: { hour: null, minutes: null }, closing: { hour: null, minutes: null }, closed: null },
                friday: { opening: { hour: null, minutes: null }, closing: { hour: null, minutes: null }, closed: null },
                saturday: { opening: { hour: null, minutes: null }, closing: { hour: null, minutes: null }, closed: null },
                sunday: { opening: { hour: null, minutes: null }, closing: { hour: null, minutes: null }, closed: null }
            });
            setDrinks({ ratedCost: null, menuAvailable: null, menuFile: null });
            setToilets({ toiletsAvailable: null, genderNeutralToilets: null, cost: null, free: null });
            setOwnedBy(null);
            setCardReaders([{ brand: null, details: {} }]);
        }
    }, [loggedIn]);


    return (
        <NewNightspotContext.Provider value={{
            selectedPlan, setSelectedPlan,
            nightspotName, setNightspotName,
            nightspotDescription, setNightspotDescription,
            nightspotEmail, setNightspotEmail,
            images, setImages,
            locationCoords, setLocationCoords,
            locationAddress, setLocationAddress,
            nightspotKind, setNightspotKind,
            nightspotTheme, setNightspotTheme,
            entryFee, setEntryFee,
            acceptedPayments, setAcceptedPayments,
            costPerTicket, setCostPerTicket,
            musicalGenres, setMusicalGenres,
            schedule, setSchedule,
            drinks, setDrinks,
            toilets, setToilets,
            ownedBy, setOwnedBy,
            cardReaders, setCardReaders,
            nightspot
        }}>
            {children}
        </NewNightspotContext.Provider>
    );
}