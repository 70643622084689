import React, { useEffect, useState } from 'react';

export default function ToiletAvailability({
    setToiletPrices,
    setAreThereToilets,
    areThereToilets,
    setAreThereNeutralToilets,
    areThereNeutralToilets,
    freeToilets,
    setFreeToilets }) {

    const [solidNumberPrice, setSolidNumberPrice] = useState(null);
    const [centsNumber, setCentsNumber] = useState(null);


    useEffect(() => {
        solidNumberPrice > 99 && setSolidNumberPrice(solidNumberPrice.substring(0, 2));
        solidNumberPrice < 0 && setSolidNumberPrice(Math.abs(solidNumberPrice));

        centsNumber > 99 && setCentsNumber(centsNumber.substring(0, 2));
        centsNumber < 0 && setCentsNumber(Math.abs(centsNumber));

        if (freeToilets) {
            setCentsNumber(0);
            setSolidNumberPrice(0);
        }

        setToiletPrices({
            natNum: solidNumberPrice,
            decimalNum: centsNumber
        });
    }, [solidNumberPrice, centsNumber])

    return (
        <div className='p-4 position-relative w-100'>
            <h5 className='d-flex justify-content-center align-items-center'><i className='bi bi-badge-wc me-3' />Toilets</h5>
            {/* <p className='explanation-text'>Specify the availability of the toilets </p> */}

            <div className='form-check form-switch mt-3'>
                <input className='form-check-input' role='swtich' type='checkbox' id='toiletAvailabilityInput'
                    onClick={() => setAreThereToilets(!areThereToilets)}
                    checked={areThereToilets}
                />
                <label className='form-check-label ms-2'>My establishment has toilets</label>
            </div>

            <div className='form-check form-switch my-2'>
                <input className='form-check-input' role='swtich' type='checkbox' id='toiletAvailabilityInput'
                    onClick={() => setAreThereNeutralToilets(!areThereNeutralToilets)}
                    checked={areThereNeutralToilets}
                    disabled={!areThereToilets}
                />
                <label className='form-check-label ms-2'>My establishment has gender neutral toilets</label>
            </div>

            <div className='my-5 ms-4 d-flex gap-2 align-items-center'>
                <input className='form-control toilet-pricing-inputs' type='number'
                    min={0}
                    max={99}
                    value={solidNumberPrice}
                    onChange={event => setSolidNumberPrice(event.target.value)}
                    disabled={freeToilets || !areThereToilets}
                />

                <h4 className='my-auto'>€</h4>
                <input className='form-control toilet-pricing-inputs' type='number'
                    min={0}
                    max={99}
                    value={centsNumber}
                    onChange={event => setCentsNumber(event.target.value)}
                    disabled={freeToilets || !areThereToilets}
                    required={!freeToilets && areThereToilets}
                />

                <input className='form-check-input ms-4' type='checkbox' id='freeToiletsCheckbox'
                    onClick={() => setFreeToilets(!freeToilets)}
                    checked={freeToilets}
                    disabled={!areThereToilets}
                />
                <label className='form-check-label' htmlFor='freeToiletsCheckbox'>Free toilets</label>
            </div>
        </div>
    )
}