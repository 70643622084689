import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import { EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import REGISTER_NIGHTSPOT from "../../assets/video/landing_page/how_to_register_nightspot.webm";
import HOW_TO_SIGNIN from "../../assets/video/landing_page/how_to_signin.webm";
import CREAET_TICKET from "../../assets/video/landing_page/how_to_create_tickets.webm";
import HOW_TO_SIGNIN_THUMBNAIL from '../../assets/video/landing_page/how_to_signin_thumbnail.webp';
import HOW_TO_REGISTER_THUMBNAIL from '../../assets/video/landing_page/how_to_register_thumbnail.webp';
import CREATE_TICKET_THUMBNAIL from '../../assets/video/landing_page/create_ticket_thumbnail.webp';
import FAQ from "./FAQ";
import "./styles/HowToFrizda.css";
import { useTranslation } from "react-i18next";

const HowToFrizda = () => {
    const { t } = useTranslation();

    // added copy of sample data after every 2 objects cause for swiper loop to work we need atleast 7 objects
    const SAMPLE_DATA = [
        {
            id: 1,
            video: HOW_TO_SIGNIN,
            title: "video1",
            description: "video1",
            thumbnail: HOW_TO_SIGNIN_THUMBNAIL,
        },
        {
            id: 1,
            video: REGISTER_NIGHTSPOT,
            title: "video2",
            description: "video2",
            thumbnail: HOW_TO_REGISTER_THUMBNAIL
        },
        {
            id: 3,
            video: CREAET_TICKET,
            title: "video3",
            description: "video3",
            thumbnail: CREATE_TICKET_THUMBNAIL
        },
        // copy 1
        {
            id: 1,
            video: HOW_TO_SIGNIN,
            title: "video1",
            description: "video1",
            thumbnail: HOW_TO_SIGNIN_THUMBNAIL,
        },
        {
            id: 1,
            video: REGISTER_NIGHTSPOT,
            title: "video2",
            description: "video2",
            thumbnail: HOW_TO_REGISTER_THUMBNAIL
        },
        {
            id: 3,
            video: CREAET_TICKET,
            title: "video3",
            description: "video3",
            thumbnail: CREATE_TICKET_THUMBNAIL
        },
        // copy 2
        {
            id: 1,
            video: HOW_TO_SIGNIN,
            title: "video1",
            description: "video1",
            thumbnail: HOW_TO_SIGNIN_THUMBNAIL,
        },
        {
            id: 1,
            video: REGISTER_NIGHTSPOT,
            title: "video2",
            description: "video2",
            thumbnail: HOW_TO_REGISTER_THUMBNAIL
        },
        {
            id: 3,
            video: CREAET_TICKET,
            title: "video3",
            description: "video3",
            thumbnail: CREATE_TICKET_THUMBNAIL
        },
        // copy 3
        {
            id: 1,
            video: HOW_TO_SIGNIN,
            title: "video1",
            description: "video1",
            thumbnail: HOW_TO_SIGNIN_THUMBNAIL,
        },
        {
            id: 1,
            video: REGISTER_NIGHTSPOT,
            title: "video2",
            description: "video2",
            thumbnail: HOW_TO_REGISTER_THUMBNAIL
        },
        {
            id: 3,
            video: CREAET_TICKET,
            title: "video3",
            description: "video3",
            thumbnail: CREATE_TICKET_THUMBNAIL
        },
    ];

    return (
        <>
            <div className="how-to-container">
                <div className="how-to-header">
                    <p>
                        {t("landing_page.get_started_with_frizda")}{" "}
                        <span>Frizda</span>
                    </p>
                </div>
                <div className="help-video-swiper-wrapper">
                    <Swiper
                        effect="coverflow"
                        slidesPerView={2}
                        centeredSlides={true}
                        coverflowEffect={{
                            rotate: 0,
                            stretch: 0,
                            depth: 300,
                            modifier: 2.5,
                            slideShadows: false,
                        }}
                        navigation={{
                            nextEl: ".nextArrow",
                            prevEl: ".prevArrow",
                        }}
                        loop={true}
                        modules={[Navigation, EffectCoverflow]}
                        className="d-none d-lg-block help-video-swiper"
                    // style={{ width: "100%", height: "500px" }}
                    >
                        {SAMPLE_DATA.map((item) => {
                            return (
                                <SwiperSlide
                                    key={item.id}
                                    className="video-container"
                                >
                                    <video className="w-100 h-100" controls poster={item.thumbnail}>
                                        <source
                                            src={item.video}
                                            type="video/mp4"
                                        />
                                    </video>
                                    <div className="d-flex flex-column align-items-center">
                                        <p
                                            style={{
                                                fontFamily:
                                                    "'DM Sans', sans-serif",
                                            }}
                                        >
                                            {t(
                                                `landing_page.videos.${item.title}`
                                            )}
                                        </p>
                                        <p
                                            style={{
                                                width: "80%",
                                                fontFamily:
                                                    "'DM Sans', sans-serif",
                                                fontSize: "0.8rem",
                                                color: "#7c7c7c",
                                                textAlign: "center",
                                            }}
                                        >
                                            {t(
                                                `landing_page.video_description.${item.description}`
                                            )}
                                        </p>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                    <div className="d-none d-lg-flex help-video-swiper-buttons">
                        <FaChevronLeft className="prevArrow" />
                        <FaChevronRight className="nextArrow" />
                    </div>

                    <Swiper
                        slidesPerView={1}
                        centeredSlides={true}
                        navigation={true}
                        modules={[Navigation]}
                        loop={true}
                        className="d-lg-none"
                        style={{ width: "90%", height: "100%" }}
                    >
                        {SAMPLE_DATA.map((item) => {
                            return (
                                <SwiperSlide
                                    key={item.id}
                                    className="video-container"
                                >
                                    <video className="w-100 h-100" controls>
                                        <source
                                            src={item.video}
                                            type="video/mp4"
                                        />
                                    </video>
                                    <p>
                                        {t(`landing_page.videos.${item.title}`)}
                                    </p>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
            </div>
            <FAQ />
        </>
    );
};

export default HowToFrizda;
