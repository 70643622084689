import React, { useEffect, useState } from "react";

import Hamburger from "hamburger-react";

import "../service-navbar/service-navbar.css";

import blacklogo from "../../styling/imgs/frizdaLogoBlack.png";
import { userLogo } from "../../../../assets/images";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { IoLanguage } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import i18n, { supportedLngs } from "../../../../utils/i18n";
import { IoMdClose } from "react-icons/io";
import { MdOutlineLogout } from "react-icons/md";
import { ApiPost } from "../../../../utils/ApiData";
import { authActions } from "../../../../redux/authslice";
import { useDispatch } from "react-redux";
import { HiMenuAlt3 } from "react-icons/hi";
import ConsultantModal from "../../../landing-page/consultant-modal/ConsultantModal";

function ServiceNavbar(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [isOpen, setOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [showConsultantModal, setShowConsultantModal] = useState(false);

    const handleLanguageChange = (language) => {
        i18n.changeLanguage(language);
    };

    const toggleSidebar = () => {
        setOpen(!isOpen);
    };

    const logout = async () => {
        toggleSidebar();
        try {
            await ApiPost("user/signout");
            dispatch(authActions.logout());
            navigate("/business/login");
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            {/* <nav className="service-parent-nav">
                <div className="service-navbar1">
                    <div className="service-navbar-content">
                        <div className="plan-nav-left">
                            <img
                                src={blacklogo}
                                alt="logo"
                                width="110px"
                                height="22px"
                            />
                        </div>
                        <div className="nav-center">
                            <p className="plan-content">Call a Consultant</p>
                            <p className="plan-content">Features</p>
                        </div>
                        <div className="service-plan-nav-right">
                            <p className="plan-content">
                                {props.name} {props.lastName}
                            </p>
                            <div className="plan-nav-user-account">
                                <img
                                    src={userLogo}
                                    alt="user"
                                    className="user-image"
                                    height={41}
                                    width={41}
                                />
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav> */}

            <nav
                className={`nav-container white-nav-container ${
                    isScrolled ? "scrolled" : ""
                }`}
            >
                <Link to="/business" className="nav-logo">
                    <img
                        src={blacklogo}
                        alt="Frizda"
                        className="w-100 h-100 object-fit-contain"
                    />
                </Link>
                <div className="nav-links w-auto d-none d-md-flex align-items-center justify-content-center gap-4" style={{ position: "absolute", inset: "0" }}>
                    <div className="nav-link">
                        {t("landing_page.navbar.features")}
                    </div>
                    <div className="nav-link cursor-pointer" onClick={() => setShowConsultantModal(true)}>
                        {t("landing_page.call_consultant")}
                    </div>
                </div>

                <div className="d-flex align-items-center gap-3" style={{ zIndex: 1 }}>
                    <div className="d-none d-md-flex align-items-center gap-3">
                        <IoLanguage size={20} />
                        <select
                            className="nav-link language-select"
                            value={i18n.language}
                            onChange={(e) =>
                                handleLanguageChange(e.target.value)
                            }
                        >
                            {Object.entries(supportedLngs).map(
                                ([code, name]) => (
                                    <option value={code} key={code}>
                                        {name}
                                    </option>
                                )
                            )}
                        </select>

                        <div className="nav-profile-container">
                            <p>
                                {props.name} {props.lastName}
                            </p>
                            <div className="nav-profile-pic">
                                <img
                                    src={userLogo}
                                    alt="Frizda"
                                    className="w-100 h-100 object-fit-contain"
                                    style={{ filter: "invert(1)" }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="d-flex d-md-none align-items-center">
                        <HiMenuAlt3
                            size={28}
                            color={"#0b0614"}
                            onClick={toggleSidebar}
                        />
                    </div>
                </div>

                <ConsultantModal
                    showConsultantModal={showConsultantModal}
                    setShowConsultantModal={setShowConsultantModal}
                />
            </nav>

            {/* responsive navbar */}
            {/* <div className="responsivebg">
                <div className="res-plan-nav-humberger">
                    <Hamburger
                        color="black"
                        className="hamburger -test"
                        toggled={isOpen}
                        toggle={setOpen}
                    />

                    <div className="plan-nav-left">
                        <img
                            src={blacklogo}
                            alt="this is cone svg"
                            width="96px"
                            height="17px"
                        />
                    </div>
                </div>
                {isOpen && (
                    <div className="plan-nav-red-bg">
                        <div className="plan-nav-right-responsive">
                            <p className="plan-content">Call a consultant</p>
                            <p className="plan-content">Features</p>
                            <div className="plan-nav-user-account">
                                <img
                                    src={userLogo}
                                    alt="user"
                                    className="user-image"
                                    height={41}
                                    width={41}
                                />
                                <div>
                                    <p className="plan-content">
                                        {props.name} {props.lastName}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div> */}

            <div className={`d-md-none sidebar ${isOpen ? "collapsed" : ""}`}>
                <div className="sidebar-links">
                    <div className="nav-profile-container">
                        <p style={{ color: "#0b0614" }}>
                            {props.name} {props.lastName}
                        </p>
                        <div className="nav-profile-pic">
                            <img
                                src={userLogo}
                                alt="Frizda"
                                className="w-100 h-100 object-fit-contain"
                                style={{ filter: "invert(1)" }}
                            />
                        </div>
                    </div>

                    <div className="nav-link  d-flex align-items-center">
                        <IoLanguage size={20} color="#555555" />
                        <select
                            className="nav-link language-select py-0"
                            value={i18n.language}
                            onChange={(e) =>
                                handleLanguageChange(e.target.value)
                            }
                        >
                            {Object.entries(supportedLngs).map(
                                ([code, name]) => (
                                    <option value={code} key={code}>
                                        {name}
                                    </option>
                                )
                            )}
                        </select>
                    </div>
                    <div className="nav-link">
                        {t("landing_page.navbar.features")}
                    </div>

                    <div
                        className="nav-link d-flex align-items-center"
                        onClick={logout}
                    >
                        <MdOutlineLogout size={20} color="#555555" />
                        <span className="ms-2">{t("logout")}</span>
                    </div>
                </div>
                <div
                    className="d-block d-lg-none"
                    style={{ position: "absolute", top: "20px", right: "20px" }}
                >
                    <IoMdClose
                        size={28}
                        color="#000000"
                        onClick={toggleSidebar}
                    />
                </div>
            </div>
        </>
    );
}

export default ServiceNavbar;
