import { default as i18n } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import ENG from '../assets/locales/en/translation.json';
import SPA from '../assets/locales/es/translation.json';
import backend from "i18next-http-backend";

const resources = {
    en: {
        translation: ENG
    },
    es: {
        translation: SPA
    }
}

export const supportedLngs = {
    en: "English",
    es: "Español"
};

const detectionOptions = {
    // order and from where user language should be detected
    order: ['localStorage', 'sessionStorage', 'htmlTag', 'navigator'],

    // keys or params to lookup language from
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',

    // cache user language on
    caches: ['localStorage', 'sessionStorage'],

    // Set a fallback language if no language is detected
    checkWhitelist: true, // ensures only allowed languages will be used
};

i18n
    .use(LanguageDetector) // Detects user's language base on browser language settings
    .use(backend)
    .use(initReactI18next) // initializes react-i18next
    .init({
        detection: detectionOptions,
        resources: resources,
        fallbackLng: 'en', // Default language
        supportedLngs: Object.keys(supportedLngs),
        debug: true, // Set this to false in production
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
