import React, { useContext, useState } from 'react';
import BusinessNavbar from './BusinessNavbar';
import '../styling/styles/business/account-dashboard.css' // ← CSS file for AccountDashboard & components
import axios from 'axios';
import { LoggedInContext } from './businessStates&Auth/businessAuthState';
// ↓ Setting components
import ChangeEmail from './account-dashboard-components/ChangeEmail';
import ChangePassword from './account-dashboard-components/ChangePassword';
import CardReaders from './add-nightclub-requirements/CardReaders';

export default function AccountDashboard(props) {

    const { name, lastName } = useContext(LoggedInContext);
    const [setting, setSetting] = useState('');

    return (
        <div className='row overflow-y-hidden p-0 vh-100' id='account-dashboard'>
            <BusinessNavbar />

            <div className='col-3 h-100 d-flex flex-column dashboard-menu'>
                <p className='fs-3 fw-bold ms-5 my-4'>Account settings</p>

                <div className='account-details px-5 py-3'>
                    <p className='fs-5 fw-bold border-top pt-3'>Account details</p>
                    <div>
                        <a className='setting' onClick={() => setSetting('changeEmail')}>Change email</a>
                    </div>
                    <div>
                        <a className='setting' onClick={() => setSetting('changePassword')}>Change password</a>
                    </div>
                </div>

                <div className='account-finances px-5 py-3'>
                    <p className='fs-5 fw-bold border-top pt-3'>Finances</p>
                    <div>
                        <a className='setting' onClick={() => setSetting('changeCardReaders')}>Card readers</a>
                    </div>
                    <div>
                        <a>Transactions</a>
                    </div>
                    <div>
                        <a>Revenues</a>
                    </div>
                </div>

            </div>
            <div className='col-9 p-5'>
                <div className='settings-welcome-container'>
                    {setting === '' &&
                        <div className='d-flex flex-column align-items-center'>
                            <p className='settings-welcome-title fw-bolder mt-5'>Welcome, <sapn className='settings-username'>{name}</sapn></p>

                            <div className='container-fluid settings-welcome-features'>
                                <div className='row'>
                                    <div className='col-4 rounded-start-2 border'>
                                        <i className='bi bi-shield-lock-fill' />
                                        <p className='fs-3 float-start'>Secure your account</p>
                                        <p className='fs-4 text-subtitle px-2'>Explore the settings on <span className='text-frizda'>Privacy</span> to optimize your user experience in the platform</p>
                                    </div>
                                    <div className='col-4 border-top border-top border-bottom'>
                                        <i className='bi bi-bank' />
                                        <p className='fs-3 float-start'>Manage your finances</p>
                                        <p className='fs-4 text-subtitle px-2'>Explore the settings on <span className='text-frizda'>Finances</span> to optimize the economic data visualization</p>
                                    </div>
                                    <div className='col-4 rounded-end-2 border'>
                                        <i className='bi bi-bell-fill' />
                                        <p className='fs-3 float-start'>Set notifications</p>
                                        <p className='fs-4 text-subtitle px-2'>Explore the settings on <span className='text-frizda'>Notifications & Alarms</span> to optimize your user experience using the platform</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {setting === 'changeEmail' && <ChangeEmail />}
                    {setting === 'changePassword' && <ChangePassword />}
                    {setting === 'changeCardReaders' && <CardReaders />}
                </div>
            </div>
        </div>
    )
}