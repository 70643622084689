import React from "react";

import "../statistics/statistics.css";

import { useNavigate } from "react-router-dom";
import dot from "../../styling/imgs/dot.svg";
import statsimage from "../../styling/imgs/stats.png";
import whitearrow from "../../styling/imgs/white-arrow.png";
function Statistics() {
    const navigate = useNavigate();

    return (
        <div className="statistics-main-div">
            <div className="statistics-child-div">
                <div className="statistics-content-div">
                    <div className="statistics-left-image-div">
                        <img
                            src={statsimage}
                            alt="stats"
                            className="statistics-img"
                        />
                    </div>
                    <div className="statistics-right-content-div">
                        <h1 className="statistics-heading"> Statistics</h1>

                        <div className="statistics-bullet-div">
                            <img
                                src={dot}
                                alt="dot"
                                className="statistics-dot"
                            />

                            <p className="statistics-para">
                                Track real revenue (including tokens)
                            </p>
                        </div>
                        <div className="statistics-bullet-div">
                            <img
                                src={dot}
                                alt="dot"
                                className="statistics-dot"
                            />

                            <p className="statistics-para">Get client data</p>
                        </div>
                        <div className="statistics-bullet-div">
                            <img
                                src={dot}
                                alt="dot"
                                className="statistics-dot"
                            />

                            <p className="statistics-para">
                                Identify high-potential leads from your audience
                            </p>
                        </div>
                        <div className="statistics-bullet-div">
                            <img
                                src={dot}
                                alt="dot"
                                className="statistics-dot"
                            />

                            <p className="statistics-para">
                                Monitor content performance and reach
                            </p>
                        </div>

                        <div className="statistics-btn-div1">
                            <button
                                className="statistics-btn"
                                onClick={() =>
                                    navigate("/business/dashboard/client-data")
                                }
                            >
                                <div className="statistics-btn-div">
                                    <h1 className="statistics-btn-para">
                                        VIEW DETAILS
                                    </h1>
                                    <div className="btn-arrow-bg">
                                        <img
                                            src={whitearrow}
                                            width={15}
                                            height={19}
                                            alt="arrow"
                                        />
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Statistics;
