import React, { useEffect, useState } from "react";
import "./pre-join.css";
import { Card, Col, Modal, Row } from "react-bootstrap";
import { frizdaLogo, prejoinIcon } from "../../../assets/images";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const PreJoin = () => {
    const [showWelcomeModal, setShowWelcomeModal] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="pre-join-container">
            <Row>
                <Col sm={12}>
                    <h2 className="pre-join-heading">
                        Be the <span>First.</span> Get the <span>Best</span>
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={6} className="pre-join-text">
                    <p>
                        Frizda is made for you <br /> to manage your <br />
                        <span>NIGHTSPOT</span>
                    </p>
                </Col>
                <Col sm={12} md={6}>
                    <Card className="pre-join-card">
                        <div className="pre-join-card-header">
                            <div className="pre-join-card-text">
                                <div className="logo-text">
                                    <img
                                        src={prejoinIcon}
                                        alt="Pre Join Frizda"
                                    />
                                    <h2>Prejoin Offer</h2>
                                </div>
                                <p>Exclusive Deal for Early Subscribers!</p>
                            </div>
                            <button onClick={() => setShowWelcomeModal(true)}>
                                Claim Offer Now
                            </button>
                        </div>
                        <ul>
                            <li>Get Premium Discount on Launch</li>
                            <li>10% off on future upgrades</li>
                            <li>Early access to new tools and features</li>
                            <li>Priority support and onboarding assistance</li>
                        </ul>
                    </Card>
                </Col>
            </Row>
            <WelcomeModal
                showWelcomeModal={showWelcomeModal}
                setShowWelcomeModal={setShowWelcomeModal}
            />
        </div>
    );
};

export default PreJoin;

const WelcomeModal = ({ showWelcomeModal, setShowWelcomeModal }) => {

    const navigate = useNavigate();

    return (
        <Modal
            show={showWelcomeModal}
            onHide={setShowWelcomeModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="welcome-modal"
        >
            <Modal.Body className="welcome-modal-body">
                <div className="welcome-modal-content">
                    <div className="welcome-modal-header">
                        <div className="welcome-modal-logo">
                            <img src={frizdaLogo} alt="Frizda" />{" "}
                            Congratulations! You're In!
                        </div>
                        <p>Welcome to Frizda Pre-Join Offer.</p>
                    </div>
                    <button onClick={() => navigate("/business")}>GET STARTED</button>
                </div>
                <div
                    className="position-absolute d-flex justify-content-center align-items-center cursor-pointer"
                    style={{ top: 20, right: 20 }}
                    onClick={() => setShowWelcomeModal(false)}
                >
                    <IoClose size={25} color="#4A4A4A" />
                </div>
            </Modal.Body>
        </Modal>
    );
};
