import React, { useContext, useState, useEffect } from "react";
import "../styling/styles/business/business.css"; // ← css classes for this file.
import "../frizda_business/styling/navbar.css";

import { useNavigate } from "react-router-dom";
import Hamburger from "hamburger-react";

import navbarlogo from "../frizda_business/styling/images/navbarlogo.png";
import frizdaLogoWhite from "../frizda_business/styling/images/frizdaLogoWhite.png";
import { LoggedInContext } from "./businessStates&Auth/businessAuthState";
import user from "../frizda_business/styling/images/user.png";

export default function BusinessNavbar(props) {
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  const {
    loggedIn,
    setLoggedIn,
    name,
    lastName,
    ownedNightspots,
    setOwnedNightspots,
    accountId,
  } = useContext(LoggedInContext);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const signupRedirect = () => {

  }

  return (
    <>
      <nav className={`parent-nav ${isScrolled ? "scrolled" : ""}`}>
        <div className="navbar1">
          <div className="navbar-content">
            <div className="nav-left">
              <img
                src={frizdaLogoWhite}
                alt="this is cone svg"
                width="96px"
                height="17px"
                onClick={() => navigate('/business')}
              />
            </div>

            <div className="nav-right">
              <p className="content">Features</p>
              <p className="content">Help</p>
              <p className="content">About</p>

              {loggedIn ?
                <div className="plan-nav-user-account">
                  <img
                    src={user}
                    alt="user"
                    className="user-image"
                    height={41}
                    width={41}
                  />
                  <div>
                    <p className="content">{name} {lastName}</p>
                  </div>
                </div>
                :
                <button className="conditional-signin-btn" onClick={() => navigate('/business/signup')}>Sign in</button>
              }
            </div>
          </div>
        </div>
      </nav>

      {/* responsive navbar */}
      <div className="responsivebg">
        <div className="res-nav-humberger">
          {/* Hamburger icon */}
          <Hamburger color="white" toggled={isOpen} toggle={setOpen} />

          {/* Red background div */}

          <div className="nav-left">
            <img src={navbarlogo} alt="this is cone svg" />
          </div>
        </div>
        {isOpen && (
          <div className="red-bg">
            <div className="nav-right-responsive position-relative">
              <p className="content">Features</p>
              <p className="content">Help</p>
              <p className="content">About</p>

              {loggedIn ?
                <div className="plan-nav-user-account">
                  <img
                    src={user}
                    alt="user"
                    className="user-image"
                    height={41}
                    width={41}
                  />
                  <div>
                    <p className="content">{name} {lastName}</p>
                  </div>
                </div>
                :
                <button className="conditional-signin-btn" onClick={() => navigate('/business/signup')}>Sign in</button>}
              {/* TODO: Make the onClick work */}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
