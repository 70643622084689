import React from 'react'
import { Spinner } from 'react-bootstrap'

const PrimaryButton = ({ title, mode, icon, height, customBtnStyle = '', customTitleStyle = '', loading = false, ...props }) => {
    return (
        <button
            className={`btn-primary rounded d-flex justify-content-around ${customBtnStyle}`}
            style={{ height: height ? height : 50, width: mode == 'full' ? '100%' : '46%', paddingLeft: "5px", paddingRight: "5px", outline: "none" }}
            {...props}
        >
            {icon && icon}
            {!loading
                ?
                <span className={`btn-text ${customTitleStyle}`}>{title}</span>
                :
                <Spinner animation="border" variant="light" />
            }
        </button>
    )
}

export default PrimaryButton