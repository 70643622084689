import React, { useState } from 'react';
import '../styling/styles/authentication/authentication.css'; // ← CSS file for this React component
// ↓ Redirection
import { Link, useNavigate } from 'react-router-dom';
import { GoogleButton } from './SignUp';
// ↓ Authentication
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { FacebookProvider, LoginButton } from 'react-facebook';
import Footer from '../components/home-page/Footer';
import SyncLoader from "react-spinners/SyncLoader";
// ↓ Backend
import axios from 'axios';

export default function Login(props) {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);  // ← To modify the frontend accordingly

    async function submit(event) {
        event.preventDefault();

        const email = document.getElementById('emailLogin').value;
        const password = document.getElementById('passwordLogin').value;

        const account = {
            ownerAccount: false,
            email: email,
            password: password
        }

        try {
            setLoading(true);

            const response = await axios.post('https://polar-hamlet-90480-1e735d673784.herokuapp.com/authenticate', account);

            if (response.status !== 200) {
                throw new Error(response.statusText);
            } else {
                // Redirect accordingly
                response.data.status === 'Authenticated' ? navigate('/') : navigate('/signup');
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }



    return (
        <>
            <div className='w-100 d-flex justify-content-center align-content-center' id='auth-section'>

                <Link to='/'><h1 className='brand-title'>Frizda</h1></Link>

                <div className='card bg-dark text-white my-auto col-lg-3 col-sm-0' id='card-login'>
                    <div className='card-body'>
                        <h1 className='mt-5 mb-4'>Log in</h1>

                        <form onSubmit={submit}>
                            <div className='mb-4'>
                                <label htmlFor='emailLogin' className='form-label'>Username</label>
                                <input type='email' id='emailLogin' className='form-control' data-bs-theme='dark' placeholder='Enter your Email or Username' />
                            </div>

                            <div className='mb-4'>
                                <label htmlFor='passwordLogin' className='form-label'>Password</label>
                                <input type='password' id='passwordLogin' className='form-control' placeholder='Enter your password' data-bs-theme='dark'></input>
                            </div>

                            <div className='row'>

                                <div className='col-lg-6 col-md-12 d-flex align-items-center'>
                                    <a className='password-recovery mb-5' href='/signup'>I forgot my password</a>
                                </div>

                                <div className='col-lg-6 col-md-12 d-flex align-items-center gap-3'>
                                    {loading &&
                                        <SyncLoader
                                            color="grey"
                                            margin={2}
                                            size={4}
                                            speedMultiplier={0.8}
                                        />}

                                    <button type='submit' className='btn btn-outline-warning ms-5' disabled={loading}>
                                        <p className='sign-email-next my-auto' id='login-continue'>Continue</p>
                                        <i className='bi bi-caret-right ms-2' id='login-arrow' />
                                    </button>

                                </div>

                            </div>

                        </form>
                        <p className='d-flex justify-content-center mt-4 mb-4 or-login'>Or</p>


                        {/* ↓ Google Auth */}
                        <GoogleOAuthProvider clientId='987703423035-7a8a3l0dkrul53cko7m8oc4h5iavuni0.apps.googleusercontent.com'>
                            <GoogleButton
                                authType='Continue with Google'
                            />
                        </GoogleOAuthProvider>

                        {/* ↓ Facebook Auth */}
                        <FacebookProvider appId='655595690070827' >
                            <LoginButton
                                scope='email'
                                onSuccess={res => console.log(res.status)}
                                onError={err => console.log(err)}
                                className='btn facebook-button mt-4 mb-4'
                            >
                                Continue with Facebook <i className='bi-facebook' />
                            </LoginButton>
                        </FacebookProvider>

                    </div>
                </div>

            </div>

            <Footer />
        </>
    )
}