import React, { useContext, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LoggedInContext } from "../businessAuthState";
// ↓ Backend operator

import "../auth-components/auth-styles/login.css";

import LoginSpinner from "./auth-styles/spinners/LoginSpinner";

import { Toast } from "primereact/toast";
import { useDispatch } from "react-redux";
import { authActions } from "../../../redux/authslice";
import { ApiPostNoAuth } from "../../../utils/ApiData";
import hide from "../auth-components/auth-styles/images/email2.png";
import show from "../auth-components/auth-styles/images/eye.png";
import logo from "../auth-components/auth-styles/images/frizdausual.png";
import googleimage from "../auth-components/auth-styles/images/google.png";
import { useTranslation } from "react-i18next";

export default function LogIn(props) {
    const navigate = useNavigate();

    const { t } = useTranslation();
    const toast = useRef(null);
    const dispatch = useDispatch();
    const { setLoggedIn, setName, setLastName, setEmail, setAccountId } =
        useContext(LoggedInContext);

    const [userNotFound, setNoUserFound] = useState(false);
    const [otherError, setOtherError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    async function authenticateUser(event) {
        event.preventDefault();

        const sentEmail = document.getElementById("emailBusinessLogin").value;
        const sentPassword = document.getElementById(
            "passwordBusinessLogin"
        ).value;

        const user = {
            // ownerAccount: true,
            email: sentEmail,
            password: sentPassword,
        };

        try {
            setNoUserFound(false);
            setOtherError(false);
            setShowLoader(true);

            const userData = await ApiPostNoAuth("user/signin", user);
            if (userData && userData.data) {
                dispatch(authActions.login(userData.data));

                setName(userData.data.firstname);
                setLastName(userData.data.lastname);
                setEmail(userData.data.email);
                setAccountId(userData.data.id);
                // setOwnedNightspots(userData.data.owned_nightspots);
                setLoggedIn(true);
                navigate("/business");
            }
            setShowLoader(false);
        } catch (error) {
            setShowLoader(false);
            let errorMessage = error
                ? error?.message
                    ? error?.message
                    : error
                : "Something went wrong";
            toast.current.show({
                severity: "error",
                summary: "Error",
                detail: errorMessage,
                life: 2000,
            });
        }
    }

    async function loginWithGoogle() {
        window.location.href =
            "https://accounts.google.com/o/oauth2/v2/auth?client_id=597017456043-lavr17js9qd1dbb8990o56eo8fkhcrou.apps.googleusercontent.com&response_type=code&scope=openid%20email&redirect_uri=http://localhost:3000/auth/callback";
    }

    return (
        <div className="business-signup-parent">
            <div className="business-signup-child">
                <div className="business-login-content">
                    <div className="business-login-left-div">
                        <img
                            src={logo}
                            alt="logo"
                            width={88}
                            className="login-logo"
                        />
                        <h1 className="business-login-heading">{t("auth.welcome_back")}</h1>
                        <p className="login-signup-link">
                            {t("auth.dont_have_an_account")}&nbsp;
                            <Link
                                to="/business/signup"
                                style={{ textDecoration: "none" }}
                            >
                                <span className="signup-link">{t("auth.sign_up")}</span>
                            </Link>
                        </p>
                        <div className="or-login-div">
                            <hr className="or-hr" />
                            <p className="or-para">OR</p>
                            <hr className="or-hr" />
                        </div>
                        <button
                            className="continue-with-google-div"
                            onClick={loginWithGoogle}
                        >
                            <img
                                src={googleimage}
                                width="19px"
                                height="19px"
                                alt="google"
                                className="google-image"
                            />

                            <p className="continue-with-google">
                                {t("auth.continue_with_google")}
                            </p>
                        </button>
                    </div>
                    <div className="business-login-right-div">
                        <form
                            className="signupform"
                            onSubmit={authenticateUser}
                        >
                            <input
                                className="signupinputemail"
                                id="emailBusinessLogin"
                                type="email"
                                placeholder={t("auth.email")}
                                required
                            />

                            <div
                                className="hide-div-login"
                                onClick={togglePasswordVisibility}
                            >
                                <img
                                    src={showPassword ? show : hide}
                                    width="25px"
                                    height="25px"
                                    alt="hide"
                                />
                            </div>

                            <input
                                className="logininputpass"
                                id="passwordBusinessLogin"
                                placeholder={t("auth.password")}
                                type={showPassword ? "text" : "password"}
                                required
                            />

                            <Link
                                to="/business/forgot-password"
                                style={{ textDecoration: "none" }}
                            >
                                <p className="login-forgot-pass-para">
                                    {t("auth.forgot_password")}
                                </p>
                            </Link>
                            {userNotFound && (
                                <p className="text-red fs-6 mt-2">
                                    Email or Password are incorrect.
                                </p>
                            )}
                            {otherError && (
                                <p className="text-red fs-6 mt-2">
                                    Something went wrong, try again later.
                                </p>
                            )}
                            <LoginSpinner
                                className="login-button"
                                text={t("auth.login")}
                                onSubmit={authenticateUser}
                                loading={showLoader}
                                disabled={showLoader}
                            />
                        </form>
                    </div>
                    {/*  */}
                </div>
            </div>
            <Toast ref={toast} position="top-right" />
        </div>
    );
}
