import React, { useEffect, useState } from 'react';

export default function PaymentMethods({ setChosenPayments, nightclubName, setSentChosenPayments }) {
    const [myClubTicketInfo, setMyClubTicketInfo] = useState(false);
    const [frizdaTokenInfo, setFrizdaTokenInfo] = useState(false);

    const [cash, setCash] = useState(false);
    const [cards, setCards] = useState(false);
    const [clubTickets, setClubTicket] = useState(false);
    const [frizdaTokens, setFrizdaToken] = useState(false);
    
    function getChosenPaymentDetails() {
        const payments = { cash, cards, clubTickets: clubTickets, frizdaTokens: frizdaTokens};
        const displayNames = {
            cash: 'Cash',
            cards: 'Cards',
            clubTickets: nightclubName != '' ? nightclubName + "'s tickets" : 'Club tickets',
            frizdaTokens: 'Frizda Tokens'
        }
        let chosenPayments = [];

        for (let payment in payments) {
            payments[payment] && chosenPayments.push(displayNames[payment]);
        }
        return chosenPayments;
    }

    useEffect(() => {
        setChosenPayments(getChosenPaymentDetails());
        setSentChosenPayments(prevData => ({
            ...prevData,
            cash: cash,
            cards: cards,
            clubTickets: clubTickets,
            frizdaTokens: frizdaTokens
        }));

    }, [cash, cards, clubTickets, frizdaTokens,]);

    useEffect(() => {
        if (frizdaTokenInfo || myClubTicketInfo) {
            setTimeout(() => {
                setFrizdaTokenInfo(false);
                setMyClubTicketInfo(false);
            }, myClubTicketInfo ? 12500 : 25000);
        }
    }, [frizdaTokens]);

    return (
        <div className='p-4 position-relative'>

            <h5 className='d-flex justify-content-center'><i className='bi bi-cash-coin me-3' /> Payment methods</h5>

            <div className='row mt-4'>
                <div className='col-xl-6 col-lg-12'>
                    <div className='form-check form-switch'>
                        <input onChange={() => setCash(!cash)} className='form-check-input me-2' id='paymentCash' type='checkbox' role='switch' />
                        <label htmlFor='paymentcash' className='form-check-label'>Cash</label>
                    </div>
                </div>

                <div className='col-xl-6 col-lg-12'>
                    <div className='form-check form-switch'>
                        <input onChange={() => setCards(!cards)} className='form-check-input me-2' id='paymentCard' type='checkbox' role='switch' />
                        <label htmlFor='paymentCard' className='form-check-label'>Credit/Debit cards</label>
                    </div>
                </div>
            </div>

            <div className='row mt-3'>
                <div className='col-xl-6 col-lg-12'>
                    <div className='form-check form-switch'>
                        <input onChange={() => setClubTicket(!clubTickets)} className='form-check-input me-2' id='paymentClubToken' type='checkbox' role='switch' />
                        <label htmlFor='paymentClubToken' className='form-check-label'>
                            {nightclubName != '' ? nightclubName : 'My club'}'s tickets&nbsp;
                        </label>
                        <a onClick={() => { setMyClubTicketInfo(!myClubTicketInfo); setFrizdaTokenInfo(false) }}><i className='bi bi-question-circle-fill' /></a>
                    </div>

                </div>
                <div className='col-xl-6 col-lg-12'>
                    <div className='form-check form-switch'>
                        <input onChange={() => setFrizdaToken(!frizdaTokens)} className='form-check-input me-2' id='paymentFrizdaToken' type='checkbox' role='switch' disabled />
                        <label htmlFor='paymentFrizdaToken' className='form-check-label'>Frizda Tokens&nbsp;</label>

                        <a onClick={() => { setFrizdaTokenInfo(!frizdaTokenInfo); setMyClubTicketInfo(false) }}><i className='bi bi-question-circle-fill' style={{ color: '#aeaeae' }} /></a>

                    </div>
                </div>

                <div>
                    {frizdaTokenInfo && <p className='explanation-text mt-2'>
                        Frizda Tokens is Frizda's inner currency. Clients buy them on the website and can use them to pay easier and safer on your business.
                        You will get to see how many Frizda Tokens you have earned in real time on your nightclub's dashboard. Frizda Token convertion to national currency
                        is free of charge.
                    </p>}

                    {myClubTicketInfo && <p className='explanation-text mt-2'>
                        If your nightclub has its own currency system (ie. tickets), Frizda can help you communicate it to your customers.
                        You can sell your tickets on Frizda too!
                    </p>}

                </div>
            </div>

        </div>
    )
}