import React, { useRef, useEffect, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css'; // ← Mapbox styles
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
// ↓ Backend operator
import axios from 'axios';
// ↓ Marker stylings
import frizdaMarker from './styling/images/map-markers/frizdaMarker.jpeg';

// Mapbox API ↓

const publicToken = 'pk.eyJ1IjoiZnJpemRhIiwiYSI6ImNsb3ExN3cyeDBnMWMycm12ZWxhOXQ0ejEifQ.J_7FV9WYgfgXHCz5M_8U4g';

mapboxgl.accessToken = publicToken;
// Public token 

export default function Mapbox() {

    // ↓ Map default location
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(4.3572);
    const [lat, setLat] = useState(50.8476);
    const [zoom, setZoom] = useState(12);

    // https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(address)}.json?access_token=${publicToken}

    // ↓ Marker additions in case of nullable coordinates
    async function geocode(address) {
        return await axios.get('https://api.mapbox.com/geocoding/v5/mapbox.places/Dubrovnik%20Airport,%20D8,%20Konavle,%20Dubrovnik-Neretva%2020215,%20Croatia.json?access_token=pk.eyJ1IjoiZnJpemRhIiwiYSI6ImNsb3ExN3cyeDBnMWMycm12ZWxhOXQ0ejEifQ.J_7FV9WYgfgXHCz5M_8U4g')
            .then(response => {
                if (response.data.features && response.data.features.length > 0) {
                    // Return the coordinates of the first result
                    const coords = response.data.features[0].geometry.coordinates;
                    return coords;
                } else {
                    return null;
                }
            })
            .catch(error => {
                console.error(`Error geocoding ${address}:`, error); // Log any errors
                return null;
            });
    }

    // ↓ Initialize map
    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/dark-v10',
            center: [lng, lat],
            zoom: zoom
        });

        // TODO: ADD MARKER WHEN COORDINATES ARE null !!!

        // ↓ Fetch nightclub data from the server
        axios.get(' https://polar-hamlet-90480-1e735d673784.herokuapp.com/nightclubs')
            .then(response => {
                const nightclubs = response.data;

                const promises = nightclubs.map(async nightclub => {
                    let coords;
                    if (nightclub.nightspotCoords.longitude != null) {
                        // Use the coordinates if they exist
                        coords = [nightclub.nightspotCoords.longitude, nightclub.nightspotCoords.latitude];
                    } else if (nightclub.nightspotAddress) {
                        // If no coordinates, use the geocode function to get coordinates from the address
                        coords = await geocode(nightclub.nightspotAddress);
                    }
                    return coords;
                });

                return Promise.allSettled(promises);
            })
            .then(coordsArray => {
                coordsArray.forEach((result, i) => {
                    if (result.status === 'fulfilled' && result.value && result.value[0] !== null && result.value[1] !== null) {
                        // Only add a marker if we have valid coordinates
                        var el = document.createElement('div');
                        el.className = 'marker';
                        new mapboxgl.Marker(el)
                            .setLngLat(result.value)
                            .addTo(map.current); // Don't forget to add the marker to the map
                    }
                });
            })
            .catch(err => console.error(err));

        // ↓ Store new coordinates on interaction
        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });

        // ↓ Send user Location to Frizda Map
        map.current.addControl(
            new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                // When active the map will receive updates to the device's location as it changes.
                trackUserLocation: true,
                // Draw an arrow next to the location dot to indicate which direction the device is heading.
                showUserHeading: true
            })
        );

        // ↓ to check every 100 milliseconds if the GeolocateControl button has been added to the DOM
        setInterval(() => {
            const geolocatorButton = document.querySelector('.mapboxgl-ctrl-top-right');
            const mapboxBrand = document.querySelector('.mapboxgl-ctrl-bottom-left');
            if (geolocatorButton) {
                // ↓ Once the button is available, set the following styiling properties
                geolocatorButton.style.margin = '10px';
                mapboxBrand.style.margin = '10px';

                clearInterval(); // ← Stop the interval (100 Ms) once styling applied
            }
        }, 100);
    });

    return (
        <div className='col-10 p-relative' id='frizdaMap'>
            <div ref={mapContainer} className="map-container" />
        </div>
    );

}