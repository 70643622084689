import React from 'react';
import '../../styling/styles/business/business.css'; // ← Business navbar links styles are on business.css !
import CoinImage from '../../styling/images/decorations/sstokens.png'

export default function FrizdaCoins(props) {
    return (
        <div className='d-flex flex-column p-5' id='frizda-coins'>
            <h1 className='mx-auto'>Frizda Coins</h1>

            <div className='my-5 container-fluid d-flex'>
                <img src={CoinImage} className='w-25 mx-auto' />
            </div>
            <div className='my-5 explanation-container row'>
                <div className='col-8 border'>
                    <p className='fs-1 fw-bold'>
                        Frizda coins explanation 
                    </p>
                </div>
                <div className='col-4 border p-5'>
                    <div>
                        <p className='fs-3'>Your frizda coins:</p>
                        <div className='border d-flex justify-content-center'>
                            8 420 F.C. = €6 014.20
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}