import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputSwitch } from "primereact/inputswitch";
import React, { useRef, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaPencil, FaRegCopy } from "react-icons/fa6";
import { GoArrowUpRight } from "react-icons/go";
import { HiOutlineMinusCircle } from "react-icons/hi2";
import { IoIosCloseCircle, IoIosSearch } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import PageNotFound from "../page-not-found/PageNotFound";
import "./affiliate-marketing.css";
import { Toast } from "primereact/toast";

const AffiliateMarketing = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const toast = useRef(null);

    const [isActive, setIsActive] = useState(false);
    const [showCreateInviteModal, setShowCreateInviteModal] = useState(false);

    return (
        <div className="h-100 w-100 overflow-y-auto affiliate-dashboard-container">
            <div className="w-100 d-flex justify-content-between align-items-center mb-3">
                <a className="know-more-link" href="/">
                    {t("affiliate_marketing_module.know_more")}
                </a>
                <div className="d-flex align-items-center justify-content-center gap-3">
                    {isActive && (
                        <button
                            className="create-invite-btn"
                            onClick={() => setShowCreateInviteModal(true)}
                        >
                            {t("affiliate_marketing_module.create_invite")}
                        </button>
                    )}

                    <div
                        className={`affiliate-activation-switch ${
                            isActive
                                ? "active-affiliate-switch"
                                : "disabled-affiliate-switch"
                        }`}
                    >
                        <p>
                            {isActive
                                ? t("affiliate_marketing_module.activated")
                                : t("affiliate_marketing_module.deactivated")}
                        </p>
                        <InputSwitch
                            className="affiliate-switch"
                            checked={isActive}
                            onChange={(e) => {}}
                        />
                    </div>
                </div>
            </div>

            {isActive ? (
                <div className="affiliate-dashboard-data-container">
                    <div className="affiliate-dashboard-widget-container">
                        <Card>
                            <p className="affiliate-widget-title">
                                {t("affiliate_marketing_module.total_income")}
                            </p>
                            <h6 className="affiliate-widget-value">€ 0</h6>
                            <div className="affiliate-widget-extra text-success">
                                <GoArrowUpRight size={20} /> 0%
                            </div>
                        </Card>
                        <Card>
                            <p className="affiliate-widget-title">
                                {t("affiliate_marketing_module.total_affiliates")}
                            </p>
                            <h6 className="affiliate-widget-value">0</h6>
                            <div className="affiliate-widget-extra text-danger">
                                0 this week
                            </div>
                        </Card>
                        <Card>
                            <p className="affiliate-widget-title">
                                {t("affiliate_marketing_module.total_clicks")}
                            </p>
                            <h6 className="affiliate-widget-value">0</h6>
                            <div className="affiliate-widget-extra text-success">
                                <GoArrowUpRight size={20} /> 0%
                            </div>
                        </Card>
                        <Card>
                            <p className="affiliate-widget-title">
                                {t('affiliate_marketing_module.affiliate_limit')}
                            </p>
                            <h6 className="affiliate-widget-value">0</h6>
                            <div className="d-flex justify-content-end align-items-center">
                                <FaPencil size={15} cursor="pointer" />
                            </div>
                        </Card>
                        <Card>
                            <p className="affiliate-widget-title">
                                {t("affiliate_marketing_module.base_commission")}
                            </p>
                            <h6 className="affiliate-widget-value">0%</h6>
                            <div className="d-flex justify-content-end align-items-center">
                                <FaPencil size={15} cursor="pointer" />
                            </div>
                        </Card>
                    </div>

                    <Card className="data-table-card">
                        <div className="data-table-header-wrapper">
                            <p className="data-table-header">{t("affiliate_marketing_module.affiliates")}</p>
                            <div className="search-input">
                                <input
                                    type="input"
                                    name="search"
                                    placeholder="Search..."
                                />
                                <IoIosSearch size={20} color="#B1B1B1" />
                            </div>
                        </div>
                        <div className="data-table-wrapper">
                            <DataTable
                                value={[
                                    {
                                        name: "John Doe",
                                        email: "johndoe@me.com",
                                        commisionRate: "2%",
                                        generatedLeads: "200",
                                    },
                                ]}
                                className={`responsive-table affiliate-marketing-data-table ${
                                    [].length === 0 ? "empty-data-table" : ""
                                }`}
                                resizableColumns={false}
                                emptyMessage={t("no_data_found")}
                                readOnly
                                onRowClick={() =>
                                    navigate(
                                        "/business/dashboard/affiliate-marketing/1"
                                    )
                                }
                            >
                                <Column
                                    field="name"
                                    header={t("name")}
                                    headerClassName="name-col"
                                ></Column>
                                <Column
                                    field="email"
                                    header={t("email")}
                                    headerClassName="email-col"
                                    align="center"
                                ></Column>
                                <Column
                                    field="commisionRate"
                                    header={t("affiliate_marketing_module.commission_rate")}
                                    headerClassName="commission-rate-col"
                                    className="text-success"
                                    align="center"
                                ></Column>
                                <Column
                                    field="generatedLeads"
                                    header={t("affiliate_marketing_module.generated_leads")}
                                    align="center"
                                    headerClassName="generated-leads-col"
                                    className="text-success"
                                ></Column>
                                <Column
                                    field="removeAffiliate"
                                    header={t("affiliate_marketing_module.remove_affiliate")}
                                    headerClassName="remove-affiliate-col"
                                    align="center"
                                    body={(rowData) => (
                                        <div className="d-flex justify-content-center align-items-center">
                                            <HiOutlineMinusCircle
                                                size={20}
                                                cursor="pointer"
                                                className="text-danger"
                                            />
                                        </div>
                                    )}
                                ></Column>
                            </DataTable>
                            {/* {hasNext && (
                    <div className="data-table-footer">
                        <span
                            style={{ cursor: "pointer" }}
                            onClick={loadMoreData}
                        >
                            {t("show_more")} <FaChevronDown />
                        </span>
                    </div>
                )} */}
                        </div>
                    </Card>
                    <CreateInviteLink
                        showCreateInviteModal={showCreateInviteModal}
                        setShowCreateInviteModal={setShowCreateInviteModal}
                        toastRef={toast}
                    />
                </div>
            ) : (
                <div
                    style={{
                        height: "calc(100vh - 200px)",
                        backgroundColor: "#FFFFFF",
                        borderRadius: "20px",
                    }}
                >
                    <PageNotFound title="Afifiliate Marketing Feature is Available soon." />
                </div>
            )}
            <Toast ref={toast} position="top-right" />
        </div>
    );
};

export default AffiliateMarketing;

const CreateInviteLink = ({
    showCreateInviteModal,
    setShowCreateInviteModal,
    toastRef,
}) => {
    const {t} = useTranslation();
    const copyInviteLink = (link) => {
        navigator.clipboard.writeText(link);
        toastRef.current.show({
            severity: "success",
            summary: t("success"),
            detail: t("affiliate_marketing_module.invite_copied"),
            life: 2000,
        });
    };

    return (
        <Modal
            show={showCreateInviteModal}
            onHide={setShowCreateInviteModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="create-invite-modal"
        >
            <Modal.Body>
                <h6>
                    <b>{t("affiliate_marketing_module.share_invite_link")}</b>
                </h6>
                <div className="copy-invite-link">
                    <a href="http://localhost:3000/business/dashboard/affiliate-marketing/1">
                        http://localhost:3000/business/dashboard/affiliate-marketing/1
                    </a>
                    <FaRegCopy
                        size={20}
                        cursor="pointer"
                        color="#616161"
                        onClick={() =>
                            copyInviteLink(
                                "http://localhost:3000/business/dashboard/affiliate-marketing/1"
                            )
                        }
                    />
                </div>
                <IoIosCloseCircle
                    size={20}
                    className="close-btn"
                    cursor="pointer"
                    onClick={() => setShowCreateInviteModal(false)}
                />
            </Modal.Body>
        </Modal>
    );
};
