import { ReactComponent as Loader } from "../../auth-styles/svgs/spinner.svg"
// import "../auth-styles/signup.css"

const LoginSpinner = ({ text, loading = false, disabled }) => {
  return (
    <button className="login-button"  disabled={disabled}>
      {!loading ? text : <Loader className="spinner" />}
    </button>
  )
}

export default LoginSpinner