import * as yup from "yup"; // Library for defining validation schemas

// Define the schema for each day
export const daySchema = yup.object().shape({
    opens: yup.date().required('*Required'),
    closes: yup.date().required('*Required'),
    closed: yup.boolean().optional()
});

// Define the full schema
export const nightspotSchema = yup.object().shape({
    name: yup.string().required('*Name is required'),
    description: yup.string().required('*Add a description'),
    images: yup.array()
        .of(yup.string())
        .min(1, '*At least one image is required')
        .max(7, '*You can upload up to 7 images')
        .required('*Images are required'),
    address: yup.object().shape({
        nameAdress: yup.string().optional(),
        coordinateAddress: yup.object().shape({
            latitude: yup.number().required(),
            longitude: yup.number().required()
        }).required()
    }).required(),
    nightspotType: yup.object().shape({
        nightclub: yup.boolean().required(),
        nightstpotOrientation: yup.string().required('*Select Nightspot Type')
    }).required('*Select Nightspot Type'),
    schedule: yup.object().shape({
        monday: daySchema,
        tuesday: daySchema,
        wednesday: daySchema,
        thursday: daySchema,
        friday: daySchema,
        saturday: daySchema,
        sunday: daySchema
    }).test(
        'at-least-one-day',
        'At least one day must be specified with both opens and closes times.',
        (value) => {
            if (!value) return false;
            return Object.values(value).some(day => day.opens && day.closes);
        }
    ),
    entry: yup.object().shape({
        free: yup.boolean().optional(),
        cost: yup.string().required('*Enter cost').test(
            'is-required-if-entry-not-free',
            '*Enter cost',
            function (value) {
                const { free } = this.parent;
                if (!free && (!value || value.trim() === '.')) {
                    return false;
                }
                return true;
            }
        ),
        paymentMethods: yup.array().of(yup.string().required()).min(1, '*Select at least one payment method').required('*Select at least one payment method')
    }).required(),
    musicalGenre: yup.array().of(yup.string())
        .min(1, '*Select at least one musical genre')
        .max(3, '*You can select up to 3 musical genres').required("*Select at least one musical genre"),
    drinkSale: yup.object().shape({
        sellsDrinks: yup.boolean().optional(),
        sellsFood: yup.boolean().optional(),
        menu: yup.mixed().optional().nullable()
    }).required(),
    toilets: yup.object().shape({
        available: yup.boolean().required(),
        genderNeutral: yup.boolean().required(),
        free: yup.boolean().required(),
        cost: yup.string().required('*Enter cost').test(
            'is-required-if-toilet-service-not-free',
            '*Enter cost',
            function (value) {
                const { free } = this.parent;
                if (!free && (!value || value.trim() === '.')) {
                    return false;
                }
                return true;
            }
        ),
    }).required(),
    ownedBy: yup.string().optional()
});