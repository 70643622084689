import React, { useRef, useState } from "react";
import {
    IoChevronBackCircleOutline,
    IoChevronForwardCircleOutline,
} from "react-icons/io5";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import {
    Alejandro_García,
    Carlos_Herrera,
    discoBall,
    Juan_López,
    leftDoubleQuotes,
    Lucía_Torres,
    mathias_nagant,
    Sofía_Martínez,
    userLogo
} from "../../assets/images";
import "./styles/Testimonials.css";

const Testimonials = () => {
    const SAMPLE_DATA = [
        {
            id: 1,
            feedback:
                "Frizda is designed to empower nightspot owners, helping them stand out and succeed in a competitive market.",
            user: {
                name: "Mathias Nagant",
                avatar: mathias_nagant,
                role: "CEO of Frizda",
            },
        },
        {
            id: 2,
            feedback:
                "Frizda transformed our nightspot operations. It's essential for any owner wanting to elevate their business.",
            user: {
                name: "Alejandro García",
                avatar: Alejandro_García,
                role: "Owner of Club Luna",
            },
        },
        {
            id: 3,
            feedback:
                "A must-have tool for nightspot owners. Frizda covers all our needs and more.",
            user: {
                name: "Sofía Martínez",
                avatar: Sofía_Martínez,
                role: "Owner of Eclipse Lounge",
            },
        },
        {
            id: 4,
            feedback:
                "An incredible tool for achieving great results. Frizda has been a game changer for us.",
            user: {
                name: "Juan López",
                avatar: null,
                role: "Owner of Galaxy Nights",
            },
        },
        {
            id: 5,
            feedback:
                "Choosing Frizda was the best decision for our venue. It truly makes a difference.",
            user: {
                name: "Carlos Herrera",
                avatar: Carlos_Herrera,
                role: "Owner of Neon Vibes",
            },
        },
        {
            id: 6,
            feedback:
                "Frizda took our nightspot to the next level with unmatched features and support.",
            user: {
                name: "Lucía Torres",
                avatar: Lucía_Torres,
                role: "Owner of Mirage Club",
            },
        },
    ];

    const swiperRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);

    const handlePrevClick = () => {
        if (swiperRef.current) {
            swiperRef.current.slidePrev();
        }
    };

    const handleNextClick = () => {
        if (swiperRef.current) {
            swiperRef.current.slideNext();
        }
    };

    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.realIndex);
    };

    return (
        <div className="testimonials-section position-relative">
            <div className="testimonials-swiper-wrapper">
                <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                    loop={true}
                    modules={[Navigation]}
                    className="testimonials-swiper"
                    onSwiper={(swiper) => {
                        swiperRef.current = swiper;
                    }}
                    onSlideChange={handleSlideChange}
                >
                    {SAMPLE_DATA.map((data) => (
                        <SwiperSlide key={data.id}>
                            <div className="testimonial-content">
                                <div className="testimonials-text">
                                    <p>{data.feedback}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>

                {/* Dynamic Author Information */}
                <div className="testimonial-author">
                    <div className="d-flex align-items-center justify-content-center gap-4">
                        <img
                            src={SAMPLE_DATA[activeIndex].user.avatar || userLogo}
                            alt={`${SAMPLE_DATA[activeIndex].user.name} avatar`}
                            style={{
                                backgroundColor: "#FFFFFF",
                                objectFit: "cover"
                            }}
                        />
                        <div className="author-details">
                            <p className="author-name">
                                {SAMPLE_DATA[activeIndex].user.name}
                            </p>
                            <p className="author-designation">
                                {SAMPLE_DATA[activeIndex].user.role}
                            </p>
                        </div>
                    </div>
                    <div className="slider-arrows">
                        <IoChevronBackCircleOutline
                            size={60}
                            className="feedback-swiper-button-prev cursor-pointer"
                            onClick={handlePrevClick}
                        />
                        <IoChevronForwardCircleOutline
                            size={60}
                            className="feedback-swiper-button-next cursor-pointer"
                            onClick={handleNextClick}
                        />
                    </div>
                </div>
            </div>
            <img
                src={leftDoubleQuotes}
                alt="ldqot"
                className="left-double-quotes"
            />
            <img src={discoBall} alt="Disco" className="disco-ball-svg" />
        </div>
    );
};

export default Testimonials;
