import React, { useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { LoggedInContext } from "../businessAuthState";
import "../auth-components/auth-styles/checkemail.css"

import logo from "../auth-components/auth-styles/images/frizdausual.png";

function CheckEmail() {
  const navigate = useNavigate()
  const { email } = useContext(LoggedInContext);

  return (
    <div className="business-signup-parent">
      <div className="business-signup-child">
        <div className="business-check-content">
          <div className="business-forgot-left-div">
            <img src={logo} alt="logo" width={88} className="forgot-logo" />
            <h1 className="business-login-heading">Check your Email</h1>
          </div>
          <div className="business-forgot-right-div">
            <p className="forgot-para-1">
              We have sent an email to {email}. click on the "Verify Email" button to confirm.
            </p>
            <p className="forgot-para-2">Didn't receive the email? Check on spam or promotions</p>

            <p className="forgot-para-2">Click &nbsp;
              <Link>
                <span className="signup-link">here</span>
              </Link> to resend an email
            </p>

            <button type="submit" className="check-email-resend-button">
              I confirmed my email
            </button>

            <button type="submit" className="forgot-login-button" onClick={() => { navigate('/business') }}>
              I will confirm later
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckEmail;
