// BusinessRoutes.js
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

// ↓ Data providers
import { ApiGet } from "../../utils/ApiData";
import { SelectedPlanProvider } from "../businessStates&Auth/SelectedPlanState";
import { LoggedInProvider } from "../businessStates&Auth/businessAuthState";
import { NewNightspotProvider } from "../businessStates&Auth/newNighstpotState";

export default function BusinessRoutes() {
    const validateCookieUser = async () => {
        try {
            const user = await ApiGet("user/");
            if (user) {
                // navigate("/business/service-home");
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        validateCookieUser();
    }, []);

    return (
        <NewNightspotProvider>
            <LoggedInProvider>
                <SelectedPlanProvider>
                    <Outlet />
                </SelectedPlanProvider>
            </LoggedInProvider>
        </NewNightspotProvider>
    );
}
