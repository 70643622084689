import React, { useContext, useEffect } from "react";
import queryString from 'query-string';
import { useNavigate, useLocation } from "react-router-dom";
import { LoggedInContext } from "../businessAuthState";
import axios from "axios";
// import queryString from 'query-string';

import "../auth-components/auth-styles/passSuccess.css";

import checkmark from "../auth-components/auth-styles/images/checkmark.png";

function PassSuccess() {
  const { loggedIn, accountId } = useContext(LoggedInContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const { token } = queryString.parse(location.search)
    console.log(accountId);
    console.log(token);
    axios.get(`http://localhost:8000/getAccountInfo/${accountId}/verification_token`).then(res => {
      const dbVerificationToken = res.data;
      if (dbVerificationToken === token) {
        axios.put(`http://localhost:8000/updateOwnerAccount/${accountId}/verifiedEmail/1`)
          .then(res => console.log(res)).catch(err => console.error(err));
      } else {
        console.log('error')
      }
    }

    ).catch(err => console.error(err));
  }, []);

  return (
    <div className="business-signup-parent">
      <div className="business-signup-child">
        <div className="business-pass-success-content">
          <img src={checkmark} alt="checkmark" className="checkmark" />

          <h1 className="business-signup-text mt-3">
            Email verified succesfully
          </h1>

          <button type="submit" className="login-button" onClick={() => { !loggedIn ? navigate('/business') : navigate('/business/login') }}>
            {loggedIn ? 'Home Page' : 'Log In'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default PassSuccess;
