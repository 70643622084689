import mapboxgl from "mapbox-gl";
import React, { useEffect, useRef } from "react";

import "../location/location.css";
import { mapMarker } from "../../../../assets/images";

const accessToken =
    "pk.eyJ1IjoiZnJpemRhIiwiYSI6ImNsemIydmdnejAyYjIyaXM1NWdoOW5tdm4ifQ.X9D178XIhhdHxEYv-5dwgw";
mapboxgl.accessToken = accessToken;

function Location({ coordinates }) {
    const mapContainer = useRef(null);
    const mapRef = useRef(null);
    const markerRef = useRef(null);

    const isValidCoordinates = (coords) =>
        Array.isArray(coords) &&
        coords.length === 2 &&
        typeof coords[0] === "number" &&
        typeof coords[1] === "number";

    // Initialize the map only once
    useEffect(() => {
        if (mapContainer.current && !mapRef.current) {
            mapRef.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: "mapbox://styles/mapbox/streets-v12",
                center: [2.1734, 41.3851], // Default coordinates (Center of Barcelona)
                zoom: 10,
                interactive: false, // Disable all interactions
            });

            mapRef.current.on("load", () => {
                mapRef.current.resize();
            });
        }

        return () => {
            if (mapRef.current) {
                mapRef.current.remove();
            }
        };
    }, []);

    // Update the map's center and marker when coordinates prop changes
    useEffect(() => {
        if (isValidCoordinates(coordinates) && mapRef.current) {
            // mapRef.current.flyTo({
            //     center: coordinates,
            //     essential: true,
            //     zoom: 10,
            // });

            if (markerRef.current) {
                markerRef.current.remove();
            }

            markerRef.current = new mapboxgl.Marker({
                element: document.createElement("img"),
                // anchor: "bottom",
            })
                .setLngLat([2.1734, 41.3851])
                .addTo(mapRef.current);

            // Set the source and styling for the img element
            markerRef.current.getElement().src = mapMarker;
            markerRef.current.getElement().style.width = "50px";
            markerRef.current.getElement().style.height = "50px";
            markerRef.current.getElement().style.objectFit = "cover";
        } else if (coordinates) {
            console.error("Invalid coordinates provided:", coordinates);
        }
    }, [coordinates]);

    return (
        <div>
            <div className="service-location-content-div">
                <div className="service-location-left-div">
                    <h1 className="service-location-left-side-heading">
                        Discover the{" "}
                        <span className="service-location-left-side-heading-span">
                            Nightlife
                        </span>{" "}
                        Around You!
                    </h1>
                    <h1 className="service-location-left-side-para">
                        Get Exclusive Benefits with Frizda
                    </h1>
                </div>
                <div className="service-location-right-div">
                    <div
                        ref={mapContainer}
                        style={{
                            width: "80%",
                            height: "400px",
                            borderRadius: "10px",
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default Location;
