import React, { createContext, useState, useEffect } from 'react';

export const LoggedInContext = createContext({
    loggedIn: false,
    name: '',
    lastName: '',
    email: '',
    accountId: null,
    ownedNightspots: [],
    selectedCardReader: '',
    setLoggedIn: () => { },
    setName: () => { },
    setLastName: () => { },
    setEmail: () => { },
    setAccountId: () => { },
    setOwnedNightspots: () => { },
    setSelectedCardReader: () => { }
});

export function LoggedInProvider({ children }) {
    const [loggedIn, setLoggedIn] = useState(() => {
        const savedLoggedIn = localStorage.getItem('loggedIn');
        return savedLoggedIn !== null ? JSON.parse(savedLoggedIn) : false;
    });
    const [name, setName] = useState(() => {
        const savedName = localStorage.getItem('name');
        return savedName !== null ? savedName : '';
    });
    const [lastName, setLastName] = useState(() => {
        const savedLastName = localStorage.getItem('lastName');
        return savedLastName !== null ? savedLastName : '';
    });
    const [email, setEmail] = useState(() => {
        const savedEmail = localStorage.getItem('email');
        return savedEmail !== null ? savedEmail : '';
    });
    const [accountId, setAccountId] = useState(() => {
        const savedAccountId = localStorage.getItem('accountId');
        return savedAccountId !== null ? savedAccountId : '';
    });
    const [ownedNightspots, setOwnedNightspots] = useState(() => {
        const savedOwnedNightspots = localStorage.getItem('ownedNightspots');
        return savedOwnedNightspots !== null ? JSON.parse(savedOwnedNightspots) : [];
    });
    const [selectedCardReader, setSelectedCardReader] = useState(() => {
        const savedSelectedCardReader = localStorage.getItem('selectedCardReader');
        return savedSelectedCardReader !== null ? savedSelectedCardReader : '';
    });

    useEffect(() => {
        localStorage.setItem('loggedIn', JSON.stringify(loggedIn));
        localStorage.setItem('name', name);
        localStorage.setItem('lastName', lastName);
        localStorage.setItem('email', email);
        localStorage.setItem('accountId', accountId);
        localStorage.setItem('ownedNightspots', JSON.stringify(ownedNightspots));
        localStorage.setItem('selectedCardReader', selectedCardReader);
    }, [loggedIn, name, lastName, email, accountId, ownedNightspots, selectedCardReader]);

    useEffect(() => {
        if (!loggedIn) {
            setName('');
            setLastName('');
            setEmail('');
            setAccountId(null);
            setOwnedNightspots([]);
            setSelectedCardReader('');

            localStorage.setItem('name', '');
            localStorage.setItem('lastName', '');
            localStorage.setItem('email', '');
            localStorage.setItem('accountId', '');
            localStorage.setItem('ownedNightspots', JSON.stringify([]));
            localStorage.setItem('selectedCardReader', '');
        }
    }, [loggedIn]);


    return (
        <LoggedInContext.Provider value={{ loggedIn, setLoggedIn, name, setName, lastName, setLastName, email, setEmail, accountId, setAccountId, ownedNightspots, setOwnedNightspots, selectedCardReader, setSelectedCardReader }} > {/* Add selectedCardReader and setSelectedCardReader here */}
            {children}
        </LoggedInContext.Provider>
    );
}