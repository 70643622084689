import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaArrowUp } from "react-icons/fa6";
import "./styles/Hero.css";
import { useNavigate } from "react-router-dom";
import ConsultantModal from "./consultant-modal/ConsultantModal";

const Hero = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showConsultantModal, setShowConsultantModal] = useState(false);

    return (
        <div className="hero-container">
            <div className="hero-content">
                <p className="hero-heading">
                    <span>{t("landing_page.empower")}</span>{" "}
                    {t("landing_page.hero_tagline")}
                </p>

                <p className="hero-tagline">{t("landing_page.hero_desc")}</p>

                <div className="hero-btn-container">
                    <button type="button" className="hero-btn" onClick={() => navigate("pre-join")}>
                        {t("landing_page.pre_join_frizda")}{" "}
                        <FaArrowUp style={{ transform: "rotate(45deg)" }} />
                    </button>
                    <button
                        type="button"
                        className="call-consultant-btn"
                        onClick={() => setShowConsultantModal(true)}
                    >
                        {t("landing_page.call_consultant")}
                    </button>
                </div>
            </div>
            <div className="hero-bg"></div>

            <ConsultantModal
                showConsultantModal={showConsultantModal}
                setShowConsultantModal={setShowConsultantModal}
            />
        </div>
    );
};

export default Hero;