import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import { MdOutlineLogout } from "react-icons/md";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { blacklogo } from "../../../assets/images";
import { ReactComponent as FrizdaAdsActiveIcon } from "../../../assets/svg/ads-active.svg";
import { ReactComponent as AffiliateActiveIcon } from "../../../assets/svg/affiliate-marketing-active.svg";
import { ReactComponent as AffiliateIcon } from "../../../assets/svg/affiliateSvgSidebar.svg";
import { ReactComponent as ClientDataActiveIcon } from "../../../assets/svg/client-data-active.svg";
import { ReactComponent as ClientDataIcon } from "../../../assets/svg/clientDataSvgSidebar.svg";
import { ReactComponent as FrizdaAdsIcon } from "../../../assets/svg/frizdaAdsSvgSidebar.svg";
import { ReactComponent as HelpIcon } from "../../../assets/svg/helpSvgSidebar.svg";
import MoneticsActiveIcon from "../../../assets/svg/moneticsActive.svg";
import MoneticsIcon from "../../../assets/svg/moneticsSvgSidebar.svg";
import { ReactComponent as NightspotDetailsIcon } from "../../../assets/svg/nightSpotDetailSvgSidebar.svg";
import { ReactComponent as NightSpotActiveIcon } from "../../../assets/svg/nightSpotSvgActive.svg";
import { ReactComponent as TicketsActiveIcon } from "../../../assets/svg/tickets-active.svg";
import { ReactComponent as TicketsIcon } from "../../../assets/svg/ticketsSvgSidebar.svg";
import { authActions } from "../../../redux/authslice";
import { ApiPost } from "../../../utils/ApiData";
import { FaGear } from "react-icons/fa6";
import { TiHome } from "react-icons/ti";
import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";

const SideBar = ({ setIsSidebarCollapsed, isSidebarCollapsed }) => {
    const { t } = useTranslation();

    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const menuItems = [
        {
            name: t("home"),
            href: "home",
            icon: (isSelected) => (
                <TiHome size={20} color={isSelected ? "#FFFFFF" : "#555555"} />
            ),
        },
        {
            name: t("monetics"),
            href: "monetics",
            icon: (isSelected) => (
                <img
                    src={isSelected ? MoneticsActiveIcon : MoneticsIcon}
                    alt="monetics"
                    style={{
                        height: "18px",
                        width: "18px",
                        objectFit: "contain",
                    }}
                />
            ),
        },
        {
            name: t("frizda_ads"),
            href: "frizda-ads",
            icon: (isSelected) =>
                isSelected ? <FrizdaAdsActiveIcon /> : <FrizdaAdsIcon />,
        },
        {
            name: t("client_data"),
            href: "client-data",
            icon: (isSelected) =>
                isSelected ? <ClientDataActiveIcon /> : <ClientDataIcon />,
        },
        {
            name: t("nightspot_details"),
            href: "nightspot-details",
            icon: (isSelected) =>
                isSelected ? <NightSpotActiveIcon /> : <NightspotDetailsIcon />,
        },
        {
            name: t("affiliate_marketing"),
            href: "affiliate-marketing",
            icon: (isSelected) =>
                isSelected ? <AffiliateActiveIcon /> : <AffiliateIcon />,
        },
        {
            name: t("tickets"),
            href: "tickets",
            icon: (isSelected) =>
                isSelected ? <TicketsActiveIcon /> : <TicketsIcon />,
        },
        {
            name: t("settings"),
            href: "settings",
            icon: (isSelected) => (
                <FaGear size={18} color={isSelected ? "#FFFFFF" : "#555555"} />
            ),
        },
    ];
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [selectedItem, setSelectedItem] = useState("Nightspot Details");

    const navigateTo = (path) => {
        navigate(`/business/dashboard/${path.href}`);
    };

    // to set active menu item
    useEffect(() => {
        setSelectedItem(
            menuItems.find((item) => pathname.includes(item.href))?.name
        );
        // setSelectedItem(menuItems.find(item => item.href === pathname.split('/').pop())?.name);
    }, [pathname]);

    return (
        <div
            className={`dashboard-sidebar border-end ${
                isSidebarCollapsed ? "" : "active"
            }`}
        >
            <div
                className="d-none d-lg-flex flex-row align-self-center mt-2"
                style={{ height: "80px", width: "120px" }}
            >
                <Link to="/business">
                    <img
                        className="h-100 w-100 object-fit-contain"
                        src={blacklogo}
                        alt="Frizda"
                    ></img>
                </Link>
            </div>
            <ul className="pt-3 pt-lg-0 nav nav-pills flex-column mb-auto" style={{ width: "100%", marginTop: "20px" }}>
                {menuItems.map((item, index) => (
                    <li
                        key={index}
                        className="nav-item mb-4"
                        style={{ marginLeft: "10px", cursor: "pointer" }}
                    >
                        <div
                            className={`nav-link d-flex align-items-center`}
                            style={{
                                backgroundColor:
                                    selectedItem === item.name
                                        ? "#7a38fe"
                                        : "#fff",
                            }}
                            onClick={() => {
                                setIsSidebarCollapsed(true);
                                navigateTo(item);
                            }}
                        >
                            {item.icon(selectedItem === item.name)}
                            <span
                                className="ms-2"
                                style={{
                                    color:
                                        selectedItem === item.name
                                            ? "#fff"
                                            : "#555555",
                                    fontSize: "16px",
                                    fontFamily: "DM Sans",
                                    fontWeight: "bold",
                                }}
                            >
                                {item.name}
                            </span>
                        </div>
                    </li>
                ))}
                <li
                    className="nav-item mb-4"
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                >
                    <div
                        className="nav-link d-flex align-items-center"
                        onClick={() => setShowConfirmModal(true)}
                    >
                        <MdOutlineLogout size={20} color="#555555" />
                        <span
                            className="ms-2"
                            style={{
                                color: "#555555",
                                fontSize: "16px",
                                fontFamily: "DM Sans",
                                fontWeight: "bold",
                            }}
                        >
                            Logout
                        </span>
                    </div>
                </li>
            </ul>
            <div className=""></div>
            <div
                className="d-flex flex-row align-items-center justify-content-between px-4"
                style={{ marginBottom: "10%" }}
            >
                <HelpIcon />
                <span
                    style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: "#555555",
                        fontFamily: "Dm Sans",
                    }}
                >
                    {t("help_and_get_started")}
                </span>
            </div>
            <div
                className="d-block d-lg-none"
                style={{ position: "absolute", top: "20px", right: "20px" }}
            >
                <IoMdClose
                    size={28}
                    color="#000000"
                    onClick={() => setIsSidebarCollapsed((prev) => !prev)}
                />
            </div>
            <ConfirmModal
                showConfirmModal={showConfirmModal}
                setShowConfirmModal={setShowConfirmModal}
            />
        </div>
    );
};

export default SideBar;

const ConfirmModal = ({ showConfirmModal, setShowConfirmModal }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const logout = async () => {
        try {
            await ApiPost("user/signout");
            dispatch(authActions.logout());
            navigate("/business");
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Modal
            show={showConfirmModal}
            onHide={setShowConfirmModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="confirmation-modal"
        >
            <Modal.Body className="confirmation-modal-body">
                <div className="confirmation-modal-header">
                    <h5>{t("confirm_logout.confirm")}</h5>
                    <p>{t("confirm_logout.are_you_sure")}</p>
                </div>

                <div className="confirmation-modal-btn-container">
                    <button
                        className="cancel-btn"
                        onClick={() => setShowConfirmModal(false)}
                    >
                        {t("confirm_logout.cancel")}
                    </button>
                    <button className="confirm-btn" onClick={logout}>
                        {t("logout")}
                    </button>
                </div>

                <div
                    className="position-absolute d-flex justify-content-center align-items-center cursor-pointer"
                    style={{ top: 10, right: 10 }}
                    onClick={() => setShowConfirmModal(false)}
                >
                    <IoClose size={25} color="#4A4A4A" />
                </div>
            </Modal.Body>
        </Modal>
    );
};
