import React from 'react'
import { Outlet } from 'react-router-dom'
import './Tickets.css'


const Tickets = () => {


    return (
        <div className='h-100 overflow-y-auto tickets-dashboard-container'>
            <Outlet />
        </div>
    )
}

export default Tickets