import React, { useContext, useEffect, useState } from 'react';
import '../../styling/styles/business/card-readers.css'; // ← Styling of this component
import axios from 'axios';
// ↓ Contexts
import { LoggedInContext } from '../businessStates&Auth/businessAuthState';
import { SelectedPlanContext } from '../businessStates&Auth/SelectedPlanState';
// ↓ Images
import StripeMark from '../../styling/images/Badges/Stripe.png';
import SumUpMark from '../../styling/images/Badges/SumUp.svg';
import SquareMark from '../../styling/images/Badges/Square.png';
// ↓ Functions
import { useNavigate } from 'react-router-dom';
// ↓ External components
import BarLoader from "react-spinners/BarLoader";
// ↓ JSX components
import BusinessNavbar from '../BusinessNavbar';
import { NewNightspotContext } from '../businessStates&Auth/newNighstpotState';

export default function CardReaders(props) {
    const { loggedIn, selectedCardReader, setSelectedCardReader, ownedNightspots, accountId, setOwnedNightspots } = useContext(LoggedInContext);
    const navigate = useNavigate();
    const [succesfulIntegration, setSuccesfulIntegration] = useState(false);
    const [actualCardReaderBrand, setActualCardReaderBrand] = useState('');

    const [dbPlan, setDbPlan] = useState('');

    useEffect(() => {
        axios.get(`https://polar-hamlet-90480-1e735d673784.herokuapp.com/getAccountInfo/${accountId}/frizdaPlan`).then(res => setDbPlan(res.data));
        !ownedNightspots && axios.get(`https://polar-hamlet-90480-1e735d673784.herokuapp.com/getAccountInfo/${accountId}/owned_nightspots`)
            .then(response => {
                setOwnedNightspots(response.data);
            });
    }, [accountId]);

    const [selectedReaderBrand, setSelectedReaderBrand] = useState('');
    // const [selectedReaderBrandJsx, setSelectedReaderBrandJsx] = useState(null);

    // frontend variables
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        !loggedIn && navigate('/business');
        isLoading && setTimeout(() => { setLoading(false) }, 5000);
    }, [loggedIn, dbPlan, isLoading]);

    async function whichIntegration() {
        try {
            setLoading(true);
            if (selectedReaderBrand === 'stripe') {
                const nightspotIds = typeof ownedNightspots === 'string' ? JSON.parse(ownedNightspots) : ownedNightspots;

                nightspotIds.forEach(nightclubId => {
                    window.location.href = `https://polar-hamlet-90480-1e735d673784.herokuapp.com/proxy-connect-stripe/${nightclubId}`;
                });

                const urlParams = new URLSearchParams(window.location.search);
                const stripeDetails = JSON.parse(urlParams.get('stripeDetails'));

                for (const nightclubId in ownedNightspots) {
                    const response = await axios.post('https://polar-hamlet-90480-1e735d673784.herokuapp.com/add-card-reader', {
                        nightclubId: nightclubId,
                        brand: selectedReaderBrand,
                        stripeDetails: stripeDetails
                    });

                    if (response.status !== 200) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                }
            } else if (selectedReaderBrand === 'sumup') {
                const nightspotIds = typeof ownedNightspots === 'string' ? JSON.parse(ownedNightspots) : ownedNightspots;

                nightspotIds.forEach(nightclubId => {
                    window.location.href = `https://polar-hamlet-90480-1e735d673784.herokuapp.com/proxy-connect-sumup/${nightclubId}`;

                    window.addEventListener('load', async () => {
                        const urlParams = new URLSearchParams(window.location.search);
                        const sumupDetails = JSON.parse(urlParams.get('sumupDetails'));

                        await axios.post('https://polar-hamlet-90480-1e735d673784.herokuapp.com/add-card-reader', {
                            nightclubId: nightclubId,
                            brand: selectedReaderBrand,
                            sumupDetails: sumupDetails
                        }).catch(err => console.error(err));
                    });
                });
            } else if (selectedReaderBrand === 'square') {
                // Parse nightspotIds into an array if it's a string
                const nightspotIds = typeof ownedNightspots === 'string' ? JSON.parse(ownedNightspots) : ownedNightspots;

                nightspotIds.forEach(nightclubId => {
                    window.location.href = `https://polar-hamlet-90480-1e735d673784.herokuapp.com/proxy-connect-square/${nightclubId}`;

                    window.addEventListener('load', async () => {
                        const urlParams = new URLSearchParams(window.location.search);
                        const sumupDetails = JSON.parse(urlParams.get('sumupDetails'));

                        const response = await axios.post('https://polar-hamlet-90480-1e735d673784.herokuapp.com/add-card-reader', {
                            nightclubId: nightclubId,
                            brand: selectedReaderBrand,
                            sumupDetails: sumupDetails
                        });

                        if (response.status !== 200) {
                            throw new Error(`HTTP error! status: ${response.status}`);
                        }
                    });
                });
            }
        } catch (err) {
            console.log(err)
        } finally {
        }
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const success = urlParams.get('successfulIntegration') === 'true';
        let nightspotIds;

        if (ownedNightspots > 0) {
            nightspotIds = typeof ownedNightspots === 'string' ? JSON.parse(ownedNightspots) : ownedNightspots;
        }

        ownedNightspots > 0 && nightspotIds.forEach(nightclubId => {
            axios.get(`https://polar-hamlet-90480-1e735d673784.herokuapp.com/getNightspotField/${nightclubId}/cardReaders`)
                .then(response => {
                    setSuccesfulIntegration(success && response.data.length > 0);
                    setActualCardReaderBrand(response.data[0][0].brand);
                }).catch(err => console.error(err));
        });

    }, []);

    return (
        <div className='m-0' data-bs-theme='dark'>
            <div></div>
            <BusinessNavbar />

            <div className='m-5' id='card-readers'>
                {!succesfulIntegration ?
                    <div className={`select-card-reader-container row`}>
                        <div className='row'>
                            <div className='col-12 border-bottom border-light d-flex py-2'>
                                <p className='header fw-bold mx-auto my-auto'>Select the brand of your card readers</p>
                            </div>
                            <div className='row px-2 gap-2' id='card-readers-section'>
                                <div
                                    className={`col-3 my-5 py-3 d-flex justify-content-center align-items-center ${selectedReaderBrand === 'stripe' ? 'selected-brand' : 'not-selected-brand'}`}
                                    onClick={() => setSelectedReaderBrand('stripe')}
                                >
                                    <img src={StripeMark} />
                                </div>

                                <div
                                    className={`col-3 my-5 py-3 d-flex justify-content-center align-items-center ${selectedReaderBrand === 'sumup' ? 'selected-brand' : 'not-selected-brand'}`}
                                    onClick={() => setSelectedReaderBrand('sumup')}
                                >
                                    <img src={SumUpMark} style={{ width: '300px' }} />
                                </div>

                                <div
                                    className={`col-3 my-5 py-3 d-flex justify-content-center align-items-center ${selectedReaderBrand === 'square' ? 'selected-brand' : 'not-selected-brand'}`}
                                    onClick={() => setSelectedReaderBrand('square')}
                                >
                                    <img src={SquareMark} style={{ width: '300px' }} />
                                </div>
                            </div>
                            <div className='d-flex justify-content-center align-items-center'>
                                {isLoading &&
                                    <div className='me-3'>
                                        <BarLoader color="#B637FB" speedMultiplier={3} width={50} />
                                    </div>
                                }
                                <button onClick={whichIntegration} className={`btn btn-lg button-plan-next w-25 my-3 ${false && 'shake'}`}>Next</button>
                            </div>
                        </div>
                    </div>

                    :

                    <div className='card text-center text-bg-dark w-50 mx-auto'>
                        <h5 className='card-header'>Success!</h5>
                        <img className='card-img p-4' src={
                            actualCardReaderBrand === 'Stripe' ? StripeMark :
                                actualCardReaderBrand === 'SumUp' ? SumUpMark :
                                    actualCardReaderBrand === 'Square' ? SquareMark :
                                        null
                        } />
                        <div className='card-body border-top'>
                            <h5 className='card-title'>Your <span className='fw-bold'>{actualCardReaderBrand}</span> card readers have been linked succesfully with Frizda</h5>
                            <p className='text-body-secondary'>You're almost there! A few more steps to do before reaching the end!</p>
                            <a href='/business/add' className='btn btn-frizda'>Next step</a>
                        </div>
                    </div>

                }
            </div>
        </div>
    );
}