import React, { useState, useEffect } from 'react';
import { FilterTimePicker } from '../../../components/home-page/OutstandingClubs-components/FilterOptions';

export default function OpeningHours({ selectedHours, setSelectedHours, setDaysAreComplete, daysAreComplete }) {

    const [selectedDayHours, setSelectedDayHours] = useState('');

    function handleTimeChange(day, purpose, value) {
        const [hour, minutes] = value.split(':').map(Number);
        setSelectedHours(prevHours => ({
            ...prevHours,
            [day]: {
                ...prevHours[day],
                [purpose]: { hour: hour ? hour : 0, minutes: minutes ? minutes : 0 },
            }
        }));
    }

    function handleClosedChange(day, value) {
        setSelectedHours(prevData => ({
            ...prevData,
            [day]: {
                ...prevData[day],
                closed: value
            },
        }));
    }

    // ↓ Set times to null of days that are closed
    useEffect(() => {
        if (selectedHours[selectedDayHours]?.closed) {
            setSelectedHours(prevHours => ({
                ...prevHours,
                [selectedDayHours]: {
                    ...prevHours[selectedDayHours],
                    opening: { hour: null, minutes: null },
                    closing: { hour: null, minutes: null }
                }
            }));
        }
    }, [selectedDayHours, selectedHours]);

    function getSelectedValue(purpose) {
        return selectedDayHours ? selectedHours[selectedDayHours][purpose] : null;
    }

    function timeToString(time) {
        if (!time) return '';
        const { hour, minutes } = time;
        return `${hour ? hour.toString().padStart(2, '0') : '00'}:${minutes ? minutes.toString().padStart(2, '0') : '00'}`;
    }

    // ↓ Proves if all the 7 days of the week have opening and closing times, set boolean value to daysAreComplete variable
    useEffect(() => {
        const allDaysComplete = Object.values(selectedHours).every(day => {
            return day && (day.closed || (day.opening != null && day.closing != null));
        });

        const atLeastOneDayOpen = Object.values(selectedHours).some(day => {
            return day && !day.closed;
        });

        setDaysAreComplete(allDaysComplete && atLeastOneDayOpen);
    }, [selectedHours]);

    // brocoli
    useEffect(() => {
        console.log('brocoli')
    }, []);

    return (
        <div className='p-4 position-relative W-100'>
            <h5 className='d-flex justify-content-center'><i className='bi bi-clock me-3' />Opening hours</h5>
            <p className='explanation-text'>Click on a day and select the opening and closing times for each day. If your business operates multiple times a day, include only the nighttime opening times. <span className='bold-text'>At least one night cannot be closed.</span></p>

            <div>
                <div className='mb-2 btn-group d-flex justify-content-center' role='group'>

                    <input
                        className='btn-check'
                        name='btn-radio'
                        autoComplete='off'
                        type='radio'
                        id='fridayHours'
                        onChange={() => setSelectedDayHours('friday')}
                    />

                    <label className='btn btn-outline-frizda btn-sm' htmlFor='fridayHours'>Friday</label>

                    <input type='radio' id='saturdayHours' className='btn-check' name='btn-radio' autoComplete='off' onChange={() => setSelectedDayHours('saturday')} />
                    <label className='btn btn-outline-frizda btn-sm' htmlFor='saturdayHours'>Saturday</label>
                </div>

                {/* <div className='form-check form-check-reverse my-3'>
                    <input
                        className='form-check-input'
                        type='checkbox'
                        id='closedWeekendnightsInput'
                        disabled={selectedDayHours != 'friday' && selectedDayHours != 'saturday'}
                        onChange={e => handleClosedChange(selectedDayHours, e.target.checked)}
                        checked={selectedDayHours == 'friday' || selectedDayHours == 'saturday' ? getSelectedValue('closed') : ''}
                    />

                    <label className='form-check-label' htmlFor='closedWeekendnightsInput'>Closed during nighttime</label>
                </div> */}

                <div className='d-flex justify-content-center'>
                    <FilterTimePicker
                        timePickerLabel='Opening at'
                        disabled={selectedDayHours != 'saturday' && selectedDayHours != 'friday' || getSelectedValue('closed')}
                        onChange={event => handleTimeChange(selectedDayHours, 'opening', event.target.value)}
                        id='openingHoursWeekend'
                        value={(selectedDayHours == 'friday' || selectedDayHours == 'saturday') && !selectedHours[selectedDayHours]?.closed ? timeToString(getSelectedValue('opening')) : ''}
                    />

                    <h5 className='mx-4 my-auto'>-</h5>
                    <FilterTimePicker
                        timePickerLabel='Closing at'
                        disabled={selectedDayHours != 'friday' && selectedDayHours != 'saturday' || getSelectedValue('closed')}
                        onChange={event => handleTimeChange(selectedDayHours, 'closing', event.target.value)}
                        id='closingHoursWeekend'
                        value={(selectedDayHours == 'friday' || selectedDayHours == 'saturday') && !selectedHours[selectedDayHours]?.closed ? timeToString(getSelectedValue('closing')) : ''}
                    />
                </div>
            </div>

            <div className='my-4'>
                <div className='mt-4 btn-group' role='group'>
                    <input type='radio' id='sundayHours' className='btn-check' name='btn-radio' autoComplete='off' onChange={() => setSelectedDayHours('sunday')} />
                    <label className='btn btn-outline-light btn-sm' htmlFor='sundayHours'>Sunday</label>

                    <input type='radio' id='mondayHours' className='btn-check' name='btn-radio' autoComplete='off' onChange={() => setSelectedDayHours('monday')} />
                    <label className='btn btn-outline-light btn-sm' htmlFor='mondayHours'>Monday</label>

                    <input type='radio' id='tuesdayHours' className='btn-check' name='btn-radio' autoComplete='off' onChange={() => setSelectedDayHours('tuesday')} />
                    <label className='btn btn-outline-light btn-sm' htmlFor='tuesdayHours'>Tuesday</label>

                    <input type='radio' id='wednesdayHours' className='btn-check' name='btn-radio' autoComplete='off' onChange={() => setSelectedDayHours('wednesday')} />
                    <label className='btn btn-outline-light btn-sm' htmlFor='wednesdayHours'>Wednesday</label>

                    <input type='radio' id='thursdayHours' className='btn-check' name='btn-radio' autoComplete='off' onChange={() => setSelectedDayHours('thursday')} />
                    <label className='btn btn-outline-light btn-sm' htmlFor='thursdayHours'>Thursday</label>
                </div>

                {/* <div className='form-check form-check-reverse my-3'>
                    <input className='form-check-input' type='checkbox' id='dayClosedWeeknightsInput' disabled={selectedDayHours == 'friday' || selectedDayHours == 'saturday' || selectedDayHours == ''}
                        onChange={event => handleClosedChange(selectedDayHours, event.target.checked)}
                        checked={selectedDayHours != 'friday' && selectedDayHours != 'saturday' ? getSelectedValue('closed') : ''}
                    />
                    <label className='form-check-label' htmlFor='dayClosedWeeknightsInput'>Closed during nighttime</label>
                </div> */}

                <div className='d-flex justify-content-center mt-2'>
                    <FilterTimePicker
                        timePickerLabel='Opening at'
                        disabled={selectedDayHours == 'friday' || selectedDayHours == 'saturday' || selectedDayHours == '' || getSelectedValue('closed')}
                        onChange={event => handleTimeChange(selectedDayHours, 'opening', event.target.value)}
                        id='openingHoursWeekday'
                        value={(selectedDayHours != 'friday' && selectedDayHours != 'saturday') && !selectedHours[selectedDayHours]?.closed ? timeToString(getSelectedValue('opening')) : ''}
                    />
                    <h5 className='mx-4 my-auto'>-</h5>
                    <FilterTimePicker
                        timePickerLabel='Closing at'
                        disabled={selectedDayHours == 'friday' || selectedDayHours == 'saturday' || selectedDayHours == '' || getSelectedValue('closed')}
                        onChange={event => handleTimeChange(selectedDayHours, 'closing', event.target.value)}
                        id='closingHoursWeekday'
                        value={(selectedDayHours != 'friday' && selectedDayHours != 'saturday') && !selectedHours[selectedDayHours]?.closed ? timeToString(getSelectedValue('closing')) : ''}
                    />
                </div>
            </div>

            {daysAreComplete && <div className='mt-2 set-text-to-green'>All days have times set</div>}
        </div>
    )
}