import React from 'react'
import { pageNotAvailable } from '../../../assets/images';

const PageNotFound = ({ title = '', tagLine = '' }) => {
    return (
        <div className='w-100 h-100 d-flex flex-column justify-content-around align-items-center'>
            <p className='page-not-found-text' style={{ color: "#7A38FE" }}>{title}</p>
            <div className='page-not-found-img-container'>
                <img src={pageNotAvailable} alt="Page Not Found" className='w-100 h-100 object-fit-contain' />
            </div>
            <p className='page-not-found-text' style={{ color: "#444444" }}>{tagLine}</p>
        </div>
    )
}

export default PageNotFound