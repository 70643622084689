import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const MyChart = () => {
  // Get the current month
  const currentMonth = new Date().getMonth();

  // Create an array of month initials
  const monthInitials = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'];

  // Get the initials of the current and the past 4 months
  const labels = Array.from({ length: 5 }, (_, i) => monthInitials[(currentMonth - i + 12) % 12]).reverse();

  // Your y-axis values
  const yValues = [24030, 16847, 31245, 11766, 9874, 2500];

  const data = {
    labels: labels, // x-axis values (displayed as month initials)
    datasets: [
      {
        label: 'My Dataset',
        data: yValues,
        borderColor: 'rgba(122, 56, 254, 1)',
        borderWidth: 2,
        fill: false,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    indexAxis: 'x',
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: 'white',
      },
    },
    elements: {
      line: {
        tension: 0.1,
      },
    },
    scales: {
      x: {
        display: true,
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            // Display the y-axis labels in 'k' format for values >= 1000, and in normal format for values < 1000
            if (value >= 1000) {
              return `${value / 1000}k`;
            } else {
              return value;
            }
          },
          stepSize: 200,
          font: {
            family: 'DM Sans',
            size: 9,
            weight: 'bold',
          },
          padding: 0,
        },
      },
    },
  };

  return (
    <div style={{ maxWidth: '290px', marginLeft: '5px', padding: '0' }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default MyChart;
