import React from "react";

import "./service-pro-hero-res.css";

import ModalComponent from "./components/Modal";

import lionclubimage from "../styling/imgs/lions-club-image.png";
import dot from "../styling/imgs/Ellipse 2271.svg";
import pen from "../styling/imgs/pen.png";
import location from "../styling/imgs/location.png";
import whiteUparrow from "../styling/imgs/black-up-arrow (2).svg";
import lock from "../styling/imgs/padlock.png";
import uptrend from "../styling/imgs/trendup.png";
import downtrend from "../styling/imgs/trenddown.png";
import { useNavigate } from "react-router-dom";
import pin from "../styling/imgs/pin.png";

function ServiceProHeroResponsive({ name, nightspotDetails }) {
    const navigate = useNavigate();

    return (
        <>
            {/*Show Button Modal to uncomment the ModalComponent below on line 20 */}
            {/* <ModalComponent /> */}
            {/*Hide Button Modal to uncomment the ModalComponent below on line 20 */}

            <div className="service-responsive-main-div">
                <div className="service-hero-content">
                    <h1 className="service-hero-heading">
                        Welcome{" "}
                        <span className="service-hero-heading-span">
                            {" "}
                            Admin
                        </span>
                    </h1>
                    <p className="service-hero-para">
                        Unlock the full potential of your business with Frizda
                        Pro.{" "}
                    </p>
                    <button
                        className="service-hero-navigation-btn"
                        onClick={() => navigate("/business/dashboard")}
                    >
                        <div className="service-hero-navigation-btn-div">
                            <h1 className="service-monetary-stat-btn-res-para">
                                Dashboard
                            </h1>
                            <img
                                src={whiteUparrow}
                                width={15}
                                height={19}
                                alt="lock"
                            />
                        </div>
                    </button>

                    {/* nightspot details */}
                    {nightspotDetails?.name ? (
                        <div className="service-lion-club-div">
                            <div className="service-lion-club-res-header">
                                <h1 className="service-lion-club-header-res-heading">
                                    {nightspotDetails?.name}
                                </h1>
                                <img
                                    src={pen}
                                    alt="pen"
                                    width={24}
                                    height={24}
                                    onClick={() =>
                                        navigate(
                                            "/business/dashboard/nightspot-details"
                                        )
                                    }
                                />
                            </div>
                            <div className="lion-club-pro-btm-res-div">
                                <div className="lion-club-btm-content-div">
                                    <div className="lion-club-btm-content-address-res-div">
                                        <img
                                            src={location}
                                            alt="pen"
                                            width={8}
                                            height={8}
                                        />
                                        <p className="lion-club-btm-content-res-para">
                                            {nightspotDetails?.address}
                                        </p>
                                    </div>
                                    <p className="lion-club-btm-content-res-schedule">
                                        Schedule <br />
                                        {nightspotDetails?.openingHours ? (
                                            Object.keys(
                                                nightspotDetails?.openingHours
                                            ).map((day, index) => (
                                                <span
                                                    key={index}
                                                    className="lion-club-content-schedule-plan"
                                                >
                                                    {day
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                        day.slice(1)}
                                                    ,{" "}
                                                    {
                                                        nightspotDetails
                                                            ?.openingHours[day]
                                                            .openingTime
                                                    }{" "}
                                                    -{" "}
                                                    {
                                                        nightspotDetails
                                                            ?.openingHours[day]
                                                            .closingTime
                                                    }{" "}
                                                    <br />
                                                </span>
                                            ))
                                        ) : (
                                            <span className="lion-club-content-schedule-plan"></span>
                                        )}
                                    </p>
                                    <p className="lion-club-btm-content-pay-res-via">
                                        Pay via
                                    </p>
                                    <div className="lion-club-pay-via-main-div">
                                        {nightspotDetails?.paymentMode?.map(
                                            (item, index) => {
                                                return (
                                                    <>
                                                        <div
                                                            className="lion-club-pay-via-main-div-1"
                                                            key={index}
                                                        >
                                                            <div className="lion-club-pay-via-child-div">
                                                                <img
                                                                    src={dot}
                                                                    alt="dot"
                                                                    width={5}
                                                                    height={5}
                                                                />
                                                                <p className="lion-club-pay-via-child-div-para">
                                                                    {item}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </>
                                                );
                                            }
                                        )}
                                    </div>
                                </div>
                                <img
                                    src={
                                        nightspotDetails?.image || lionclubimage
                                    }
                                    className="lion-club-img-res"
                                    alt={nightspotDetails?.name || "img"}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="service-lion-club-div display-none-1374min">
                            <div className="service-lion-club-header">
                                <h1 className="service-lion-club-header-heading">
                                    Nightspot Title
                                </h1>
                                <img
                                    src={pin}
                                    alt="pen"
                                    width={30}
                                    height={30}
                                />
                            </div>
                            <div className="lion-club-btm-div">
                                <h1 className="lion-club-btm-div-heading">
                                    Your Nightspot Details
                                </h1>
                                <button className="service-client-data-details-btn">
                                    <div className="service-client-data-btn-div">
                                        <h1
                                            className="service-client-data-details-btn-para"
                                            onClick={() =>
                                                navigate(
                                                    "/business/dashboard/nightspot-details"
                                                )
                                            }
                                        >
                                            Add Details
                                        </h1>
                                        <img
                                            src={whiteUparrow}
                                            width={15}
                                            height={19}
                                            alt="lock"
                                        />
                                    </div>
                                </button>
                            </div>
                        </div>
                    )}

                    <div className="monetary-and-client-flex-res-div">
                        {/* monetary-stats */}
                        {true ? (
                            <div
                                className="service-monetary-stats-res-div"
                                style={{ justifyContent: "flex-start" }}
                                onClick={() =>
                                    navigate("/business/dashboard/monetics")
                                }
                            >
                                <h1 className="service-pro-monetary-stat-res-heading">
                                    Monetary Statistics
                                </h1>
                                <div className="service-monetary-pro-last-transection-div">
                                    <h3 className="service-monetary-pro-last-transection-heading">
                                        Last transactions
                                    </h3>
                                    <div className="service-monetary-pro-last-transection-details-div-main">
                                        <div className="service-monetary-pro-last-transection-border"></div>

                                        <div className="service-monetary-pro-last-transection-details-div">
                                            <p className="service-monetary-pro-last-transection-date">
                                                29/05
                                            </p>
                                            <p className="service-monetary-pro-last-transection-amount">
                                                {" "}
                                                + €23
                                            </p>
                                        </div>
                                        <div className="service-monetary-pro-last-transection-border"></div>

                                        <div className="service-monetary-pro-last-transection-details-div">
                                            <p className="service-monetary-pro-last-transection-date">
                                                22/05
                                            </p>
                                            <p className="service-monetary-pro-last-transection-amount">
                                                {" "}
                                                + €14
                                            </p>
                                        </div>
                                        <div className="service-monetary-pro-last-transection-border"></div>

                                        <div className="service-monetary-pro-last-transection-details-div">
                                            <p className="service-monetary-pro-last-transection-date">
                                                16/05
                                            </p>
                                            <p className="service-monetary-pro-last-transection-amount">
                                                {" "}
                                                + €62
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="service-monetary-stats-res-div">
                                <h1 className="service-monetary-stat-res-heading">
                                    Monetary Statistics
                                </h1>

                                <button className="service-monetary-stat-btn">
                                    <div className="service-monetary-stat-btn-div">
                                        <h1 className="service-monetary-stat-btn-res-para">
                                            Unlock with Pro
                                        </h1>
                                        <img
                                            src={lock}
                                            width={9}
                                            height={13}
                                            alt="lock"
                                        />
                                    </div>
                                </button>
                            </div>
                        )}

                        {/*client data  */}
                        <div
                            className="service-monetary-stats-res-div"
                            onClick={() =>
                                navigate("/business/dashboard/client-data")
                            }
                        >
                            <h1 className="client-res-heading">Client Data</h1>
                            <div className="service-client-data-total-client-main-res-div">
                                <div className="service-client-data-total-res-client">
                                    <p className="service-client-data-total-client-para">
                                        Total Clients - Last month
                                    </p>
                                    <div className="service-client-data-total-client-stat">
                                        <p className="service-client-data-total-client-stat-nmber">
                                            200
                                        </p>
                                        <div className="service-client-data-total-client-trend">
                                            <img
                                                src={uptrend}
                                                alt="svg"
                                                width={12}
                                                height={12}
                                            />
                                            <p className="service-client-data-total-client-trend-stat">
                                                24%
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="service-client-data-total-res-client">
                                    <p className="service-client-data-total-client-para">
                                        Daily count
                                    </p>
                                    <div className="service-client-data-total-client-stat">
                                        <p className="service-client-data-total-client-stat-nmber">
                                            956
                                        </p>
                                        <div className="service-client-data-total-client-trend">
                                            <img
                                                src={downtrend}
                                                alt="svg"
                                                width={12}
                                                height={12}
                                            />
                                            <p className="service-client-data-total-client-trend-stat-red">
                                                12.40%
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="service-client-data-total-res-client">
                                    <p className="service-client-data-total-client-para">
                                        Daily count
                                    </p>
                                    <div className="service-client-data-total-client-stat">
                                        <p className="service-client-data-total-client-stat-nmber">
                                            956
                                        </p>
                                        <div className="service-client-data-total-client-trend">
                                            <img
                                                src={downtrend}
                                                alt="svg"
                                                width={12}
                                                height={12}
                                            />
                                            <p className="service-client-data-total-client-trend-stat-red">
                                                12.40%
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="service-client-data-total-res-client">
                                    <p className="service-client-data-total-client-para">
                                        Daily count
                                    </p>
                                    <div className="service-client-data-total-client-stat">
                                        <p className="service-client-data-total-client-stat-nmber">
                                            956
                                        </p>
                                        <div className="service-client-data-total-client-trend">
                                            <img
                                                src={downtrend}
                                                alt="svg"
                                                width={12}
                                                height={12}
                                            />
                                            <p className="service-client-data-total-client-trend-stat-red">
                                                12.40%
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/*  */}
                            </div>
                        </div>
                    </div>

                    <div className="monetary-and-client-flex-res-div">
                        {/* affiliate marketing */}
                        {true ? (
                            <div
                                className="affiliate-pro-res-div"
                                onClick={() =>
                                    navigate(
                                        "/business/dashboard/affiliate-marketing"
                                    )
                                }
                            >
                                <h1 className="affiliate-pro-res-heading">
                                    Affiliate Marketing
                                </h1>
                                <div className="service-pro-affiliate-data-scroll-div">
                                    <div className="service-pro-affiliate-data-total-client">
                                        <p className="service-client-data-total-client-para">
                                            Total clicks on your links
                                        </p>
                                        <div className="service-client-data-total-client-stat">
                                            <p className="service-client-data-total-client-stat-nmber">
                                                121,000
                                            </p>
                                            <div className="service-client-data-total-client-trend">
                                                <img
                                                    src={uptrend}
                                                    alt="svg"
                                                    width={12}
                                                    height={12}
                                                />
                                                <p className="service-client-data-total-client-trend-stat">
                                                    24%
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="service-pro-affiliate-data-total-client">
                                        <p className="service-client-data-total-client-para">
                                            Tickets sold
                                        </p>
                                        <div className="service-client-data-total-client-stat">
                                            <p className="service-client-data-total-client-stat-nmber">
                                                €320
                                            </p>
                                            <div className="service-client-data-total-client-trend">
                                                <img
                                                    src={uptrend}
                                                    alt="svg"
                                                    width={12}
                                                    height={12}
                                                />
                                                <p className="service-client-data-total-client-trend-stat">
                                                    13%
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="service-pro-affiliate-data-total-client">
                                        <p className="service-client-data-total-client-para">
                                            Number of affiliates
                                        </p>
                                        <div className="service-client-data-total-client-stat">
                                            <p className="service-client-data-total-client-stat-nmber">
                                                24
                                            </p>
                                        </div>
                                    </div>
                                    <div className="service-pro-affiliate-data-total-client">
                                        <p className="service-client-data-total-client-para">
                                            Number of affiliates
                                        </p>
                                        <div className="service-client-data-total-client-stat">
                                            <p className="service-client-data-total-client-stat-nmber">
                                                24
                                            </p>
                                        </div>
                                    </div>
                                    {/*  */}
                                </div>
                            </div>
                        ) : (
                            <div className="service-monetary-stats-res-div">
                                <h1 className="affiliate-res-heading">
                                    Affiliate Marketing
                                </h1>
                                <p className="service-monetary-stat-res-para">
                                    Affiliate Leads with Frizda Pro
                                </p>
                                <button className="service-monetary-stat-btn">
                                    <div className="service-monetary-stat-btn-div">
                                        <h1 className="service-monetary-stat-btn-res-para">
                                            Unlock with Pro
                                        </h1>
                                        <img
                                            src={lock}
                                            width={9}
                                            height={13}
                                            alt="lock"
                                        />
                                    </div>
                                </button>
                            </div>
                        )}

                        {/* Frizda ads */}
                        {true ? (
                            <div
                                className="affiliate-pro-res-div"
                                onClick={() =>
                                    navigate("/business/dashboard/frizda-ads")
                                }
                            >
                                <h1
                                    className="affiliate-pro-res-heading"
                                    style={{
                                        paddingBottom: "15px",
                                        paddingTop: "15px",
                                    }}
                                >
                                    Frizda Ads
                                </h1>
                                <div className="service-pro-affiliate-data-scroll-div">
                                    <div className="service-frizda-ads-pro-ads-collection">
                                        <div className="service-frizda-ads-pro-ad">
                                            <p className="service-frizda-ads-pro-ad-para">
                                                Reach
                                            </p>
                                            <p className="service-frizda-ads-pro-ad-stat">
                                                + 76%
                                            </p>
                                        </div>
                                        <div className="service-frizda-ads-pro-ad-2">
                                            <p className="service-frizda-ads-pro-ad-stat">
                                                €4,500
                                            </p>
                                            <p className="service-frizda-ads-pro-ad-para">
                                                invested
                                            </p>
                                        </div>
                                    </div>

                                    <div className="service-monetary-pro-ads-div-scroll">
                                        <h3 className="service-monetary-pro-last-transection-heading">
                                            May, 2024
                                        </h3>
                                        <div className="service-monetary-pro-last-transection-border"></div>

                                        <div className="service-monetary-pro-last-transection-details-div">
                                            <p className="service-monetary-pro-last-transection-date">
                                                29/05
                                            </p>
                                            <p className="service-monetary-pro-last-transection-amount-ads">
                                                {" "}
                                                - €23
                                            </p>
                                        </div>
                                        <div className="service-monetary-pro-last-transection-border"></div>

                                        <div className="service-monetary-pro-last-transection-details-div">
                                            <p className="service-monetary-pro-last-transection-date">
                                                22/05
                                            </p>
                                            <p className="service-monetary-pro-last-transection-amount-ads">
                                                {" "}
                                                - €14
                                            </p>
                                        </div>
                                        <div className="service-monetary-pro-last-transection-border"></div>
                                        <div className="service-monetary-pro-last-transection-details-div">
                                            <p className="service-monetary-pro-last-transection-date">
                                                16/05
                                            </p>
                                            <p className="service-monetary-pro-last-transection-amount-ads">
                                                {" "}
                                                - €62
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="service-monetary-stats-res-div">
                                <h1 className="Frizda-ads-res-heading">
                                    Frizda Ads
                                </h1>
                                <p className="service-monetary-stat-res-para">
                                    Advertise & Grow with Frizda Pro
                                </p>
                                <button className="service-monetary-stat-btn">
                                    <div className="service-monetary-stat-btn-div">
                                        <h1 className="service-monetary-stat-btn-res-para">
                                            Unlock with Pro
                                        </h1>
                                        <img
                                            src={lock}
                                            width={9}
                                            height={13}
                                            alt="lock"
                                        />
                                    </div>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ServiceProHeroResponsive;
