import React, { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import {
    aboutMathiasPFP,
    frizdaLogo,
    mathias_nagant,
} from "../../assets/images";
import "./styles/about.css";
import { Trans, useTranslation } from "react-i18next";

const About = () => {
    const { t } = useTranslation();

    const TEAM_MEMBER = [
        {
            id: 1,
            name: "John Petrik",
            role: "COO",
            image: mathias_nagant,
        },
        {
            id: 2,
            name: "Selena Smith",
            role: "CEO",
            image: mathias_nagant,
        },
        {
            id: 3,
            name: "Wright Barker",
            role: "CTO",
            image: mathias_nagant,
        },
        {
            id: 4,
            name: "Taylor Grande",
            role: "CMO",
            image: mathias_nagant,
        },
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="about-page">
            {/* <LandingNavbar /> */}
            <div className="owner-section about-alex-banner">
                <Row>
                    <Col xs={12} md={6} className="about-img-container-col">
                        <div className="about-img-container">
                            <img src={aboutMathiasPFP} alt="Mathias Nagant" />
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <div className="about-section-header">
                            <p>{t("about_page.making_of")}</p>
                            <div className="about-frizda-logo">
                                <img src={frizdaLogo} alt="Frizda" />
                                <h2>FRIZDA</h2>
                            </div>
                        </div>
                        <Card className="about-card">
                            <p>
                                <Trans i18nKey="about_page.alex_frizda_description">
                                    One day, I was walking in my town's
                                    center,&nbsp;
                                    <span>
                                        struggling to find a good nightlife spot
                                    </span>
                                    &nbsp; that suited my needs and where I
                                    could spend the rest of the night with my
                                    friends. At that moment, I realized there
                                    was a problem that needed to be solved. The
                                    reason we created FRIZDA is to help people
                                    find the{" "}
                                    <span>perfect nightlife spots</span> where
                                    they can enjoy themselves and make their
                                    dreams come true, while also giving more
                                    visibility to the night spot owners.
                                </Trans>
                            </p>
                            <p>
                                <span>- Alex</span>
                            </p>
                        </Card>
                    </Col>
                </Row>
                {/* <div className="about-alex-banner"></div> */}
            </div>

            <div className="owner-section about-mathias-banner">
                <Row>
                    <Col xs={12} md={6}>
                        <div className="about-section-header about-section-header-alt">
                            <div className="about-frizda-logo">
                                <img src={frizdaLogo} alt="Frizda" />
                                <h2>FRIZDA</h2>
                            </div>
                            <p>{t("about_page.explained")}</p>
                        </div>

                        <Card className="about-card">
                            <div className="">
                                <p>
                                    <Trans i18nKey="about_page.mathias_frizda_description1">
                                        FRIZDA is an all-in-one platform
                                        designed to bridge the gap between
                                        nightlife venues and their
                                        clients.&nbsp;
                                        <span>FRIZDA for businesses,</span>{" "}
                                        offers tools to publish on our
                                        marketplace, track client data, sell
                                        event tickets, streamline ID checks,
                                        manage income, and run an affiliation
                                        system, all while boosting visibility.
                                    </Trans>
                                </p>
                                <p className="mt-2">
                                    <Trans i18nKey="about_page.mathias_frizda_description2">
                                        On the other hand,&nbsp;
                                        <span>FRIZDA Classic</span> is an app
                                        where nightlife enthusiasts can find
                                        their perfect spot, filter venues
                                        globally, meet new people, and enjoy
                                        exclusive benefits.
                                    </Trans>
                                </p>
                            </div>
                            <p>
                                <span>- Mathias Nagant</span>
                            </p>
                        </Card>
                    </Col>
                    <Col xs={12} md={6} className="about-img-container-col">
                        <div className="about-img-container about-img-container-alt">
                            <img src={mathias_nagant} alt="Mathias Nagant" />
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="">
                <div className="about-section-header">
                    <p>
                        <Trans i18nKey="about_page.we_are_here_to_help">
                            We're here to help you and your
                            business&nbsp;
                            <span>thrive.</span>
                        </Trans>
                    </p>
                </div>
                <div className="team-member-grid">
                    {TEAM_MEMBER.map((member) => (
                        <div className="team-member-card">
                            <div
                                className="team-member-card-overlay"
                                style={{
                                    backgroundImage: `url(${member.image})`,
                                }}
                            >
                                <div className="team-member-card-details">
                                    <h5>{member.name}</h5>
                                    <p>{member.role}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default About;
