import React from 'react'
import Lottie from 'react-lottie';
import * as animationData from '../../../assets/lottie/spinner_animation.json';

const SpinLoader = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
            <Lottie
                options={defaultOptions}
                height={200}
                width={200}
            />
        </div>
    )
}

export default SpinLoader