import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiPostNoAuth } from "../../../utils/ApiData";
import "../auth-components/auth-styles/ResetPass.css";
import hide from "../auth-components/auth-styles/images/email2.png";
import show from "../auth-components/auth-styles/images/eye.png";
import logo from "../auth-components/auth-styles/images/frizdausual.png";
import LoginSpinner from "./auth-styles/spinners/ResetPassSpinner";

function ResetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [NewPassword, setNewPassword] = useState(false);
  const [confirmPassword, setconfirmPassword] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [passwordMistmatch, setPasswordMismatch] = useState(false);

  const [lacksLength, setLacksLength] = useState(false);
  const [lacksChar, setLacksChar] = useState(false);
  const [lacksUpper, setLacksUpper] = useState(false);
  const [lacksNumber, setLacksNumber] = useState(false);
  const [lacksLower, setLacksLower] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const verificationToken = queryParams.get('token');
  const email = queryParams.get('email');
  const toast = useRef(null);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleNewPasswordVisibility = () => {
    setNewPassword(!NewPassword);
  };

  const handleSignupForm = (e) => {
    e.preventDefault();
    resetPassword();
  };

  const resetPassword = async () => {
    setShowLoader(true);
    try {
      if (NewPassword !== confirmPassword) {
        setShowLoader(false);
        setPasswordMismatch(true);
        return;
      } else {
        setPasswordMismatch(false);
      }

      if (!validatePassword(NewPassword)) {
        setShowLoader(false);
        return;
      }

      const response = await ApiPostNoAuth('user/change-password', {
        email: email,
        password: NewPassword,
        token: verificationToken
      });

      if (response) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Password reset successfully',
          life: 2000
        });

        navigate('/business/login');
        setShowLoader(false);
      }

    } catch (error) {
      setShowLoader(false);
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: error.message || 'An error occurred',
        life: 2000
      });
    }
  }

  function validatePassword(password) {
    // Reset all state variables
    setLacksLength(false);
    setLacksChar(false);
    setLacksUpper(false);
    setLacksNumber(false);
    setLacksLower(false);

    // Check password requirements
    if (password.length < 8) setLacksLength(true);
    else if (!/[!@#$%^&*]/.test(password)) setLacksChar(true);
    else if (!/[A-Z]/.test(password)) setLacksUpper(true);
    else if (!/[0-9]/.test(password)) setLacksNumber(true);
    else if (!/[a-z]/.test(password)) setLacksLower(true);
    else return true;

    return false;
  };

  return (
    <div className="business-signup-parent">
      <div className="business-signup-child">
        <div className="business-resetPass-content">
          <div className="business-login-left-div">
            <img src={logo} alt="logo" width={88} className="login-logo" />
            <h1 className="business-login-heading">Reset Password</h1>
            <p className="reset-pass-para">
              Choose a new password for your account
            </p>
          </div>
          <div className="business-login-right-div">
            <form className="reset-form" onSubmit={handleSignupForm}>
              <div
                className="hide-div-resetNewPass"
                onClick={toggleNewPasswordVisibility}
              >
                <img
                  src={NewPassword ? show : hide}
                  width="25px"
                  height="25px"
                  alt="hide"
                />
              </div>
              <input
                className="resetPassinputNewpass"
                type={NewPassword ? "text" : "password"}
                placeholder="New Password"
                required
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
              />
              <div
                className="hide-div-resetPass"
                onClick={togglePasswordVisibility}
              >
                <img
                  src={showPassword ? show : hide}
                  width="25px"
                  height="25px"
                  alt="hide"
                />
              </div>
              <input
                className="resetPassinputpass"
                placeholder="Confirm Password"
                type={showPassword ? "text" : "password"}
                required
                onChange={(e) => {
                  setconfirmPassword(e.target.value);
                }}
              />

              <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", fontSize: "12px", marginTop: "10px" }}>
                <ul>
                  <li className={`${lacksLength && 'text-red'}`}>8 or more characters</li>
                  <li className={`${lacksChar && 'text-red'}`}>1 special character</li>
                  <li className={`${lacksUpper && 'text-red'}`}>1 Uppercase character</li>
                </ul>
                <ul>
                  <li className={`${lacksNumber && 'text-red'}`}>1 number</li>
                  <li className={`${lacksLower && 'text-red'}`}>1 lowercase character</li>
                </ul>
              </div>

              {passwordMistmatch && (
                <p style={{ fontSize: "12px" }}>
                  Passwords do not match
                </p>
              )}

              <LoginSpinner
                className="login-button"
                text="Reset Password"
                onSubmit={handleSignupForm}
                loading={showLoader}
                disabled={showLoader}
              />
            </form>
          </div>
        </div>
      </div>
      <Toast ref={toast} position='top-right' />
    </div>
  );
}

export default ResetPassword;
