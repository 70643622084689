import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { InputSwitch } from 'primereact/inputswitch';

const PaymentMethodTab = () => {

    const [sameOutgoingMethod, setSameOutgoingMethod] = useState(false);

    return (
        <div className="payment-method-tab">
            <Row>
                <Col xs={12} md={6}>
                    <div className="payment-method-tab-header">
                        <h3>Payment Method</h3>
                        <p>
                            Choose your desirable gateway to pay for services on
                            Frizda.
                        </p>
                    </div>

                    <div className="">
                        
                    </div>
                </Col>
                <Col xs={12} md={6}>
                    <Row className="payment-method-tab-header align-items-center">
                        <Col xs={12} md={6}>
                            <h3>Incoming Transactions</h3>
                        </Col>
                        <Col xs={12} md={6} className="d-flex justify-content-end align-items-center gap-3">
                            <p>Same as Outgoing Method</p>
                            <InputSwitch
                                className="affiliate-switch"
                                checked={sameOutgoingMethod}
                                onChange={(e) => setSameOutgoingMethod(e.value)}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default PaymentMethodTab;
