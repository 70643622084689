import React, { useState, useEffect, createContext, useContext } from 'react';
import { LoggedInContext } from './businessAuthState';

export const SelectedPlanContext = createContext({
    selectedPlan: '',
    setSelectedPlan: () => { }
});

export function SelectedPlanProvider({ children }) {
    const { loggedIn } = useContext(LoggedInContext)
    const [selectedPlan, setSelectedPlan] = useState(localStorage.getItem('selectedPlan') || '');

    useEffect(() => {
        localStorage.setItem('selectedPlan', selectedPlan);
    }, [selectedPlan]);

    useEffect(() => {
        !loggedIn && setSelectedPlan('')
    }, [loggedIn]);

    return (
        <SelectedPlanContext.Provider value={{ selectedPlan, setSelectedPlan }}>
            {children}
        </SelectedPlanContext.Provider>
    )
}
