import React, { useState } from 'react';
import { FilterOptionSelect } from '../../../components/home-page/OutstandingClubs-components/FilterOptions';

export default function MusicalGenres({ selectedGenres, setSelectedGenres }) {

    const musicalGenres = [
        'Mix', 'Reggaeton', 'Techno', 'Rap', 'Trap', 'Rock', 'Dance',
        'Deephouse', 'Jazz', 'Bachata', 'Salsa', 'Techouse', 'House', 'Avant Garde', 'Dark Techno',
        'Merengue', 'Electronic Dance Music', 'Hip-hop', 'Pop', 'Country', 'Reggae',
        'Indie and Alternative', 'Disco-funk'
    ];
    const [hideSecondGenreSelect, setHideSecondGenreSelect] = useState(true);
    const [hideThirdGenreSelect, setHideThirdGenreSelect] = useState(true);

    return (
        <div className='p-4 position-relative'>
            <h5 className='d-flex justify-content-center'><i className='bi bi-music-note-beamed me-3' /> Musical genres</h5>
            <p className='explanation-text'>You will be able to change the current musical genre that is being played whenever you want. You can choose a maximum of 3 musical grenres</p>

            <div className='position-relative'>
                <FilterOptionSelect
                    extraClassNames='w-75'
                    selectObjectText='Select a musical genre'
                    array={musicalGenres.filter((genre) => ![selectedGenres.genre2].includes(genre) && ![selectedGenres.genre3].includes(genre))}
                    extraCustomOption={false}
                    required={true}
                    onSelectChange={(selectedGenre) => {
                        if (selectedGenre == null) {
                            setSelectedGenres((prev) => ({ ...prev, genre1: null, genre2: null, genre3: null }));
                            setHideSecondGenreSelect(true);
                            setHideThirdGenreSelect(true);
                        } else {
                            setSelectedGenres((prev) => ({ ...prev, genre1: selectedGenre }));
                        }
                    }}
                />
                {hideSecondGenreSelect && <button className='btn btn-success btn-sm add-another-musical-genre' onClick={() => setHideSecondGenreSelect(false)}>+</button>}
            </div>

            {!hideSecondGenreSelect &&
                <>
                    <FilterOptionSelect
                        extraClassNames='w-75'
                        selectObjectText='Select a second musical genre'
                        array={musicalGenres.filter(genre => ![selectedGenres.genre1].includes(genre) && ![selectedGenres.genre3].includes(genre))}
                        extraCustomOption={false}
                        onSelectChange={selectedGenre => {
                            setSelectedGenres((prev) => ({ ...prev, genre2: selectedGenre }));

                            if (selectedGenre == null) {
                                setSelectedGenres(prev => ({ ...prev, genre2: null, genre3: null }));
                                setHideThirdGenreSelect(true);
                            }
                        }}
                        disabled={selectedGenres.genre1 === null}
                    />

                    {hideThirdGenreSelect &&
                        <>
                            <button className='btn btn-success btn-sm add-another-musical-genre me-2' onClick={() => setHideThirdGenreSelect(false)}>+</button>
                            <button className='btn btn-danger btn-sm add-another-musical-genre' onClick={() => {
                                setHideSecondGenreSelect(true);
                                setSelectedGenres(prev => ({ ...prev, genre2: null }));
                            }
                            }>-</button>
                        </>
                    }
                </>
            }

            {!hideThirdGenreSelect &&
                <>
                    <FilterOptionSelect
                        extraClassNames='w-75'
                        selectObjectText='Select a third musical genre'
                        array={musicalGenres.filter(
                            (genre) => ![selectedGenres.genre1].includes(genre) && ![selectedGenres.genre2].includes(genre)
                        )}
                        extraCustomOption={false}
                        onSelectChange={(selectedGenre) => {
                            setSelectedGenres((prev) => ({ ...prev, genre3: selectedGenre }));
                        }}
                        disabled={selectedGenres.genre2 === null || selectedGenres.genre1 === null}
                    />

                    <button className='btn btn-danger btn-sm add-another-musical-genre' onClick={
                        () => {
                            setHideThirdGenreSelect(true)
                            setSelectedGenres(prev => ({ ...prev, third: null }));
                        }
                    }>-</button>
                </>
            }

        </div>
    )
}