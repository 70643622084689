import React from "react";
import Footer from "./Footer";
import LandingNavbar from "./LandingNavbar";

const LandingPageWrapper = ({ children }) => {
    return (
        <div>
            <LandingNavbar />
            {children}
            <Footer />
        </div>
    );
};

export default LandingPageWrapper;
