import React from 'react'
import { Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
    affilicationSystem,
    clientDataInsights,
    frizdaAds,
    frizdaSmartMap,
    monetoryStatistics,
    ticketSelling
} from '../../assets/images'
import FeatureCard from './FeatureCard'
import './styles/Features.css'
import { useTranslation } from 'react-i18next'

const Features = () => {
    const { t } = useTranslation();

    const FEATURES = [
        {
            id: 1,
            imgUrl: affilicationSystem,
            name: 'affiliation_system',
            description: 'affiliation_system_desc'
        },
        {
            id: 2,
            imgUrl: monetoryStatistics,
            name: 'monetory_statistics',
            description: 'monetory_statistics_desc'
        },
        {
            id: 3,
            imgUrl: clientDataInsights,
            name: 'client_data_insights',
            description: 'client_data_insights_desc'
        },
        {
            id: 4,
            imgUrl: ticketSelling,
            name: 'seamless_ticket_selling',
            description: 'seamless_ticket_selling_desc'
        },
        {
            id: 5,
            imgUrl: frizdaAds,
            name: 'frizda_ads',
            description: 'frizda_ads_desc'
        },
        {
            id: 6,
            imgUrl: frizdaSmartMap,
            name: 'frizda_smart_map',
            description: 'frizda_smartmap_desc'
        }
    ]
    return (
        <section id='features' className='features-section'>
            <div className="feature-header">
                <p>{t('landing_page.features_header')}</p>
            </div>
            <div className="feature-card-wrapper">
                {
                    FEATURES.map((feature) => (
                        <FeatureCard key={feature.id} {...feature} />
                    ))
                }
            </div>
            <div className="d-sm-none">
                <Swiper
                    centeredSlides={true}
                    loop={true}
                    autoplay={{ delay: 2000, disableOnInteraction: false }}
                    className="m-0 feature-card-swiper"
                    modules={[Autoplay]}
                >
                    {
                        FEATURES.map((feature) => (
                            <SwiperSlide key={feature.id}>
                                <FeatureCard {...feature} />
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
        </section>
    )
}

export default Features