import React, { useState } from 'react'
import SideBar from './components/SideBar'
import Navbar from './components/Navbar'
import NightspotDetails from './nightspot-details/NightspotDetails'
import { Outlet } from 'react-router-dom'

const FrizdaDashboard = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true)

  return (
    <div className='d-flex flex-row'>
      <div >
        <SideBar setIsSidebarCollapsed={setIsSidebarCollapsed} isSidebarCollapsed={isSidebarCollapsed} />
      </div>
      <div className='dashboard-main-content'>
        <Navbar setIsSidebarCollapsed={setIsSidebarCollapsed} />
        <div className="content-wrapper">
          <Outlet />
          {/* <NightspotDetails /> */}
        </div>
      </div>

    </div>
  )
}

export default FrizdaDashboard