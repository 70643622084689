import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { FaPlus } from "react-icons/fa";
import { FaArrowUp, FaClock } from "react-icons/fa6";

const NightspotFeed = () => {
    const [startDate, setStartDate] = useState(null);

    return (
        <div className="frizda-ads-plan-card">
            <div className="">
                <div className="frizda-ads-plan-header">
                    <h4>Your Nightspot on Frizda Feed</h4>
                    <p>
                        148%
                        <FaArrowUp color="green" /> User Interest
                    </p>
                </div>
                <div className="frizda-ads-card-text">
                    <p>
                        Upload an <span style={{ color: "#7A38FE" }}>Ad</span>{" "}
                        (image, title, description) that will pop as soon as
                        users click on your business' profile. In order to boost
                        the ad, you can also choose to add a sparkling effect
                        around your nightspot marker, send a notification to
                        users around, or both!
                    </p>
                </div>
            </div>

            <div className="nightspot-feed-form">
                <Row>
                    <Col xs={12} md={4}>
                        <div class="feed-add-image-area">
                            <div className="">
                                <FaPlus size={20} />
                                <p>Attach Files</p>
                            </div>
                            <input type="file" name="file" />
                        </div>
                    </Col>
                    <Col xs={12} md={8}>
                        <div className="ad-details-inputs">
                            <input
                                type="text"
                                name="title"
                                id="title"
                                placeholder="Title"
                            />
                            <textarea
                                name="description"
                                id="description"
                                placeholder="Description"
                                rows="5"
                            ></textarea>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12}>
                        <div className="standout-date">
                            <label htmlFor="startDate">Select Time</label>
                            <div className="">
                                <FaClock color="#7A38FE" size={20} />
                                <DatePicker
                                    className="standout-datepicker"
                                    id="startDate"
                                    minDate={new Date()}
                                    placeholderText="DD/MM/YYYY"
                                    dateFormat="dd/MM/yyyy"
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <p className="note-text" style={{ color: "#555555" }}>
                    Get your Special event be visible with sparkling effect on
                    Frizda Feed
                </p>
            </div>

            <div className="frizda-ads-plan-footer">
                <p>
                    € 255.36 <span style={{ color: "#A3A3A3" }}>€ 64.92</span>
                </p>
                <button>Activate</button>
            </div>
        </div>
    );
};

export default NightspotFeed;
