export function convertToFormData(data) {
    const formData = new FormData();

    function appendFormData(key, value) {
        if (value === null || value === undefined) {
            formData.append(key, '');
        } else if (key === 'openingHours' || key === 'paymentMethods') {
            formData.append(key, JSON.stringify(value));
        } else if (typeof value === 'object' && !Array.isArray(value)) {
            for (const subKey in value) {
                appendFormData(`${key}[${subKey}]`, value[subKey]);
            }
        } else if (Array.isArray(value)) {
            value.forEach((item) => {
                formData.append(`${key}`, item);
            });
        } else {
            formData.append(key, value);
        }
    }

    for (const key in data) {
        appendFormData(key, data[key]);
    }

    return formData;
}

export const whatsAppShare = (messageTxt = "") => {
    console.log("messageTxt", messageTxt);
    const phoneNumber = "+32 (476) 05-20-08";
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    if (isMobile) {
        // Redirect to WhatsApp app
        window.location.href = `whatsapp://send?phone=${phoneNumber}&text=${encodeURI(messageTxt)}`;
    } else {
        // Redirect to WhatsApp Web
        window.open(`https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURI(messageTxt)}`, '_blank');
    }
}; 