import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IoClose } from "react-icons/io5";
import { MdMessage } from "react-icons/md";
import { calendly } from "../../../assets/images";
import { whatsAppShare } from "../../../utils/Helper";

const ConsultantModal = ({ showConsultantModal, setShowConsultantModal }) => {
    const { t } = useTranslation();

    return (
        <Modal
            show={showConsultantModal}
            onHide={setShowConsultantModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="consultant-modal"
        >
            <Modal.Body className="consultant-modal-body">
                <div className="consultant-modal-header">
                    <h5>{t("call_a_consultant.schedule_a_consultation")}</h5>
                    <p>{t("call_a_consultant.get_ready")}</p>
                </div>

                <div
                    className="consultant-card-item"
                    onClick={() => whatsAppShare()}
                >
                    <div className="consultant-item-image">
                        <MdMessage size={50} color="#7A38FE" />
                    </div>
                    <div className="consultant-item-description">
                        <p className="header" style={{ color: "#7A38FE" }}>
                            {t("call_a_consultant.call_consultant")}
                        </p>
                        <p className="description">
                            {t("call_a_consultant.call_consultant_description")}
                        </p>
                    </div>
                </div>

                <div className="w-100 text-center" style={{ color: "#4A4A4A" }}>
                    {t("call_a_consultant.or")}
                </div>

                <div
                    className="consultant-card-item cursor-pointer"
                    onClick={() =>
                        window.open(
                            "https://calendly.com/frizda-business/20-min-meeting",
                            "_blank"
                        )
                    }
                >
                    <div className="consultant-item-image">
                        <img
                            src={calendly}
                            alt="Calendly"
                            className="w-100 h-100 object-fit-contain"
                        />
                    </div>
                    <div className="consultant-item-description">
                        <p className="header" style={{ color: "#006BFF" }}>
                            {t("call_a_consultant.schedule_a_meet")}
                        </p>
                        <p className="description">
                            {t("call_a_consultant.book_a_meet")}
                        </p>
                    </div>
                </div>

                <div
                    className="position-absolute d-flex justify-content-center align-items-center cursor-pointer"
                    style={{ top: 20, right: 20 }}
                    onClick={() => setShowConsultantModal(false)}
                >
                    <IoClose size={25} color="#4A4A4A" />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ConsultantModal;
