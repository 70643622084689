import React, { useContext, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import BusinessNavbar from '../BusinessNavbar';
import { useNavigate } from 'react-router-dom';
import noImagesImage from '../../styling/images/commercial/noImages.png';
// ↓ Mapbox API
import AddressSelectionMap from './AddressSelectionMap';
// The CSS file for this this .jsx file is add-nightclub.css
// ↓ External data from inner project imports
// ↓ External components
import BarLoader from "react-spinners/BarLoader";
import { FilterOptionSelect, FilterTimePicker } from '../../components/home-page/OutstandingClubs-components/FilterOptions';
// ↓ React components (specially for filters)
import PaymentMethods from './nightclub-details-components/PaymentMethods';
import MusicalGenres from './nightclub-details-components/MusicalGenres';
import OpeningHours from './nightclub-details-components/OpeningHours';
import DrinksPrice from './nightclub-details-components/DrinksPricing';
import ToiletAvailability from './nightclub-details-components/ToiletAvilability';
import ClubType from './nightclub-details-components/ClubType';
// ↓ Backend operator
import axios from 'axios';
// ↓ Contexts
import { LoggedInContext } from '../businessStates&Auth/businessAuthState';
import { SelectedPlanContext } from '../businessStates&Auth/SelectedPlanState';
import { NewNightspotContext } from '../businessStates&Auth/newNighstpotState';

export default function NightclubDetails(props) {

    // ↓ External functionalities
    const navigate = useNavigate();
    const { loggedIn, accountId } = useContext(LoggedInContext);
    const {
        selectedPlan, setSelectedPlan,
        nightspotName, setNightspotName,
        nightspotDescription, setNightspotDescription,
        nightspotEmail, setNightspotEmail,
        images, setImages,
        locationCoords, setLocationCoords,
        locationAddress, setLocationAddress,
        nightspotKind, setNightspotKind,
        nightspotTheme, setNightspotTheme,
        entryFee, setEntryFee,
        acceptedPayments, setAcceptedPayments,
        costPerTicket, setCostPerTicket,
        musicalGenres, setMusicalGenres,
        schedule, setSchedule,
        drinks, setDrinks,
        toilets, setToilets,
        ownedBy, setOwnedBy,
        cardReaders, setCardReaders
    } = useContext(NewNightspotContext);

    // ↓ Automatic redirections
    useEffect(() => {
        !loggedIn && navigate('/business/signup');
        selectedPlan === '' && navigate('/business/add/plans');
    }, [loggedIn, selectedPlan]);

    // ↓ External state variables
    const [isSubmitting, setSubmitting] = useState(false);

    const [nightclubDescription, setNightclubDescription] = useState('');
    const [nightclubName, setNightclubName] = useState('');
    const [useDescription, setUseDescription] = useState(true);
    const [nightclubEmail, setEmail] = useState('');

    // ↓ Images selection
    const [selectedImages, setSelectedImages] = useState([]);  // For the backend
    const [displayImages, setDisplayImages] = useState([]);  // For the frontend
    const [isMaxImagesReached, setMaxImagesReached] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    let maxImages = 7;

    async function handleImageUpload(event) {
        const files = event.target.files;

        if (files.length > maxImages) {
            setMaxImagesReached(true);
            setSelectedImages([]);
            event.target.value = null;
            return;
        }

        // Store the File objects in the selectedImages state
        setSelectedImages(files);

        const imageUrls = Array.from(files).map(file => URL.createObjectURL(file));
        setDisplayImages(imageUrls);
    }


    useEffect(() => {
        if (selectedImages.length > 1) {
            const intervalId = setInterval(() => {
                setCurrentImageIndex(prevIndex => (prevIndex + 1) % selectedImages.length);
            }, 5000);

            return () => clearInterval(intervalId);
        }

        if (isMaxImagesReached) {
            setTimeout(() => {
                setMaxImagesReached(false);
            }, 10000);
        }
    }, [selectedImages, isMaxImagesReached]);

    // ↓ Nightclub selected details (states of selection)
    // ↓ Address
    const [nightspotCoords, setNightspotCoords] = useState({
        longitude: null,
        latitude: null,
    });
    const [nightspotAddress, setNightspotAddress] = useState('');

    // ↓ Payment methods
    const [chosenPayments, setChosenPayments] = useState([]);
    const [sentChosenPayments, setSentChosenPayments] = useState({});  // ← For submit function
    const [atLeastOnePayment, setAtLeastOnePayment] = useState(true);

    useEffect(() => {
        chosenPayments.length > 0 ? setAtLeastOnePayment(true) : setAtLeastOnePayment(false);
    }, [chosenPayments]);

    // ↓ Musical genres
    const [selectedGenres, setSelectedGenres] = useState({
        genre1: null,
        genre2: null,
        genre3: null
    });

    // ↓ Opening hours
    const [selectedHours, setSelectedHours] = useState({
        monday: { opening: null, closing: null, closed: false },
        tuesday: { opening: null, closing: null, closed: false },
        wednesday: { opening: null, closing: null, closed: false },
        thursday: { opening: null, closing: null, closed: false },
        friday: { opening: null, closing: null, closed: false },
        saturday: { opening: null, closing: null, closed: false },
        sunday: { opening: null, closing: null, closed: false }
    });

    function timeToString(time) {
        if (!time) return '';
        const { hour, minutes } = time;
        return `${hour ? hour.toString().padStart(2, '0') : '00'}:${minutes ? minutes.toString().padStart(2, '0') : '00'}`;
    }

    const [daysAreComplete, setDaysAreComplete] = useState(false);

    // ↓ Drinks pice
    const [sellsDrinks, setSellsDrinks] = useState(true);
    const [drinkPriceRating, setDrinkPriceRating] = useState('Select an average drink price');
    const [numericPriceRating, setNumericPriceRate] = useState(null);

    useEffect(() => {
        drinkPriceRating == '€' && setNumericPriceRate(1.0);
        drinkPriceRating == '€€' && setNumericPriceRate(2.0);
        drinkPriceRating == '€€€' && setNumericPriceRate(3.0);
        drinkPriceRating == '€€€€' && setNumericPriceRate(4.0);

        drinkPriceRating == '' && setNumericPriceRate(0.0);
    }, [drinkPriceRating]);

    const [useMenu, setUseMenu] = useState(true);
    const [pdfMenuValid, setPdfMenuValid] = useState(true);
    const [pdfSrc, setPdfSrc] = useState(null);

    function handleMenuUpload(event) {
        const file = event.target.files[0];

        if (file && file.name.toLowerCase().endsWith('.pdf')) {
            const blobUrl = URL.createObjectURL(file);
            setPdfSrc(blobUrl);
            setPdfMenuValid(true);
        } else {
            setPdfMenuValid(false);
        }
    }

    const drinksObject = {
        ratedCost: numericPriceRating,
        menuAvailable: useMenu,
        menuFile: pdfSrc ? pdfSrc : null
    }

    useEffect(() => {
        return () => {
            if (pdfSrc) { URL.revokeObjectURL(pdfSrc) }
        }
    }, [pdfSrc]);

    // ↓ Toilets
    const [areThereToilets, setAreThereToilets] = useState(true);
    const [areThereNeutralToilets, setAreThereNeutralToilets] = useState(false);
    const [toiletPrices, setToiletPrices] = useState({});
    const [freeToilets, setFreeToilets] = useState(false);

    useEffect(() => {

        if (!areThereToilets) {
            setAreThereNeutralToilets(false);
            setFreeToilets(false);
        }
    }, [areThereToilets, toiletPrices, areThereNeutralToilets]);

    const toiletsObject = {
        toiletsAvailable: areThereToilets,
        genderNeutralToilets: areThereNeutralToilets,
        cost: !freeToilets ? toiletPrices?.natNum + toiletPrices?.decimalNum / 100 : null,
        free: freeToilets
    }

    // ↓ Nighspot type
    const [selectedNightspot, setSelectedNightSpot] = useState('');
    const [nightspotType, setNightspotType] = useState('');
    const [entryCost, setEntryCost] = useState({
        solidEuro: null,
        euroCents: null,
        free: false
    });
    const floatEntryCost = Number(entryCost?.solidEuro) + Number(entryCost?.euroCents) / 100;

    const entryFeeObject = {  // ← For main submit function
        cost: entryCost.free ? 0.0 : floatEntryCost,
        free: entryCost.free
    }

    // ↓ MAIN SUBMIT FUNCTION ↓

    const [isFormValid, setFormValid] = useState(false);
    const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
    const [submissionMessage, setSubmissionMessage] = useState('');

    // ↓ Valid details there verifier
    useEffect(() => {

        const isBasicInfoValid = nightclubName !== '' && selectedImages.length > 0;

        // Update the overall form validity
        setFormValid(isBasicInfoValid);

    }, [nightclubName, nightclubEmail, selectedImages, nightspotCoords.longitude, nightspotCoords.latitude]);


    async function submitToBackend(event) {
        event.preventDefault()

        setSubmitting(true);

        try {
            const formData = new FormData();

            // Append the files to formData
            for (let i = 0; i < selectedImages.length; i++) {
                formData.append('photos', selectedImages[i]);
            }

            // Append the other form fields to formData
            // formData.append('nightclubData', JSON.stringify({
            //     selectedPlan: selectedPlan,
            //     nightclubName: nightclubName,
            //     nightclubDescription: useDescription ? nightclubDescription : null,
            //     nightclubEmail: nightclubEmail,
            //     nightspotCoords: {
            //         longitude: nightspotCoords.longitude || null,
            //         latitude: nightspotCoords.latitude || null
            //     },
            //     nightspotAddress: nightspotAddress,
            //     selectedNightspot: selectedNightspot,
            //     nightspotType: nightspotType,
            //     entryFeeObject: entryFeeObject,
            //     acceptedPayments: chosenPayments,
            //     selectedGenres: {
            //         genre1: selectedGenres.genre1,
            //         genre2: selectedGenres.genre2 !== null ? selectedGenres.genre2 : null,
            //         genre3: selectedGenres.genre3 !== null ? selectedGenres.genre3 : null
            //     },
            //     selectedHours: selectedHours,
            //     drinksObject: sellsDrinks ? drinksObject : null,
            //     toiletsObject: toiletsObject,
            //     ownedBy: accountId
            // }));

            // Send the formData with axios
            // await axios.post('https://polar-hamlet-90480-1e735d673784.herokuapp.com/upload', formData, {
            //     headers: {
            //         'Content-Type': 'multipart/form-data'
            //     }
            // });


            setNightspotName(nightclubName);
            setNightspotDescription(useDescription ? nightclubDescription : null);
            setNightspotEmail(nightclubEmail);
            setLocationCoords({ longitude: nightspotCoords.longitude, latitude: nightspotCoords.latitude });
            setLocationAddress(nightspotAddress);
            setNightspotKind(selectedNightspot);
            setNightspotTheme(nightspotType);
            setEntryFee(entryFeeObject);
            setAcceptedPayments(chosenPayments);
            setMusicalGenres({
                genre1: selectedGenres.genre1,
                genre2: selectedGenres.genre2 !== null ? selectedGenres.genre2 : null,
                genre3: selectedGenres.genre3 !== null ? selectedGenres.genre3 : null
            });
            setSchedule(selectedHours);
            setDrinks(sellsDrinks ? drinksObject : null);
            setToilets(toiletsObject);
            setOwnedBy(accountId);
            setImages(selectedImages);

        } catch (error) {
        } finally {
            setSubmitting(false);
            navigate('/business/add');
        }
    }

    return (
        <div id='nightclub-details' className='vh-100' data-bs-theme='dark'>

            {/* <BusinessNavbar /> */}

            <form onSubmit={submitToBackend} >
                <div className='row'>
                    <div className='main-nightclub-details row col-9'>
                        <div className='offset-1 col-3 px-5 pt-4'>
                            <label htmlFor='nightclubName' className='form-label'>Nightclub name</label>
                            <input
                                value={nightclubName}
                                onChange={event => setNightclubName(event.target.value)}
                                type='text'
                                className='form-control'
                                id='nightclubName'
                                placeholder="Type the name" required
                            />
                        </div>

                        <div className='col-5 px-5 pt-4'>
                            <label htmlFor='nightclubDescription' className='form-label d-flex'>
                                Nightclub description
                                <div className='form-check form-switch d-flex align-items-center ms-2'>
                                    <input className='form-check-input' type='checkbox' role='switch' id='opcionalNightclubDescription' onChange={() => setUseDescription(!useDescription)} checked={useDescription} />
                                </div>
                            </label>
                            <textarea
                                value={nightclubDescription}
                                onChange={event => { setNightclubDescription(event.target.value) }}
                                maxLength={280}
                                className='form-control'
                                id='nightclubDescription'
                                aria-describedby='nightclubDescriptionCharacters'
                                placeholder='Write a description for your nightclub'
                                style={{ resize: 'none' }}
                                disabled={!useDescription}
                                required={!useDescription}
                            />

                            {useDescription && <div id='nightclubDescriptionCharacters' className='form-text d-flex justify-content-end'>{nightclubDescription.length}/280</div>}
                        </div>

                        <div className='col-3 pt-4'>
                            <label htmlFor='nightclubEmail' className='form-label'>Business email address</label>
                            <input className='form-control' onChange={event => setEmail(event.target.value)} type='email' id='nightclubEmail' placeholder='Enter the email address' required />
                        </div>
                    </div>
                    <div className='col-3 p-4 d-flex' style={{ height: '150px', backgroundColor: '#292b2c' }}>
                        <div>
                            <label className='form-label'>Select your nightclub images</label>
                            <input type='file' className={`form-control ${isMaxImagesReached && 'is-invalid'}`} multiple accept='image/*' onChange={handleImageUpload} required />
                            <div className={`form-text ${isMaxImagesReached && 'set-text-to-red'}`}>{isMaxImagesReached ? 'Select a maximum of 7 images' : `You selected ${selectedImages.length}/7 images`}</div>
                        </div>
                    </div>
                </div>

                <div className='row h-100 m-0' id='bottomDetails'>
                    <div className='nightclub-preview h-100 p-4 col-xl-9 col-lg-8 col-md-7 col-sm-7'>

                        <div className='card text-bg-dark' id='nightclub-card-preview'>
                            <div className='row'>
                                <div className='col-8 position-relative'>
                                    <img className='card-img p-1'
                                        src={displayImages.length > 0 ? displayImages[currentImageIndex] : noImagesImage}
                                    />

                                    <div className='main-details'>

                                        <div className='d-flex align-items-center justify-content-end'>
                                            <h5 className='my-auto d-inline me-2'>
                                                {nightspotType != '' && nightspotType}
                                                {selectedNightspot != '' && nightspotType != '' && ' - '}
                                                {selectedNightspot != '' && selectedNightspot}
                                                {nightspotType == '' && selectedNightspot == '' && 'Select nightspot type'}

                                            </h5>
                                            <i className='bi bi-emoji-sunglasses' />
                                        </div>

                                        <div className='d-flex align-items-center justify-content-end'>
                                            <h5 className='my-auto d-inline me-2'>
                                                {entryCost.free ? 'Free entry' : (
                                                    `${entryCost.solidEuro != null ? entryCost.solidEuro : ''}` +
                                                    `${entryCost.euroCents != null ? ' € ' + entryCost.euroCents : ''}`
                                                ) || 'Add entry cost'}
                                            </h5>
                                            <i className='bi bi-door-open' />
                                        </div>


                                        <div className='d-flex align-items-center justify-content-end'>
                                            <h5 className='my-auto d-inline me-2'>
                                                {selectedGenres.genre1 === null && 'Select musical genres'}
                                                {selectedGenres.genre1 !== null && selectedGenres.genre1}
                                                {selectedGenres.genre2 !== null && selectedGenres.genre1 !== null && `, ${selectedGenres.genre2}`}
                                                {selectedGenres.genre3 !== null && selectedGenres.genre2 !== null && selectedGenres.genre1 !== null && `, ${selectedGenres.genre3}`}</h5>
                                            <i className='bi bi-music-note-beamed' />
                                        </div>

                                        <div className='d-flex align-items-center justify-content-end'>
                                            <h5 className='my-auto d-inline me-2'>{drinkPriceRating}</h5>
                                            <i className='bi bi-cup-straw' />
                                        </div>

                                        <div className='d-flex align-items-center justify-content-end'>
                                            <h5 className='my-auto d-inline me-2'>{chosenPayments.length == 1 ? `${chosenPayments[0]} only` : chosenPayments.join(', ')}</h5>
                                            {chosenPayments.length == 0 && <h5 className='my-auto d-inline me-2'>Select payment methods</h5>}<i className='bi bi-cash' />
                                        </div>

                                    </div>

                                    <div className='col-lg-4 col-md-12'>
                                        <div className='py-1 px-3 me-5 d-flex card-text-container mb-3 d-inline-block'>
                                            <h2 className='card-title'>{nightclubName != '' ? nightclubName : 'Nightclub name'}</h2>
                                        </div>
                                    </div>

                                </div>

                                <div className='col-lg-4 col-md-12'>
                                    <div className='card-body'>
                                        {useDescription &&
                                            <>
                                                <h4>Nightclub Description:</h4>
                                                <h5 className={`card-text ${nightclubDescription != '' && 'explanation-text'}`}>{nightclubDescription != '' ? nightclubDescription : 'Here goes your nightclub description...'}</h5>
                                            </>
                                        }

                                        <div className='mt-3'>
                                            <h4 className='mb-3'>Opening times:</h4>

                                            <h5 className='explanation-text'>
                                                Monday: {selectedHours.monday.closed ? 'Closed' : `${timeToString(selectedHours.monday?.opening)}${(selectedHours.monday?.opening && selectedHours.monday?.closing) ? ' - ' : ''}${timeToString(selectedHours.monday?.closing)}`}
                                            </h5>
                                            <h5 className='explanation-text'>
                                                Tuesday: {selectedHours.tuesday.closed ? 'Closed' : `${timeToString(selectedHours.tuesday?.opening)}${(selectedHours.tuesday?.opening && selectedHours.tuesday?.closing) ? ' - ' : ''}${timeToString(selectedHours.tuesday?.closing)}`}
                                            </h5>
                                            <h5 className='explanation-text'>
                                                Wednesday: {selectedHours.wednesday.closed ? 'Closed' : `${timeToString(selectedHours.wednesday?.opening)}${(selectedHours.wednesday?.opening && selectedHours.wednesday?.closing) ? ' - ' : ''}${timeToString(selectedHours.wednesday?.closing)}`}
                                            </h5>
                                            <h5 className='explanation-text'>
                                                Thursday: {selectedHours.thursday.closed ? 'Closed' : `${timeToString(selectedHours.thursday?.opening)}${(selectedHours.thursday?.opening && selectedHours.thursday?.closing) ? ' - ' : ''}${timeToString(selectedHours.thursday?.closing)}`}
                                            </h5>
                                            {/* // ↓ Weekend nights */}
                                            <h5 className='explanation-text'>
                                                Friday: {selectedHours.friday.closed ? 'Closed' : `${timeToString(selectedHours.friday?.opening)}${(selectedHours.friday?.opening && selectedHours.friday?.closing) ? ' - ' : ''}${timeToString(selectedHours.friday?.closing)}`}
                                            </h5>
                                            <h5 className='explanation-text'>
                                                Saturday: {selectedHours.saturday.closed ? 'Closed' : `${timeToString(selectedHours.saturday?.opening)}${(selectedHours.saturday?.opening && selectedHours.saturday?.closing) ? ' - ' : ''}${timeToString(selectedHours.saturday?.closing)}`}
                                            </h5>

                                            <h5 className='explanation-text'>
                                                Sunday: {selectedHours.sunday.closed ? 'Closed' : `${timeToString(selectedHours.sunday?.opening)}${(selectedHours.sunday?.opening && selectedHours.sunday?.closing) ? ' - ' : ''}${timeToString(selectedHours.sunday?.closing)}`}
                                            </h5>
                                        </div>

                                        {
                                            useMenu &&
                                            <div className='mt-3'>
                                                <h4>Menu:</h4>
                                                {pdfSrc ? <a className='position-relative' href={pdfSrc} rel='noopener noreferrer' download='menu.pdf'><h5>Download Menu</h5></a> : <h5 className='explanation-text'>Upload a PDF file of the menu</h5>}
                                            </div>
                                        }

                                        <div className='mt-3'>
                                            <h4>Toilets:</h4>
                                            {!areThereToilets && <div className='explanation-text'>No toilets</div>}
                                            {areThereToilets && !freeToilets && (
                                                <div className='explanation-text d-flex align-items-center'>
                                                    {toiletPrices.natNum == null ? '0' : toiletPrices.natNum} € {toiletPrices.decimalNum == null ? '00' : toiletPrices.decimalNum} <i className='bi bi-badge-wc ms-2' />
                                                    {areThereNeutralToilets && <h6 className='d-flex align-items-center lgbt-colored ms-1'>Gender Neutral</h6>}
                                                </div>
                                            )}
                                            {freeToilets && areThereToilets && (
                                                <div className='explanation-text d-flex align-items-center'>
                                                    FREE <i className='bi bi-badge-wc ms-2' />
                                                    {areThereNeutralToilets && <h6 className='d-flex align-items-center lgbt-colored ms-1'>Gender Neutral</h6>}
                                                </div>
                                            )}
                                            {areThereNeutralToilets && <div><i /></div>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='submit-button-container'>
                                <div className='d-flex align-items-center'>
                                    {isSubmitting && <BarLoader color="#B637FB" speedMultiplier={3} width={50} />}
                                    <button
                                        type={isFormValid && 'submit'}
                                        className={`btn btn-lg btn-outline-frizda position-relative m-4 ${submitButtonClicked && !isFormValid && 'shake'}`}
                                        // disabled={!isFormValid}
                                        onClick={() => setSubmitButtonClicked(true)}
                                        disabled={isSubmitting}
                                    >
                                        Submit
                                    </button>
                                </div>

                                {submissionMessage && <div className='set-text-to-red'>{submissionMessage}</div>}
                            </div>

                        </div>
                        <div></div>
                    </div>
                    <div className='rest-nightclub-details position-relative col-xl-3 col-lg-4 col-md-5 col-sm-5'>
                        <div className='nightclub-details-container oferflow-scroll'>
                            <AddressSelectionMap
                                setNightspotCoords={setNightspotCoords}
                                setNightspotAddress={setNightspotAddress}
                            />

                            <div className='horizontal-line-75 mx-auto' />

                            <ClubType
                                setSelectedNightSpot={setSelectedNightSpot}
                                selectedNightspot={selectedNightspot}
                                setNightspotType={setNightspotType}
                                nightspotType={nightspotType}
                                setEntryCost={setEntryCost}
                            />

                            <div className='horizontal-line-75 mx-auto' />

                            <PaymentMethods
                                setChosenPayments={setChosenPayments}
                                nightclubName={nightclubName}
                                setSentChosenPayments={setSentChosenPayments}
                                setAtLeastOnePayment={setAtLeastOnePayment}
                            />

                            <div className='horizontal-line-75 mx-auto' />

                            <MusicalGenres
                                selectedGenres={selectedGenres}
                                setSelectedGenres={setSelectedGenres}
                            />

                            <div className='horizontal-line-75 mx-auto' />

                            <OpeningHours
                                selectedHours={selectedHours}
                                setSelectedHours={setSelectedHours}
                                setDaysAreComplete={setDaysAreComplete}
                                daysAreComplete={daysAreComplete}
                            />

                            <div className='horizontal-line-75 mx-auto' />

                            <DrinksPrice
                                setDrinkPriceRating={setDrinkPriceRating}
                                drinkPriceRating={drinkPriceRating}
                                useMenu={useMenu}
                                setUseMenu={setUseMenu}
                                handleMenuUpload={handleMenuUpload}
                                pdfValid={pdfMenuValid}
                                pdfSrc={pdfSrc}
                                setSellsDrinks={setSellsDrinks}
                                sellsDrinks={sellsDrinks}
                                setPdfMenuValid={setPdfMenuValid}
                            />

                            <div className='horizontal-line-75 mx-auto' />

                            <ToiletAvailability
                                setToiletPrices={setToiletPrices}
                                setAreThereToilets={setAreThereToilets}
                                areThereToilets={areThereToilets}
                                setAreThereNeutralToilets={setAreThereNeutralToilets}
                                areThereNeutralToilets={areThereNeutralToilets}
                                setFreeToilets={setFreeToilets}
                                freeToilets={freeToilets}
                            />
                        </div>
                    </div>
                </div>
            </form>


            <div className='plan-info-container rounded border border-light mb-5 mx-5 px-4 fs-4 fw-bold d-flex justify-content-center'>
                {selectedPlan}
                <button className={`btn btn-sm ms-5 ${selectedPlan != 'Frizda Basic' ? 'btn-outline-dark' : 'btn-dark'}`} onClick={() => navigate('/business/add/plans')}>Change plan</button>
            </div>
        </div>
    )
}