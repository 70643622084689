import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaArrowUp } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { eventFeeds, frizdaFeed, notifyCustomers, standOut } from "../../../assets/images";

const FrizdaAds = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    return (
        <div className="w-100 h-100">
            <div className="ads-history-row">
                <button onClick={() => navigate("history")}>Ad History</button>
            </div>
            <Row className="h-100" style={{ rowGap: "1.2rem" }}>
                <Col xs={12} md={6}>
                    <Card className="business-dashboard-card frizda-ads-card">
                        <div className="frizda-ads-card-header">
                            <h4>Stand out From the Rest</h4>
                            <p>
                                21% - 31%
                                <FaArrowUp color="green" /> User Interest
                            </p>
                        </div>
                        <div className="frizda-ads-card-content">
                            <div className="frizda-ads-card-text">
                                <p>
                                    Add a{" "}
                                    <span style={{ color: "#7A38FE" }}>
                                        sparkling effect
                                    </span>{" "}
                                    around your Nightspot marker on the Frizda
                                    Map, let users know that your business is a
                                    Banger tonight
                                </p>
                                <div className="ads-activate-btn-container">
                                    <button
                                        className="disabled-btn"
                                        disabled
                                        onClick={() =>
                                            navigate("plan/stand-out")
                                        }
                                    >
                                        {/* Activate */}
                                        Available Soon
                                    </button>
                                    <p>€ 15.00</p>
                                </div>
                            </div>
                            <div className="frizda-ads-card-img">
                                <img
                                    src={standOut}
                                    alt="Stand out From the Rest"
                                />
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col xs={12} md={6}>
                    <Card className="business-dashboard-card frizda-ads-card">
                        <div className="frizda-ads-card-header">
                            <h4>Notify Customers</h4>
                            <p>
                                52%
                                <FaArrowUp color="green" /> User Interest
                            </p>
                        </div>
                        <div className="frizda-ads-card-content">
                            <div className="frizda-ads-card-text">
                                <p>
                                    Add a{" "}
                                    <span style={{ color: "#7A38FE" }}>
                                        sparkling effect
                                    </span>{" "}
                                    around your Nightspot marker on the Frizda
                                    Map, let users know that your business is
                                    booming tonight.
                                </p>
                                <div className="ads-activate-btn-container">
                                    <button
                                        className="disabled-btn"
                                        disabled
                                        onClick={() =>
                                            navigate("plan/notify-customers")
                                        }
                                    >
                                        {/* Activate */}
                                        Available Soon
                                    </button>
                                    <p>€ 35.00</p>
                                </div>
                            </div>
                            <div className="frizda-ads-card-img">
                                <img
                                    src={notifyCustomers}
                                    alt="Notify Customers"
                                />
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col xs={12} md={6}>
                    <Card className="business-dashboard-card frizda-ads-card">
                        <div className="frizda-ads-card-header">
                            <h4>Your Nightspot on Frizda Feed</h4>
                            <p>
                                58%
                                <FaArrowUp color="green" /> User Interest
                            </p>
                        </div>
                        <div className="frizda-ads-card-content">
                            <div className="frizda-ads-card-text">
                                <p>
                                    Upload a boosting{" "}
                                    <span style={{ color: "#7A38FE" }}>Ad</span>{" "}
                                    of your{" "}
                                    <span style={{ color: "#7A38FE" }}>
                                        business profile
                                    </span>
                                    .
                                </p>
                                <div className="ads-activate-btn-container">
                                    <button
                                        className="disabled-btn"
                                        disabled
                                        onClick={() =>
                                            navigate("plan/nightspot-feed")
                                        }
                                    >
                                        {/* Activate */}
                                        Available Soon
                                    </button>
                                    <p>€ 15.00</p>
                                </div>
                            </div>
                            <div className="frizda-ads-card-img">
                                <img
                                    src={frizdaFeed}
                                    alt="Your Nightspot on Frizda Feed"
                                />
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col xs={12} md={6}>
                    <Card className="business-dashboard-card frizda-ads-card">
                        <div className="frizda-ads-card-header">
                            <h4>Your Event on Frizda Feed</h4>
                            <p>
                                67%
                                <FaArrowUp color="green" /> User Interest
                            </p>
                        </div>
                        <div className="frizda-ads-card-content">
                            <div className="frizda-ads-card-text">
                                <p>
                                    Upload a{" "}
                                    <span style={{ color: "#7A38FE" }}>Ad</span>{" "}
                                    (image/videos, title, description) of your{" "}
                                    <span style={{ color: "#7A38FE" }}>
                                        upcoming event
                                    </span>{" "}
                                    on the Frizda Feed.
                                </p>
                                <div className="ads-activate-btn-container">
                                    <button
                                        className="disabled-btn"
                                        disabled
                                        onClick={() =>
                                            navigate("plan/eventso-feed")
                                        }
                                    >
                                        {/* Activate */}
                                        Available Soon
                                    </button>
                                    <p>€ 25.00</p>
                                </div>
                            </div>
                            <div className="frizda-ads-card-img">
                                <img
                                    src={eventFeeds}
                                    alt="Your Event on Frizda Feed"
                                />
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default FrizdaAds;
