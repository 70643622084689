import App from './App.jsx';
import React from 'react';
import { createRoot } from "react-dom/client";
// I have added these three lines at index.js so you can use bootstrap in all over the app now without importing in all components..
import 'bootstrap/dist/css/bootstrap.min.css'; // ← Bootstrap CSS classes
import 'bootstrap-icons/font/bootstrap-icons.css'; // ← Bootstrap icons
import 'bootstrap/dist/js/bootstrap.bundle.min';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { PrimeReactProvider } from 'primereact/api'; // ← PrimeReact
import './utils/i18n.js'; // ← i18n for language support

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <PrimeReactProvider>
        <App />
    </PrimeReactProvider>
);