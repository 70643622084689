import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiMenuAlt3 } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import { IoLanguage } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { blacklogo, userLogo, whiteLogo } from "../../assets/images";
import { authActions } from "../../redux/authslice";
import { ApiGet, ApiPost } from "../../utils/ApiData";
import { supportedLngs } from "../../utils/i18n";
import "./styles/LandingNavbar.css";
import { MdOutlineLogout } from "react-icons/md";
const LandingNavbar = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [whiteVariant, setWhiteVariant] = useState(false);

    const { t, i18n } = useTranslation();
    const { isLoggedIn, user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname, state } = useLocation();

    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };

    const handleLanguageChange = (language) => {
        i18n.changeLanguage(language);
    };

    const validateCookieUser = async () => {
        try {
            const user = await ApiGet("user/");
            if (user) {
                dispatch(authActions.login(user?.data));
            } else {
                dispatch(authActions.logout());
                // return navigate("/business/login", { replace: true });
            }
        } catch (error) {
            console.error(error);
            // return navigate("/business", { replace: true });
        }
    };

    const logout = async () => {
        toggleSidebar();
        try {
            await ApiPost("user/signout");
            dispatch(authActions.logout());
            navigate("/business/login");
        } catch (error) {
            console.error(error);
        }
    };

    const setNavbarVariant = () => {
        if (
            pathname === "/business/about" ||
            pathname === "/business/pre-join" ||
            pathname === "/business/pre-join" ||
            pathname === "/business/claim-nightspot"
        ) {
            setWhiteVariant(true);
            return;
        }
        setWhiteVariant(false);
    };

    useEffect(() => {
        validateCookieUser();

        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const scrollToSection = (section) => {
        if (isCollapsed) {
            toggleSidebar();
        }

        if (pathname !== "/business") {
            navigate("/business", { state: { targetSection: section } });
        } else {
            scrollToTargetSection(section);
        }
    };

    const scrollToTargetSection = (section) => {
        const element = document.getElementById(section);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    // useEffect to handle scrolling after navigation completes
    useEffect(() => {
        setNavbarVariant();
        if (pathname === "/business" && state?.targetSection) {
            scrollToTargetSection(state.targetSection);
        }
    }, [pathname]);

    return (
        <>
            <nav
                className={`nav-container ${isScrolled ? "scrolled" : ""} ${
                    whiteVariant ? "white-nav-container" : ""
                }`}
            >
                <Link to="/business" className="nav-logo">
                    <img
                        src={whiteVariant ? blacklogo : whiteLogo}
                        alt="Frizda"
                        className="w-100 h-100 object-fit-contain"
                    />
                </Link>
                <div className="nav-links">
                    <NavLink
                        end
                        className={({ isActive }) =>
                            `nav-link ${isActive ? "active-nav-link" : ""}`
                        }
                        to="/business"
                    >
                        {t("landing_page.navbar.home")}
                    </NavLink>
                    {/* <NavLink to="#features" className='nav-link'>{t('landing_page.navbar.features')}</NavLink> */}
                    <div
                        onClick={() => scrollToSection("features")}
                        className="nav-link"
                    >
                        {t("landing_page.navbar.features")}
                    </div>
                    <NavLink
                        className={({ isActive }) =>
                            `nav-link ${isActive ? "active-nav-link" : ""}`
                        }
                        to="/business/pre-join"
                    >
                        {t("landing_page.navbar.pre_join")}
                    </NavLink>
                    <NavLink
                        className={({ isActive }) =>
                            `nav-link ${isActive ? "active-nav-link" : ""}`
                        }
                        to="/business/about"
                    >
                        {t("landing_page.navbar.about")}
                    </NavLink>

                    <div className="d-flex align-items-center">
                        <IoLanguage size={20} />
                        <select
                            className="nav-link language-select"
                            value={i18n.language}
                            onChange={(e) =>
                                handleLanguageChange(e.target.value)
                            }
                        >
                            {Object.entries(supportedLngs).map(
                                ([code, name]) => (
                                    <option value={code} key={code}>
                                        {name}
                                    </option>
                                )
                            )}
                        </select>
                    </div>

                    {!isLoggedIn ? (
                        <NavLink to="/business/login" className="sign-in-btn">
                            {t("landing_page.navbar.login")}
                        </NavLink>
                    ) : (
                        <div className="nav-profile-container">
                            <p>
                                {user.firstname} {user.lastname}
                            </p>
                            <div className="nav-profile-pic">
                                <img
                                    src={userLogo}
                                    alt="Frizda"
                                    className="w-100 h-100 object-fit-contain"
                                    style={{ filter: "invert(1)" }}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className="d-md-none d-flex align-items-center gap-2">
                    {!isLoggedIn ? (
                        <NavLink to="/business/login" className="sign-in-btn">
                            {t("landing_page.navbar.login")}
                        </NavLink>
                    ) : (
                        <div className="nav-profile-container">
                            <p>
                                {user.firstname} {user.lastname}
                            </p>
                            <div className="nav-profile-pic">
                                <img
                                    src={userLogo}
                                    alt="Frizda"
                                    className="w-100 h-100 object-fit-contain"
                                    style={{ filter: "invert(1)" }}
                                />
                            </div>
                        </div>
                    )}
                    <HiMenuAlt3
                        size={28}
                        color={whiteVariant ? "#0b0614" : "#FFFFFF"}
                        onClick={toggleSidebar}
                    />
                </div>
            </nav>
            <div
                className={`d-md-none sidebar ${
                    isCollapsed ? "collapsed" : ""
                }`}
            >
                <div className="sidebar-links">
                    <div className="nav-link  d-flex align-items-center">
                        <IoLanguage size={20} color="#555555" />
                        <select
                            className="nav-link language-select py-0"
                            value={i18n.language}
                            onChange={(e) =>
                                handleLanguageChange(e.target.value)
                            }
                        >
                            {Object.entries(supportedLngs).map(
                                ([code, name]) => (
                                    <option value={code} key={code}>
                                        {name}
                                    </option>
                                )
                            )}
                        </select>
                    </div>
                    <NavLink
                        end
                        className={({ isActive }) =>
                            `nav-link ${isActive ? "active-nav-link" : ""}`
                        }
                        to="/business"
                    >
                        {t("landing_page.navbar.home")}
                    </NavLink>
                    {/* <NavLink to="/business" className='nav-link'>{t('landing_page.navbar.features')}</NavLink> */}
                    <div
                        onClick={() => scrollToSection("features")}
                        className="nav-link"
                    >
                        {t("landing_page.navbar.features")}
                    </div>
                    <NavLink
                        className={({ isActive }) =>
                            `nav-link ${isActive ? "active-nav-link" : ""}`
                        }
                        to="/business/pre-join"
                    >
                        {t("landing_page.navbar.pre_join")}
                    </NavLink>
                    <NavLink
                        className={({ isActive }) =>
                            `nav-link ${isActive ? "active-nav-link" : ""}`
                        }
                        to="/business/about"
                    >
                        {t("landing_page.navbar.about")}
                    </NavLink>

                    {isLoggedIn && (
                        <div
                            className="nav-link d-flex align-items-center"
                            onClick={logout}
                        >
                            <MdOutlineLogout size={20} color="#555555" />
                            <span className="ms-2">{t("logout")}</span>
                        </div>
                    )}
                </div>
                <div
                    className="d-block d-lg-none"
                    style={{ position: "absolute", top: "20px", right: "20px" }}
                >
                    <IoMdClose
                        size={28}
                        color="#000000"
                        onClick={toggleSidebar}
                    />
                </div>
            </div>
        </>
    );
};

export default LandingNavbar;
