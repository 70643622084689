import React, { useState } from "react";
import { FaCreditCard } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa6";
import { IoShieldCheckmarkSharp } from "react-icons/io5";
import { GiWallet } from "react-icons/gi";
import { IoIosPaper } from "react-icons/io";
import './settings.css';
import CardReadersTab from "./tabs/CardReadersTab";
import PrivacyPolicyTab from "./tabs/PrivacyPolicyTab";
import ChangeCredsTab from "./tabs/ChangeCredsTab";
import PaymentMethodTab from "./tabs/PaymentMethodTab";
import LegalTab from "./tabs/LegalTab";
import { useTranslation } from "react-i18next";

const SettingsWrapper = () => {
    const { t } = useTranslation();

    const TABS = [
        {
            id: 1,
            label: t('settings_module.card_readers'),
            component: <CardReadersTab />,
            icon: <FaCreditCard size={20} />
        },
        {
            id: 2,
            label: t('settings_module.privacy_policy'),
            component: <PrivacyPolicyTab />,
            icon: <IoShieldCheckmarkSharp size={20} />
        },
        {
            id: 3,
            label: t('settings_module.change_credentials'),
            component: <ChangeCredsTab />,
            icon: <FaEnvelope size={20} />
        },
        {
            id: 4,
            label: t('settings_module.payment_methods'),
            component: <PaymentMethodTab />,
            icon: <GiWallet size={20} />
        },
        // {
        //     id: 5,
        //     label: "Legal",
        //     component: <LegalTab />,
        //     icon: <IoIosPaper size={20} />
        // }
    ];

    const [activeTab, setActiveTab] = useState(TABS[0].id);

    return <div className="w-100 h-100" style={{ fontFamily: '"DM Sans", sans-serif'}}>
        <div className="settings-tab-container">
            {TABS.map((tab) => (
                <div key={tab.id} className={`settings-tab ${activeTab === tab.id ? 'active-tab' : ''}`} onClick={() => setActiveTab(tab.id)}>
                    {tab.icon} <p>{tab.label}</p>
                </div>
            ))}
        </div>

        <div className="settings-tab-content">
            {TABS[activeTab - 1].component}
        </div>
    </div>;
};

export default SettingsWrapper;
