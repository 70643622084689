import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import {
    DollarSack,
    Customers,
    Expenses,
    default_club_image,
} from "../../../assets/images";
import { GoArrowUpRight } from "react-icons/go";
import { FaBell } from "react-icons/fa";
import { Chart } from "primereact/chart";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./homepage.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ApiGet } from "../../../utils/ApiData";
import { useNavigate } from "react-router-dom";
import { FaArrowUp } from "react-icons/fa6";

const Homepage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.auth);

    const [barChartData, setBarChartData] = useState({});
    const [barChartOptions, setBarChartOptions] = useState({});

    const [lineChartData, setLineChartData] = useState({});
    const [lineChartOptions, setLineChartOptions] = useState({});

    const [nightspotDetails, setNightspotDetails] = useState({});

    const getOwnedNightspots = async () => {
        try {
            const { data } = await ApiGet(`nightspot/${user?.nightSpotId}`);

            if (data) {
                setNightspotDetails({
                    name: data?.name,
                    description: data?.description,
                    image:
                        data?.images && data?.images?.length > 0
                            ? data?.images[0]?.url
                            : "",
                    address:
                        data?.address && data?.address?.lineOne
                            ? data?.address?.lineOne
                            : "",
                    nightspotType: data?.type
                        ? data?.type === "NIGHTCLUB"
                            ? "Nightclub"
                            : "Bar"
                        : "",
                    paymentMode: data?.paymentMode,
                    openingHours: data?.openingHours,
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const barChartData = {
            labels: [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
            ],
            datasets: [
                {
                    label: "Splendid Nights",
                    backgroundColor: "#CBBB65",
                    borderRadius: 5,
                    data: [65, 59, 80, 81, 56, 55, 40],
                },
                {
                    label: "Highlife Nights",
                    backgroundColor: "#B7B7B7",
                    borderRadius: 5,
                    data: [28, 48, 40, 19, 86, 27, 90],
                },
                {
                    label: "Fun Fantastic",
                    backgroundColor: "#7A38FE",
                    borderRadius: 5,
                    data: [28, 48, 40, 19, 86, 27, 90],
                },
            ],
        };
        const barChartOptions = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    align: "center",
                    position: "bottom",
                    labels: {
                        font: {
                            size: 12,
                            family: '"DM Sans", sans-serif',
                        },
                        usePointStyle: true,
                        pointStyle: "circle",
                        boxWidth: 10,
                        boxHeight: 10,
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        font: {
                            size: 12,
                            family: '"DM Sans", sans-serif',
                        },
                    },
                    grid: {
                        display: false,
                    },
                },
                y: {
                    ticks: {
                        display: false,
                    },
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                },
            },
        };

        const lineChartData = {
            labels: [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
            ],
            datasets: [
                {
                    label: "Dataset 1",
                    fill: false,
                    tension: 0.4,
                    data: [65, 59, 80, 81, 56, 55, 10],
                    borderWidth: 1,
                    borderColor: "#7A38FE",
                    backgroundColor: "#7A38FE",
                    pointRadius: 0,
                },
            ],
        };

        const lineChartOptions = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    display: false,
                },
            },
            scales: {
                x: {
                    ticks: {
                        font: {
                            size: 12,
                            family: '"DM Sans", sans-serif',
                        },
                    },
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                },
                y: {
                    type: "linear",
                    display: true,
                    position: "left",
                    ticks: {
                        font: {
                            size: 10,
                            family: '"DM Sans", sans-serif',
                        },
                    },
                    grid: {
                        drawBorder: false,
                    },
                    beginAtZero: true,
                },
            },
        };

        setBarChartData(barChartData);
        setBarChartOptions(barChartOptions);
        setLineChartData(lineChartData);
        setLineChartOptions(lineChartOptions);
        getOwnedNightspots();
    }, []);

    return (
        <div className="business-dashboard-container overflow-y-auto">
            <Row className="h-100">
                <Col xs={12} md={8}>
                    <Card className="business-dashboard-card">
                        <Row>
                            <Col xs={12} md={12}>
                                <h4>{t("business_home.dashboard")}</h4>
                            </Col>
                        </Row>

                        {/* Widgets [Start] */}
                        <Row className="mt-3">
                            <Col xs={12} md={4}>
                                <Card className="business-home-widget-card business-home-cards">
                                    <div className="widget-img">
                                        <img
                                            src={DollarSack}
                                            alt="DollarSack"
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "contain",
                                            }}
                                        />
                                    </div>
                                    <div className="">
                                        <p className="widget-amount">€ 0</p>
                                        <p className="widget-title">
                                            {t("business_home.income")}
                                        </p>
                                    </div>
                                </Card>
                            </Col>
                            <Col xs={12} md={4}>
                                <Card className="business-home-widget-card business-home-cards">
                                    <div className="widget-img">
                                        <img
                                            src={Customers}
                                            alt="Customers"
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "contain",
                                            }}
                                        />
                                    </div>
                                    <div className="">
                                        <p className="widget-amount">€ 0</p>
                                        <p className="widget-title">
                                            {t("business_home.total_spend")}
                                        </p>
                                    </div>
                                </Card>
                            </Col>
                            <Col xs={12} md={4}>
                                <Card className="business-home-widget-card business-home-cards">
                                    <div className="widget-img">
                                        <img
                                            src={Expenses}
                                            alt="Expenses"
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "contain",
                                            }}
                                        />
                                    </div>
                                    <div className="">
                                        <p className="widget-amount">0</p>
                                        <p className="widget-title">
                                            {t(
                                                "business_home.global_customers"
                                            )}
                                        </p>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        {/* Widgets [End] */}

                        {/* Charts [Start] */}
                        <Row className="mt-3">
                            <Col xs={12} md={6}>
                                <Card className="business-home-cards disabled-card-section">
                                    <div className="header-row">
                                        <h6>
                                            {t("business_home.customers")}{" "}
                                            <span>
                                                ({t("business_home.this_week")})
                                            </span>
                                        </h6>
                                        <div className="d-flex align-items-center justify-content-center gap-3">
                                            <p className="header-value">0</p>
                                            <span className="header-badge">
                                                <GoArrowUpRight
                                                    size={15}
                                                    color="#458E33"
                                                />{" "}
                                                0%
                                            </span>
                                        </div>
                                    </div>

                                    <div className="">
                                        <Chart
                                            type="line"
                                            data={lineChartData}
                                            options={lineChartOptions}
                                            height="220px"
                                        />
                                    </div>
                                </Card>
                            </Col>
                            <Col xs={12} md={6}>
                                <Card className="business-home-cards disabled-card-section">
                                    <div className="header-row">
                                        <h6>
                                            {t("business_home.tickets_sold")}
                                        </h6>
                                        <div className="d-flex align-items-center justify-content-center gap-3">
                                            <p className="header-value">0</p>
                                            <span className="header-badge">
                                                <GoArrowUpRight
                                                    size={15}
                                                    color="#458E33"
                                                />{" "}
                                                0%
                                            </span>
                                        </div>
                                    </div>

                                    <div className="">
                                        <Chart
                                            type="bar"
                                            data={[barChartData]}
                                            options={barChartOptions}
                                            height="220px"
                                        />
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        {/* Charts [End] */}

                        <Row className="mt-3">
                            <Col xs={12} md={12}>
                                <Card className="business-home-cards">
                                    {false ? (
                                        <div>
                                            <div className="header-row">
                                                <h6>
                                                    {t(
                                                        "business_home.top_affiliates"
                                                    )}
                                                </h6>
                                                <button className="view-all-btn">
                                                    {t("view_all")}
                                                </button>
                                            </div>

                                            <div className="business-home-table">
                                                <DataTable
                                                    value={[]}
                                                    className={`responsive-table affiliate-marketing-data-table ${
                                                        [].length === 0
                                                            ? "empty-data-table"
                                                            : ""
                                                    }`}
                                                    resizableColumns={false}
                                                    emptyMessage={t(
                                                        "no_data_found"
                                                    )}
                                                    readOnly
                                                >
                                                    <Column
                                                        field="rank"
                                                        header={t("rank")}
                                                        headerClassName="rank-col"
                                                        align="left"
                                                    ></Column>
                                                    <Column
                                                        field="name"
                                                        header={t("name")}
                                                        headerClassName="name-col"
                                                    ></Column>
                                                    <Column
                                                        field="instagram"
                                                        header="Instagram"
                                                        headerClassName="email-col"
                                                        align="center"
                                                    ></Column>
                                                    <Column
                                                        field="date"
                                                        header="Date"
                                                        headerClassName="commission-rate-col"
                                                        align="center"
                                                    ></Column>
                                                    <Column
                                                        field="commission"
                                                        header="Commission"
                                                        align="center"
                                                        headerClassName="generated-leads-col"
                                                    ></Column>
                                                    <Column
                                                        field="leads"
                                                        header="Leads"
                                                        align="center"
                                                        headerClassName="generated-leads-col"
                                                        className="text-success"
                                                    ></Column>
                                                </DataTable>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="disabled-affiliate-marketing">
                                            <div className="">
                                                <h5>
                                                    {t(
                                                        "business_home.affiliate_marketing_is_deactivated"
                                                    )}
                                                </h5>
                                                <p>
                                                    {t(
                                                        "business_home.kindly_activate_to_track_top_affiliates"
                                                    )}
                                                </p>
                                            </div>
                                            <button>
                                                {t("business_home.activate")}
                                            </button>
                                        </div>
                                    )}
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col xs={12} md={4}>
                    <Row>
                        <Col xs={12} md={12}>
                            <Card className="business-home-cards business-dashbaord-club-card">
                                {nightspotDetails && nightspotDetails?.name ? (
                                    <>
                                        <div className="club-image">
                                            <img
                                                src={
                                                    nightspotDetails?.image ??
                                                    default_club_image
                                                }
                                                alt={nightspotDetails?.name}
                                            />
                                        </div>
                                        <div className="club-details">
                                            <div className="">
                                                <h5>
                                                    {nightspotDetails?.name}
                                                </h5>
                                                <p>Manage</p>
                                            </div>
                                            <button
                                                onClick={() =>
                                                    navigate(
                                                        "/business/dashboard/nightspot-details"
                                                    )
                                                }
                                            >
                                                <GoArrowUpRight
                                                    size={25}
                                                    color="#FFFFFF"
                                                    cursor="pointer"
                                                />
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    <div className="d-flex flex-column align-items-center justify-content-center">
                                        <h1 className="lion-club-btm-div-heading">
                                            Your Nightspot Details
                                        </h1>
                                        <button
                                            className="service-client-data-details-btn"
                                            onClick={() =>
                                                navigate(
                                                    "/business/dashboard/nightspot-details"
                                                )
                                            }
                                        >
                                            <div className="service-client-data-btn-div">
                                                <h1
                                                    className="service-client-data-details-btn-para"
                                                    onClick={() =>
                                                        navigate(
                                                            "/business/dashboard/nightspot-details"
                                                        )
                                                    }
                                                >
                                                    Add Details
                                                </h1>
                                                <FaArrowUp
                                                    color="white"
                                                    size={20}
                                                    style={{
                                                        transform:
                                                            "rotate(45deg)",
                                                    }}
                                                />
                                            </div>
                                        </button>
                                    </div>
                                )}
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={12}>
                            <Card className="business-home-cards business-dashbaord-ads-card">
                                {false ? (
                                    <>
                                        <div className="home-ads-header">
                                            <h5>Active Ad Plan</h5>
                                            <button>{t("view_more")}</button>
                                        </div>

                                        <div className="home-ads-content">
                                            <span>
                                                <FaBell
                                                    size={20}
                                                    color="#FFFFFFF"
                                                />
                                            </span>
                                            <p>Notify Customers</p>
                                        </div>
                                    </>
                                ) : (
                                    <div className="d-flex flex-column justify-content-center align-items-center gap-3">
                                        <h5>No Activated Ads</h5>
                                        <div className="home-ads-header">
                                            <button
                                                onClick={() =>
                                                    navigate(
                                                        "/business/dashboard/frizda-ads"
                                                    )
                                                }
                                            >
                                                Upgrade
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={12}>
                            <Card className="business-home-cards business-dashbaord-nationality-card">
                                <h5>Nationality Distribution</h5>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default Homepage;
