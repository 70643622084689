import React from 'react'
import { useTranslation } from 'react-i18next';

const FeatureCard = ({ imgUrl, name, description }) => {
    const { t } = useTranslation();
    return (
        <div className="feature-card">
            <div className="feature-card-img-wrapper">
                <img src={imgUrl} alt={t(`landing_page.features.${name}`)} style={{ width: "100%", height: "100%", objectFit: "fill" }} />
            </div>
            <p className='feature-name'>{t(`landing_page.features.${name}`)}</p>
            <div className="feature-description">
                <p>{t(`landing_page.features.${description}`)}</p>
            </div>
        </div>
    )
}

export default FeatureCard