import { yupResolver } from "@hookform/resolvers/yup";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaEnvelope, FaEye } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { ApiGet, ApiPost } from "../../../../utils/ApiData";
import { authActions } from "../../../../redux/authslice";
import SpinLoader from "../../../../components/home-page/loaders/SpinLoader";

const credChangeSchema = yup.object().shape({
    email: yup.string().email("Enter a valid email"),
    oldPassword: yup
        .string()
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Password must be at least 8 characters, including one letter, one number, and one special character"
        ),
    newPassword: yup
        .string()
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Password must be at least 8 characters, including one letter, one number, and one special character"
        ),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref("newPassword"), null], "Passwords must match")
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Password must be at least 8 characters, including one letter, one number, and one special character"
        ),
});

const ChangeCredsTab = () => {
    const { t } = useTranslation();
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const toastRef = useRef(null);

    const [showOldPass, setShowOldPass] = useState(false);
    const [showNewPass, setShowNewPass] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(credChangeSchema, {
            defaultValues: {
                email: "",
                oldPassword: "",
                newPassword: "",
            },
        }),
    });

    const handleSaveClick = async (data) => {
        setIsLoading(true);
        const payloadData = {
            newEmail: data?.email ? data?.email : null,
            password: data?.oldPassword ? data?.oldPassword : null,
            newPassword: data?.newPassword ? data?.newPassword : null,
        };

        if (!data?.email) {
            delete payloadData.newEmail;
        }
        if (!data?.newPassword) {
            delete payloadData.newPassword;
        }
        if (!data?.oldPassword) {
            delete payloadData.password;
        }
        if (
            (data?.newPassword && !data?.oldPassword) ||
            (!data?.newPassword && data?.oldPassword)
        ) {
            toastRef.current.show({
                severity: "error",
                summary: "Error",
                detail: t("settings_module.add_old_new_both_password_error"),
                life: 2000,
            });
            setIsLoading(false);
            return;
        }

        if (data?.newPassword && data?.oldPassword && data?.newPassword === data?.oldPassword) {
            toastRef.current.show({
                severity: "error",
                summary: "Error",
                detail: t("settings_module.old_new_password_match_error"),
                life: 2000,
            });
            setIsLoading(false);
            return;
        }

        try {
            const data = await ApiPost("user/edit-profile", payloadData);

            if (data) {
                toastRef.current.show({
                    severity: "success",
                    summary: "Success",
                    detail: t("settings_module.email_changed_successfully"),
                    life: 2000,
                });

                await getUserData();
                setIsLoading(false);
            }
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Error",
                detail: error,
                life: 2000,
            });
            setIsLoading(false);
        }
    };

    const getUserData = async () => {
        try {
            const user = await ApiGet("user/");
            if (user) {
                dispatch(authActions.login(user?.data));
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            {isLoading ? (
                <SpinLoader />
            ) : (
                <form className="change-creds-tab">
                    <div className="h-50">
                        <Row>
                            <Col
                                xs={12}
                                md={12}
                                className="change-creds-tab-header"
                            >
                                <h3>{t("settings_module.change_email")}</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={4}>
                                <div className="">
                                    <label>
                                        {t("settings_module.current_email")}:
                                    </label>
                                    <p className="current-email">
                                        {user?.email}
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12} md={4}>
                                <div className="">
                                    <label>
                                        {t("settings_module.new_email")}:
                                    </label>
                                    <CredInput
                                        register={register("email")}
                                        name="email"
                                        type="email"
                                        placeholder={t("email")}
                                        icon={
                                            <FaEnvelope
                                                size={20}
                                                error={errors.email?.message}
                                            />
                                        }
                                        error={errors.email?.message}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="h-50 d-flex flex-column justify-content-between">
                        <Row>
                            <Col
                                xs={12}
                                md={12}
                                className="change-creds-tab-header"
                            >
                                <h3>{t("settings_module.change_password")}</h3>
                            </Col>
                            <Col xs={12} md={4}>
                                <CredInput
                                    register={register("oldPassword")}
                                    name="oldPassword"
                                    type={showOldPass ? "text" : "password"}
                                    placeholder={t(
                                        "settings_module.old_password"
                                    )}
                                    icon={
                                        <FaEye
                                            size={20}
                                            cursor="pointer"
                                            error={errors.oldPassword?.message}
                                            onClick={() =>
                                                setShowOldPass(!showOldPass)
                                            }
                                        />
                                    }
                                    error={errors.oldPassword?.message}
                                />
                            </Col>
                            <Col xs={12} md={4}>
                                <CredInput
                                    register={register("newPassword")}
                                    name="newPassword"
                                    type={showNewPass ? "text" : "password"}
                                    placeholder={t(
                                        "settings_module.new_password"
                                    )}
                                    icon={
                                        <FaEye
                                            size={20}
                                            cursor="pointer"
                                            error={errors.newPassword?.message}
                                            onClick={() =>
                                                setShowNewPass(!showNewPass)
                                            }
                                        />
                                    }
                                    error={errors.newPassword?.message}
                                />
                            </Col>
                            <Col xs={12} md={4}>
                                <CredInput
                                    register={register("confirmPassword")}
                                    name="confirmPassword"
                                    type={showNewPass ? "text" : "password"}
                                    placeholder={t(
                                        "settings_module.confirm_password"
                                    )}
                                    icon={
                                        <FaEye
                                            size={20}
                                            cursor="pointer"
                                            error={
                                                errors.confirmPassword?.message
                                            }
                                            onClick={() =>
                                                setShowNewPass(!showNewPass)
                                            }
                                        />
                                    }
                                    error={errors.confirmPassword?.message}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12}>
                                <button
                                    className="add-card-readers-btn"
                                    type="submit"
                                    onClick={handleSubmit(handleSaveClick)}
                                >
                                    {t("save")}
                                </button>
                            </Col>
                        </Row>
                    </div>
                </form>
            )}
            <Toast ref={toastRef} position="top-right" />
        </>
    );
};

export default ChangeCredsTab;

const CredInput = ({ register, icon, error, ...rest }) => {
    return (
        <>
            <div className="creds-input">
                <input {...register} {...rest} />
                {icon && icon}
            </div>
            {error && (
                <p
                    className="text-danger"
                    style={{ fontSize: "12px", margin: "5px 0 0 5px" }}
                >
                    {error}
                </p>
            )}{" "}
        </>
    );
};
