import React from 'react';
import '../../styling/styles/home-page-styling/footer.css';  // ← CSS file for this React component

// import apple from '../../../assets/images/download-buttons/app-store/app-store.png';
// import android from '../../../assets/images/download-buttons/play-store/play-store.png';

function Footer(props) {
    return (
        <div id='footer-section'>

            <div className='d-flex flex-column col-11 offset-1 mb-5'>
                <h3 className='footer-brand'>Frizda</h3>
                <a href=''>Help Center</a>
            </div>

            <div className='row mt-5'>
                <div className='d-flex flex-column col-4 footer-elements'>
                    <a href='#'>About</a>
                    <a href='/business'>Club Owner</a>
                    <a href='#'>Get Help</a>
                    <a href=''>Frizda Pro</a>
                </div>

                <div className='col-4'>

                </div>

                <div className='col-4'>
                
                </div>
            </div>

            <div className='copyright-footer'>
                <h5>&copy; Frizda Company</h5>

                <div className='socials-container'>
                    <i class="bi bi-instagram" />
                    <i class="bi bi-linkedin" />
                    <i class="bi bi-twitter-x" />
                    <i class="bi bi-youtube" />
                    <i class="bi bi-facebook" />
                </div>
            </div>
        </div>
    )
}

export default Footer;