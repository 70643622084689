import { default as React } from "react";
import {
    Card,
    CardBody,
    Col,
    OverlayTrigger,
    Popover,
    Row,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
    FaChevronLeft,
    FaChevronRight,
    FaCircle,
    FaCocktail,
    FaEuroSign,
    FaGlobe,
    FaMapMarkerAlt,
    FaRegCalendarCheck,
} from "react-icons/fa";
import { FaArrowUpLong } from "react-icons/fa6";
import { IoMusicalNoteSharp, IoWalletSharp } from "react-icons/io5";
import Swiper from "swiper";
import { Navigation } from "swiper/modules";
import { SwiperSlide } from "swiper/react";
import { imagePlaceholder } from "../../assets/images";
import discoPin from "../../assets/images/map-markers/disco-ball-pin.webp";
import diverseAudience from "../../assets/images/landing-page/claim-nightspot/diverse-audience.webp";
import increaseProfit from "../../assets/images/landing-page/claim-nightspot/increase-profit.webp";
import rewardCustomers from "../../assets/images/landing-page/claim-nightspot/reward-customers.webp";
import "./claimNightspot.css";

const ClaimNightspot = () => {
    const { t } = useTranslation();

    const SERIVCES = [
        {
            id: 1,
            imgURL: diverseAudience,
            title: "Reach Diverse Audience",
            description:
                "Host inclusive events to attract a broader crowd and partner with influencers to expand visibility.",
        },
        {
            id: 2,
            imgURL: increaseProfit,
            title: "Increase Profit",
            description:
                "Discover new ways to earn, like selling tickets for special events or offering VIP packages.",
        },
        {
            id: 3,
            imgURL: rewardCustomers,
            title: "Reward Your Customer",
            description:
                "Offer exclusive perks for frequent visitors and a referral system to encourage word-of-mouth.",
        },
    ];

    return (
        <>
            <div className="claim-nightspot-container">
                <Row className="claim-nightspot-row">
                    <Col sm={12} md={6}>
                        <div className="">
                            <h4 className="claim-nightspot-title">
                                <span>
                                    <img src={discoPin} alt="Frizda" />
                                </span>{" "}
                                Manage & connect with <br /> your customers on
                                Frizda.
                            </h4>
                            <p className="claim-nightspot-para">
                                Access a loyalty system with your customers,{" "}
                                <br /> reward the ones that come the most
                                frequently
                            </p>
                        </div>

                        <form className="claim-nightspot-form">
                            <input type="text" placeholder="Enter your name" />
                            <input
                                type="email"
                                placeholder="Enter your email"
                            />
                            <input
                                type="password"
                                placeholder="Create a password"
                            />
                            <button>
                                Claim your nightspot
                                <FaArrowUpLong size={20} />
                            </button>
                        </form>
                    </Col>
                    <Col
                        sm={12}
                        md={6}
                        className="d-flex flex-column justify-content-start align-items-center"
                    >
                        <div className="preview-content-container claim-nightspot-preview-container">
                            <div
                                className="bg-preview px-4"
                                style={{ overflowY: "auto" }}
                            >
                                {/* images block */}
                                {0 > 0 ? (
                                    <div className="position-relative">
                                        <Swiper
                                            slidesPerView={2}
                                            spaceBetween={30}
                                            grabCursor={true}
                                            className="nightspot-preview-slider"
                                            navigation={{
                                                prevEl: ".custom-swiper-button-prev",
                                                nextEl: ".custom-swiper-button-next",
                                            }}
                                            modules={[Navigation]}
                                        >
                                            {[].map((image, imgIndex) => (
                                                <SwiperSlide key={imgIndex}>
                                                    <img
                                                        className="preview-slider-image"
                                                        src={
                                                            image instanceof
                                                            File
                                                                ? URL.createObjectURL(
                                                                      image
                                                                  )
                                                                : image
                                                        }
                                                        alt={`Uploaded ${imgIndex}`}
                                                    />
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                        <div className="custom-swiper-button-prev">
                                            <FaChevronLeft />
                                        </div>
                                        <div className="custom-swiper-button-next">
                                            <FaChevronRight />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="d-flex flex-row justify-content-between pt-4">
                                        <div className="d-flex image-wrapper border border-2 rounded border-dark justify-content-center">
                                            <img
                                                className="image-placeholder align-self-center"
                                                src={imagePlaceholder}
                                                style={{
                                                    height: "40%",
                                                    width: "30%",
                                                    filter: "contrast(0)",
                                                }}
                                                alt="Placeholder"
                                                />
                                        </div>
                                        <div className="d-flex image-wrapper border border-2 rounded border-dark justify-content-center">
                                            <img
                                                className="image-placeholder align-self-center"
                                                src={imagePlaceholder}
                                                style={{
                                                    height: "40%",
                                                    width: "30%",
                                                    filter: "contrast(0)",
                                                }}
                                                alt="Placeholder"
                                            />
                                        </div>
                                    </div>
                                )}
                                {/* name and description block */}
                                <div className="club-name-container">
                                    <span className="single-line-text limited-text">
                                        {t("nightspot_details_module.name")}
                                    </span>
                                    <span
                                        className="multi-line-text limited-text"
                                        style={{ opacity: "0.9" }}
                                    >
                                        {t(
                                            "nightspot_details_module.nightspot_description"
                                        )}
                                    </span>
                                </div>
                                {/* info block */}
                                <Row
                                    className={`nightspot-info-container 
                                    ${
                                        // description?.length > 150
                                        //     ? "mt-0 pb-4" :
                                        "mt-5"
                                    }
                                        `}
                                >
                                    <Col xs={12} md={6} lg={6}>
                                        <Row className="nightspot-info-item">
                                            <Col xs={1} md={1} lg={1}>
                                                <FaGlobe
                                                    color="#000"
                                                    size={18}
                                                />
                                            </Col>
                                            <Col xs={11} md={11} lg={11}>
                                                <span className="info-text">
                                                    {t(
                                                        "nightspot_details_module.nightspot_type"
                                                    )}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row className="nightspot-info-item">
                                            <Col xs={1} md={1} lg={1}>
                                                <FaRegCalendarCheck
                                                    color="#000"
                                                    size={18}
                                                />
                                            </Col>
                                            <Col xs={11} md={11} lg={11}>
                                                <div className="d-flex flex-wrap">
                                                    <span className="info-text">
                                                        {t(
                                                            "nightspot_details_module.working_days_and_time"
                                                        )}
                                                    </span>
                                                    &nbsp;
                                                    <span className="info-text p-0">
                                                        {/* {getFirstDayTimes(
                                                        nightspotSchedule
                                                    )} */}
                                                    </span>
                                                </div>
                                                <OverlayTrigger
                                                    rootClose={true}
                                                    trigger="click"
                                                    placement="top-start"
                                                    overlay={
                                                        <Popover
                                                            id="popover-contained"
                                                            className="schedule-popover"
                                                        >
                                                            <Popover.Header
                                                                as="h3"
                                                                className="schedule-popover-header"
                                                            >
                                                                {t(
                                                                    "nightspot_details_module.schedule"
                                                                )}
                                                            </Popover.Header>
                                                            <Popover.Body className="schedule-popover-body"></Popover.Body>
                                                        </Popover>
                                                    }
                                                >
                                                    <div className="d-flex justify-content-start align-items-center cursor-pointer">
                                                        <span
                                                            className="info-text"
                                                            style={{
                                                                fontSize:
                                                                    "12px",
                                                                color: "#fff",
                                                                textDecoration: "underline",
                                                            }}
                                                        >
                                                            {t(
                                                                "nightspot_details_module.see_full_schedule"
                                                            )}
                                                        </span>
                                                    </div>
                                                </OverlayTrigger>
                                            </Col>
                                        </Row>
                                        <Row className="nightspot-info-item">
                                            <Col xs={1} md={1} lg={1}>
                                                <FaEuroSign
                                                    color="#000"
                                                    size={18}
                                                />
                                            </Col>
                                            <Col xs={11} md={11} lg={11}>
                                                <span className="info-text">
                                                    {"Entrance Fee"}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row className="nightspot-info-item">
                                            <Col xs={1} md={1} lg={1}>
                                                <IoWalletSharp
                                                    color="#000"
                                                    size={18}
                                                />
                                            </Col>
                                            <Col xs={11} md={11} lg={11}>
                                                <div className="d-flex">
                                                    <span className="info-text">
                                                        {t(
                                                            "nightspot_details_module.payment_mode"
                                                        )}
                                                    </span>
                                                </div>
                                                <div className="d-flex flex-wrap">
                                                    <span className="info-text">
                                                        {/* {paymentModes
                                                        .map(
                                                            (item, index) =>
                                                                paymentOptions.find(
                                                                    (option) =>
                                                                        option.id ===
                                                                        item
                                                                ).label
                                                        )
                                                        .join(", ")} */}
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xs={12} md={6} lg={6}>
                                        <Row className="nightspot-info-item">
                                            <Col xs={1} md={1} lg={1}>
                                                <FaMapMarkerAlt
                                                    color="#000"
                                                    size={18}
                                                />
                                            </Col>
                                            <Col xs={11} md={11} lg={11}>
                                                <span
                                                    className="info-text"
                                                    style={{
                                                        textOverflow:
                                                            "ellipsis",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                        display: "block",
                                                    }}
                                                >
                                                    {
                                                        // addressControlValue?.nameAdress
                                                        //     ? addressControlValue?.nameAdress
                                                        //     :
                                                        "Address"
                                                    }
                                                </span>
                                                <OverlayTrigger
                                                    rootClose={true}
                                                    trigger="click"
                                                    placement="top-end"
                                                    overlay={
                                                        <Popover
                                                            id="popover-contained"
                                                            className="schedule-popover"
                                                        >
                                                            <Popover.Header
                                                                as="h3"
                                                                className="schedule-popover-header"
                                                            >
                                                                {t(
                                                                    "nightspot_details_module.coordinates"
                                                                )}
                                                            </Popover.Header>
                                                            <Popover.Body className="schedule-popover-body">
                                                                <Row className="mb-1">
                                                                    <Col
                                                                        sm={4}
                                                                        md={4}
                                                                        lg={4}
                                                                    >
                                                                        <label className="d-flex align-items-center justify-content-between">
                                                                            {t(
                                                                                "address"
                                                                            )}
                                                                        </label>
                                                                    </Col>
                                                                    <Col
                                                                        sm={8}
                                                                        md={8}
                                                                        lg={8}
                                                                    >
                                                                        {/* {
                                                                        addressControlValue?.nameAdress
                                                                    } */}
                                                                    </Col>
                                                                </Row>
                                                                <Row className="mb-1">
                                                                    <Col
                                                                        sm={4}
                                                                        md={4}
                                                                        lg={4}
                                                                    >
                                                                        <label className="d-flex align-items-center justify-content-between">
                                                                            {t(
                                                                                "latitude"
                                                                            )}
                                                                        </label>
                                                                    </Col>
                                                                    <Col
                                                                        sm={8}
                                                                        md={8}
                                                                        lg={8}
                                                                    >
                                                                        {/* {
                                                                        addressControlValue
                                                                            ?.coordinateAddress
                                                                            ?.latitude
                                                                    }{" "} */}
                                                                        °N
                                                                    </Col>
                                                                </Row>
                                                                <Row className="mb-1">
                                                                    <Col
                                                                        sm={4}
                                                                        md={4}
                                                                        lg={4}
                                                                    >
                                                                        <label className="d-flex align-items-center justify-content-between">
                                                                            {t(
                                                                                "longitude"
                                                                            )}
                                                                        </label>
                                                                    </Col>
                                                                    <Col
                                                                        sm={8}
                                                                        md={8}
                                                                        lg={8}
                                                                    >
                                                                        {/* {
                                                                        addressControlValue
                                                                            ?.coordinateAddress
                                                                            ?.longitude
                                                                    }{" "} */}
                                                                        °E
                                                                    </Col>
                                                                    <Col
                                                                        sm={8}
                                                                        md={8}
                                                                        lg={8}
                                                                    >
                                                                        <button
                                                                            className="btn btn-secondary btn-sm mt-2"
                                                                            // onClick={() => {
                                                                            //     const latitude =
                                                                            //         addressControlValue
                                                                            //             ?.coordinateAddress
                                                                            //             ?.latitude;
                                                                            //     const longitude =
                                                                            //         addressControlValue
                                                                            //             ?.coordinateAddress
                                                                            //             ?.longitude;
                                                                            //     const nameAddress =
                                                                            //         addressControlValue?.nameAddress;
                                                                            //     let url =
                                                                            //         "";

                                                                            //     if (
                                                                            //         latitude &&
                                                                            //         longitude
                                                                            //     ) {
                                                                            //         url = `https://www.google.com/maps?q=${latitude},${longitude}`;
                                                                            //     } else if (
                                                                            //         nameAddress
                                                                            //     ) {
                                                                            //         url = `https://www.google.com/maps?q=${nameAddress}`;
                                                                            //     }

                                                                            //     if (
                                                                            //         url
                                                                            //     ) {
                                                                            //         window.open(
                                                                            //             url,
                                                                            //             "_blank"
                                                                            //         );
                                                                            //     }
                                                                            // }}
                                                                            // disabled={
                                                                            //     !addressControlValue
                                                                            //         ?.coordinateAddress
                                                                            //         ?.longitude &&
                                                                            //     !addressControlValue?.nameAddress
                                                                            // }
                                                                        >
                                                                            Google
                                                                            Maps
                                                                        </button>
                                                                    </Col>
                                                                </Row>
                                                            </Popover.Body>
                                                        </Popover>
                                                    }
                                                >
                                                    <div className="d-flex justify-content-start align-items-center cursor-pointer">
                                                        <p
                                                            className="info-text"
                                                            style={{
                                                                fontSize:
                                                                    "12px",
                                                                color: "#fff",
                                                            }}
                                                        >
                                                            {t(
                                                                "nightspot_details_module.get_directions"
                                                            )}
                                                        </p>
                                                    </div>
                                                </OverlayTrigger>
                                            </Col>
                                        </Row>
                                        <Row className="nightspot-info-item">
                                            <Col xs={1} md={1} lg={1}>
                                                <IoMusicalNoteSharp
                                                    color="#000"
                                                    size={18}
                                                />
                                            </Col>
                                            <Col xs={11} md={11} lg={11}>
                                                <div className="d-flex flex-wrap">
                                                    {/* {selectedGenre.length > 0 ? (
                                                    <span className="info-text">
                                                        {selectedGenre.join(
                                                            ", "
                                                        )}
                                                    </span>
                                                ) : ( */}
                                                    <span className="info-text">
                                                        {t(
                                                            "nightspot_details_module.music_genre"
                                                        )}
                                                    </span>
                                                    {/* )} */}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="nightspot-info-item">
                                            <Col xs={1} md={1} lg={1}>
                                                {/* {isGenderNeutralToiletAvailable ? (
                                                <img
                                                    src={Neutral}
                                                    style={{
                                                        width: "18px",
                                                        height: "18px",
                                                    }}
                                                />
                                            ) : ( */}
                                                <FaCircle
                                                    color="#000"
                                                    size={18}
                                                />
                                                {/* )} */}
                                            </Col>
                                            <Col xs={11} md={11} lg={11}>
                                                <div className="d-flex flex-wrap">
                                                    {/* {toiletModes === "available" ? (
                                                    <span className="info-text">
                                                        {t(
                                                            "nightspot_details_module.toilet_available"
                                                        )}
                                                    </span>
                                                ) : ( */}
                                                    <span className="info-text">
                                                        {t(
                                                            "nightspot_details_module.toilet_unavailable"
                                                        )}
                                                    </span>
                                                    {/* )} */}
                                                </div>
                                                {/* {toiletModes === "available" && (
                                                <div className="d-flex justify-content-start align-items-center">
                                                    <span className="info-text">
                                                        {isFreeToiletEntry
                                                            ? "Free"
                                                            : toiletEntranceFee.euros
                                                            ? `€ ${
                                                                  toiletEntranceFee.euros
                                                              }.${
                                                                  toiletEntranceFee.cents
                                                                      ? toiletEntranceFee.cents
                                                                      : "00"
                                                              } `
                                                            : "Price"}
                                                    </span>
                                                </div>
                                            )} */}
                                            </Col>
                                        </Row>
                                        <Row className="nightspot-info-item">
                                            <Col xs={1} md={1} lg={1}>
                                                <FaCocktail
                                                    color="#000"
                                                    size={18}
                                                />
                                            </Col>
                                            <Col xs={11} md={11} lg={11}>
                                                <div className="d-flex">
                                                    {/* {diningModes.length > 0 ? (
                                                    <span className="info-text">
                                                        {diningOptions.length >
                                                        1
                                                            ? diningModes
                                                                  .map(
                                                                      (item) =>
                                                                          diningOptions.find(
                                                                              (
                                                                                  option
                                                                              ) =>
                                                                                  option.id ===
                                                                                  item
                                                                          )
                                                                              .label
                                                                  )
                                                                  .join(", ")
                                                            : diningOptions.find(
                                                                  (option) =>
                                                                      option.id ===
                                                                      diningModes[0]
                                                              ).label}
                                                    </span>
                                                ) : ( */}
                                                    <span className="info-text">
                                                        {t(
                                                            "nightspot_details_module.drinks_and_dining"
                                                        )}
                                                    </span>
                                                    {/* )} */}
                                                </div>
                                                {/* {menuImage && (
                                                <div className="d-flex justify-content-start align-items-center cursor-pointer gap-1">
                                                    <HiDownload size={18} />{" "}
                                                    <a
                                                        href={menuImage}
                                                        download={`${name} Menu`}
                                                        target="_blank"
                                                        style={{
                                                            fontSize: "12px",
                                                            color: "#fff",
                                                        }}
                                                    >
                                                        Download Menu
                                                    </a>
                                                </div>
                                            )} */}
                                            </Col>
                                        </Row>
                                    </Col>
                                    {/* {ownedNightspots.length > 0 && <button disabled={isFormComplete} className='position-absolute bottom-0 mb-4 w-25'>{ownedNightspots ? 'Save Changes' : 'Upload'}</button>} */}
                                </Row>
                            </div>
                        </div>
                        <div className="nearby-club-text">
                            <span className="highlighted-count">21</span>
                            <span>
                                Nightclubs near your business <br /> are already
                                in the platform!
                            </span>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="claim-nightspot-services">
                <Row>
                    {SERIVCES.map((item) => {
                        return (
                            <Col key={item.id} sm={12} md={4}>
                                <Card
                                    className={`claim-nightspot-service-card ${
                                        item.id === 3
                                            ? "reward-customer-card"
                                            : ""
                                    }`}
                                >
                                    <CardBody>
                                        <div className="img-container">
                                            <img
                                                src={item.imgURL}
                                                alt="icon"
                                                className="w-75 h-100 object-fit-contain"
                                            />
                                        </div>
                                        <div className="service-details">
                                            <h5>{item.title}</h5>
                                            <p>{item.description}</p>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        );
                    })}
                </Row>
            </div>
        </>
    );
};

export default ClaimNightspot;
