import React, { useEffect, useState } from "react";
import ServiceNavbar from "./service-page-components/service-navbar";
import Location from "./service-page-components/location";
import Statistics from "./service-page-components/statistics";
import ServiceMarketing from "./service-page-components/marketing";
import ServiceAdvertisement from "./service-page-components/advertisement";
import ServicePlans from "./service-page-components/service-page-plans";
import ServiceProHero from "./service-home-page-pro-hero/ServiceProHero";
import ServiceProHeroResponsive from "./service-home-page-pro-hero/ServiceProHeroRes";
import { useSelector } from "react-redux";
import { ApiGet } from "../../utils/ApiData";
import Footer from "../landing-page/Footer";

import "./service-page.css";
import "./service-page-components/Hero/service-hero.css";
import "./service-responisve/service-responsive.css";

function ServiceHomePage() {
    // const { loggedIn, name, lastName } = useContext(LoggedInContext);
    const { isloggedIn, user } = useSelector((state) => state.auth);

    const [nightspotDetails, setNightspotDetails] = useState({
        name: "",
        description: "",
        image: "",
        address: "",
        nightspotType: "",
        paymentMode: [],
        openingHours: "",
    });
    const [coordinates, setCoordinates] = useState([-3.7038, 40.4168]);

    const getOwnedNightspots = async () => {
        try {
            const { data } = await ApiGet(`nightspot/${user?.nightSpotId}`);

            if (data) {
                setNightspotDetails({
                    name: data?.name,
                    description: data?.description,
                    image:
                        data?.images && data?.images?.length > 0
                            ? data?.images[0]?.url
                            : "",
                    address:
                        data?.address && data?.address?.lineOne
                            ? data?.address?.lineOne
                            : "",
                    nightspotType: data?.type
                        ? data?.type === "NIGHTCLUB"
                            ? "Nightclub"
                            : "Bar"
                        : "",
                    paymentMode: data?.paymentMode,
                    openingHours: data?.openingHours,
                });

                setCoordinates([
                    data?.address?.longitude,
                    data?.address?.latitude,
                ]);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (user?.nightSpotId) {
            getOwnedNightspots();
        }
    }, []);

    return (
        <div>
            <ServiceNavbar
                name={user?.firstname}
                lastName={user?.lastname}
                loggedIn={isloggedIn}
            />

            {/* pro-home-page */}
            <div className="service-pro-web-hero">
                <ServiceProHero
                    name={user?.firstname}
                    nightspotDetails={nightspotDetails}
                />
            </div>
            <div className="service-res-hero">
                <ServiceProHeroResponsive
                    name={user?.firstname}
                    nightspotDetails={nightspotDetails}
                />
            </div>
            
            <Location coordinates={coordinates} />
            <Statistics />
            <ServiceMarketing />
            <ServiceAdvertisement />
            <ServicePlans />
            <Footer />
        </div>
    );
}

export default ServiceHomePage;
