import React, { useState, useEffect, useContext, } from 'react';
import '../styling/styles/authentication/business-auth.css';
// ↓ Backend
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
// ↓ Frontend imported components
import SignUp from './businessStates&Auth/auth-components/SignUp';
import LogIn from './businessStates&Auth/auth-components/LogIn';
// ↓ Email verification
import validator from 'validator';
import { LoggedInContext } from './businessStates&Auth/businessAuthState';

export default function SignUpForBusiness(props) {

    const { loggedIn, setLoggedIn, setName, setLastName, setEmail, setAccountId } = useContext(LoggedInContext);

    const navigate = useNavigate();

    const [passwordsMatch, setPasswordsMatch] = useState(true);  // ← Passwords input match verification
    const [passwordHasNumber, setPasswordHasNumber] = useState(false);  // ← Boolean tells if the password contains a number
    const [passwordHasSpecialCharacter, setPasswordHasSpecialCharacter] = useState(false);  // ← Boolean tells if the password contains a special character
    const [passwordMeetsRequirements, setPasswordMeetsRequirements] = useState(false);  // ← Boolean tells if the password meets the three requirements (only 2 codely checked)
    const [loading, setLoading] = useState(false);  // ← Modifies the frontend accordingly if the page is loading or not
    const [toSignUp, setToSignUp] = useState(true);
    // ↓ States for ID verification
    const [selectedFile, setSelectedFile] = useState();
    const [idInfo, setIdInfo] = useState();
    const [noFileSelected, setNoFileSelected] = useState(false);
    const [idLoading, setIdLoading] = useState(false);

    const [flashClass, setFlashClass] = useState(false);  // ← Adds flash effect to submit button when submition goes wrong

    function showPassword() {
        let passwordInput = document.getElementById('passwordBusiness');
        let confirmPasswordInput = document.getElementById('passwordConfirmBusiness');
        let passwordLogin = document.getElementById('passwordBusinessLogin');

        if (toSignUp) {
            if (passwordInput.type == 'password') {
                passwordInput.type = 'text';
                confirmPasswordInput.type = 'text';
            } else if (passwordInput.type == 'text') {
                passwordInput.type = 'password';
                confirmPasswordInput.type = 'password';
            }
        } else {
            passwordLogin.type == 'password' ? passwordLogin.type = 'text' : passwordLogin.type = 'password';
        }
    }

    useEffect(() => {
        passwordHasNumber && passwordHasSpecialCharacter ?
            setPasswordMeetsRequirements(true) : setPasswordMeetsRequirements(false);

    }, [passwordHasNumber, passwordHasSpecialCharacter, loggedIn]);

    // ↓ For ID cards images
    async function handleFileUpload(event) {
        event.preventDefault();
        setSelectedFile(event.target.files[0]);
        console.log('handleFileUpload triggered')

        if (!event.target.files[0]) {
            console.log(!event.target.files[0]);
            return (setNoFileSelected(true));
        }

        const selectedFile = event.target.files[0];
        const formData = new FormData();
        formData.append('file', selectedFile);

        // Convert file to base64 (image to numeric)
        const reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onloadend = async () => {
            const base64String = reader.result.replace('data:', '').replace(/^.+,/, '');

            try {
                setIdLoading(true);

                console.log('trying...')
                const response = await fetch('https://api.idanalyzer.com/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        apikey: 'N7EA26OoSITRpprO2NOlmdx7jLNnRfua',
                        file_base64: base64String,
                    }),
                });
                const data = await response.json();
                setIdInfo(data);
                console.log(data);

                setIdLoading(false);

            } catch (error) {
                console.log('error:' + error);
            }
        };
    };


    async function submit(event) {
        event.preventDefault();

        const name = idInfo.result.firstName;
        const lastName = idInfo.result.lastName;
        const email = document.getElementById('emailBusiness').value;
        const password = document.getElementById('passwordBusiness').value;
        const confirmedPassword = document.getElementById('passwordConfirmBusiness').value;

        const account = {
            ownerAccount: true,
            name: name,
            lastName: lastName,
            email: email,
            password: password,
            confirmedPassword: confirmedPassword
        }

        try {
            setLoading(true);

            if (password == confirmedPassword && passwordMeetsRequirements && validator.isEmail(email) && idInfo) {
                // logic to submit to database
                axios.post('https://polar-hamlet-90480-1e735d673784.herokuapp.com/data', account)
                    .then(response => {
                        setName(name);
                        setLastName(lastName);
                        setEmail(email);
                        setAccountId(response.data.insertId);
                        console.log(response.data);
                        setLoggedIn(true);
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            } else {
                const passwordRequirmentText = document.getElementById('numberRequirement');
                const specialCharacterRequirementText = document.getElementById('specialCharacterRequirement');

                setFlashClass(true); // ← Will modify the display of the submit button
                setTimeout(() => {
                    setFlashClass(false);
                }, 1001);

                password != confirmedPassword ? setPasswordsMatch(false) : setPasswordsMatch(true);
                !passwordHasNumber ? passwordRequirmentText.classList.add('set-text-to-red') : passwordRequirmentText.classList.remove('set-text-to-red');
                !passwordHasSpecialCharacter ? specialCharacterRequirementText.classList.add('set-text-to-red') : specialCharacterRequirementText.classList.remove('set-text-to-red');
            }

            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    function checkPasswordRequirements(event) {  // ← Checks and changes the states every single change on the password input of ' id="passwordBusiness" '
        const passwordValue = event.target.value;

        // Check numbers and special characters in the input
        const hasNumber = /\d/.test(passwordValue);
        const hasSpecialCharacter = /[!@#\$%\^&\*\(\),\.\?":\{\}\|<>]/.test(passwordValue);

        // Set boolean value accordingly
        setPasswordHasNumber(hasNumber);
        setPasswordHasSpecialCharacter(hasSpecialCharacter);
    }

    return (
        <div className='' id='business-auth-parent'>
            {toSignUp ?
                <SignUp
                    submit={submit}
                    checkPasswordRequirements={checkPasswordRequirements}
                    loading={loading}
                    passwordsMatch={passwordsMatch}
                    showPassword={showPassword}
                    setToSignUp={() => setToSignUp(false)}
                    SignUploading={loading}
                    buttonFlashClass={flashClass}
                    passwordHasNumber={passwordHasNumber}
                    handleFileUpload={handleFileUpload}
                    idInfo={idInfo}
                    idLoading={idLoading}
                />
                :
                <LogIn
                    showPassword={showPassword}
                    setToSignUp={() => setToSignUp(true)}
                />
            }
        </div>
    )
}