import React, { useState } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { FaArrowUp, FaCalendar, FaClock } from "react-icons/fa6";

const NotifyCustomer = () => {
    const [startDate, setStartDate] = useState(null);
    const [startTime, setStartTime] = useState(null);

    return (
        <div className="frizda-ads-plan-card">
            <div className="">
                <div className="frizda-ads-plan-header">
                    <h4>Notify Customers</h4>
                    <p>
                        62%
                        <FaArrowUp color="green" /> User Interest
                    </p>
                </div>
                <div className="frizda-ads-card-text">
                    <p>
                        Send a{" "}
                        <span style={{ color: "#7A38FE" }}>notification</span>{" "}
                        to every user near your business about how great will be
                        tonight at yours!
                    </p>
                </div>
            </div>

            <div className="h-100 mt-4">
                <Row className="h-100">
                    <Col xs={12} md={6} className="d-flex align-items-center">
                        <div className="standout-date-time-contianer">
                            <div className="standout-date">
                                <label htmlFor="startDate">Select Date</label>
                                <div className="">
                                    <FaCalendar color="#7A38FE" size={20} />
                                    <DatePicker
                                        className="standout-datepicker"
                                        id="startDate"
                                        minDate={new Date()}
                                        placeholderText="DD/MM/YYYY"
                                        dateFormat="dd/MM/yyyy"
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                    />
                                </div>
                            </div>
                            <div className="standout-date">
                                <label htmlFor="startTime">Select Time</label>
                                <div className="">
                                    <FaClock color="#7A38FE" size={20} />
                                    <DatePicker
                                        className="standout-datepicker"
                                        id="startTime"
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        placeholderText="HH:MM:SS"
                                        timeFormat="HH:mm:ss"
                                        selected={startTime}
                                        showTimeCaption={false}
                                        onChange={(date) => setStartTime(date)}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className="d-flex align-items-center">
                        <div className="standout-details-accordion">
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        <label htmlFor="notificationDetails">
                                            Notification Details
                                        </label>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="ads-notification-details-body">
                                            <div className="ads-notification-details-item">
                                                <p>
                                                    This Nighspot is Trending in
                                                    your area nearby. Checkout
                                                    How they got into air.
                                                </p>
                                            </div>
                                            <div className="ads-notification-details-item">
                                                <p>
                                                    Hurrah..! Customers are
                                                    talking up about your
                                                    nightspot..
                                                </p>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Col>
                </Row>
                <p className="note-text" style={{ color: "#555555" }}>
                    *The sparkling effect will be active for 18 hrs, starting
                    from 12:00 of the selected date.{" "}
                </p>
            </div>

            <div className="frizda-ads-plan-footer">
                <p>€ 94.96</p>
                <button>Activate</button>
            </div>
        </div>
    );
};

export default NotifyCustomer;
