import { ReactComponent as Loader } from "../../auth-styles/svgs/spinner.svg"
// import "../auth-styles/signup.css"

const ForgotSpinner = ({ text, loading = false, disabled }) => {
  return (
    <button className="forgot-send-button"  disabled={disabled}>
      {!loading ? text : <Loader className="spinner" />}
    </button>
  )
}

export default ForgotSpinner