import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const PrivacyPolicyTab = () => {
    const { t } = useTranslation();

    return (
        <div className="privacy-policy-tab">
            <Row className="position-relative">
                <Col xs={12} md={12}>
                    <h3>{t("settings_module.privacy_policy")}</h3>
                </Col>
                <Col xs={12} md={12}>
                    <div className="policy-body">
                        <p>
                            <span className="frizda-title">Welcome to Frizda!</span> This Privacy Policy
                            outlines how we collect, use, and protect your
                            information when you use our platform. Information
                            We Collect
                        </p>

                        <p>Information We Collect</p>

                        <ul>
                            <li>
                                Personal Information: When you register on
                                Frizda as a business client or user, we may
                                collect personal information such as your name,
                                email address, phone number, and location.
                            </li>
                            <li>
                                Usage Data: We gather information about your
                                interactions with the platform, including
                                nightspot searches, event bookings, and
                                interactions with business clients.
                            </li>
                        </ul>

                        <p>How We Use Your Information</p>

                        <ul>
                            <li>
                                Personalization: We use your information to
                                personalize your experience on Frizda, providing
                                tailored recommendations, event notifications,
                                and special offers based on your preferences.
                            </li>
                            <li>
                                Service Improvement: Your data helps us analyze
                                user behavior and improve our services,
                                including enhancing the platform's functionality
                                and optimizing user engagement.
                            </li>
                        </ul>

                        <p>Data Protection and Security</p>

                        <ul>
                            <li>
                                Security Measures: We implement
                                industry-standard security measures to protect
                                your personal information from unauthorized
                                access, disclosure, alteration, or destruction.
                            </li>
                            <li>
                                Data Access: Access to your personal information
                                is restricted to authorized personnel only, who
                                are required to maintain the confidentiality of
                                your data.
                            </li>
                        </ul>

                        <p>Sharing of Information</p>

                        <ul>
                            <li>
                                Third-Party Services: We may share your
                                information with third-party service providers
                                who assist us in delivering our services, such
                                as payment processors or analytics providers.
                            </li>
                            <li>
                                Legal Compliance: We may disclose your
                                information if required by law or to protect our
                                rights or the rights of other users.
                            </li>
                        </ul>

                        <p>Your Choices and Rights</p>

                        <ul>
                            <li>
                                Access and Control: You can access and update
                                your personal information through your account
                                settings on Frizda. You may also request to
                                delete your account or opt-out of certain
                                communications.
                            </li>
                            <li>
                                Cookies: Frizda uses cookies and similar
                                technologies to enhance your browsing
                                experience. You can manage your cookie
                                preferences through your browser settings.
                            </li>
                        </ul>

                        <p>Changes to This Policy</p>

                        <ul>
                            <li>
                                Policy Updates: We may update this Privacy
                                Policy periodically to reflect changes in our
                                practices. We will notify you of any significant
                                updates through email or by posting a notice on
                                our platform.
                            </li>
                        </ul>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default PrivacyPolicyTab;
