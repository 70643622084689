import React, { useContext, useEffect, useState } from 'react';
import { LoggedInContext } from '../businessStates&Auth/businessAuthState';
import axios from 'axios';
import FrizdaLogo from '../../styling/images/frizda-badges/frizda-usual.svg';

export default function ChangeEmail(props) {

    const { email, setEmail, accountId } = useContext(LoggedInContext);
    const [newEmail, setNewEmail] = useState('');
    const [changeSuccesful, setChangeSuccesful] = useState(null);
    const [newEmailIsTheSame, setNewEmailIsTheSame] = useState(false);
    const [newEmailAlreadyInUse, setNewEmailAlreadyInUse] = useState(false);
    let submitStatus;

    useEffect(() => {
        axios.get(`https://polar-hamlet-90480-1e735d673784.herokuapp.com/getAccountInfo/${accountId}/email`).then(res => setEmail(res.data));
    }, [submitStatus, accountId, setEmail]);

    async function submitEmailChange(event) {
        event.preventDefault();

        if (newEmail === email) {
            setNewEmailIsTheSame(true);
            setNewEmail('');

            newEmailIsTheSame && setTimeout(() => { setNewEmailIsTheSame(false) }, 30000);
        } else {
            await axios.get(`https://polar-hamlet-90480-1e735d673784.herokuapp.com/checkEmail/${newEmail}`)
                .then(async (res) => {
                    await axios.put(`https://polar-hamlet-90480-1e735d673784.herokuapp.com/updateOwnerAccount/${accountId}/email/${newEmail}`)
                        .then(res => { submitStatus = res.status; setEmail(newEmail) }).catch(err => console.log(err));
                    submitStatus === 200 ? setChangeSuccesful(true) : setChangeSuccesful(false);
                })
                .catch(err => {
                    if (err.response.status === 409) {
                        setNewEmailAlreadyInUse(true);
                    }
                });
        }
    }

    return (
        <div className='p-5'>
            <p className='fs-2'>Change Email address</p>

            <p>Current Email Address: <span className='fw-bolder'>{email}</span></p>

            <form onSubmit={submitEmailChange}>
                <label className='form-label'>New Email Address:</label>
                <input className='form-control col-4' type='email' onChange={e => setNewEmail(e.target.value)} value={newEmail} disabled={changeSuccesful} />
                {newEmailIsTheSame && <p className='text-danger'>Introduce another email than the one already set</p>}
                {newEmailAlreadyInUse && <p className='text-warning'>Email already in use, use another one</p>}
                <button className='btn btn-frizda mt-4' type='submit'>Change</button>
            </form>

            {changeSuccesful === true ?
                <div className='border rounded-2 mt-5 text-bg-success d-flex p-5 position-relative'>
                    <p className='fs-4 my-auto'>Your Email has succesfully changed to {newEmail}</p>
                    <img alt='frizda-logo' src={FrizdaLogo} style={{ width: '50px', margin: '25px' }} className='position-absolute end-0 bottom-0' />
                </div>
                : changeSuccesful === false && !newEmailAlreadyInUse &&
                <div className='border rounded-2 mt-5 text-bg-warning d-flex flex-column p-5 position-relative'>
                    <p className='fs-4 my-auto'>
                        The server is experiencing high demand right now, try again later.
                    </p>
                    <p className='fs-5 mt-3'>
                        In the name of the Frizda team, we apologise for the arosen inconviniences.
                    </p>
                    <img alt='frizda-logo' src={FrizdaLogo} style={{ width: '50px', margin: '25px' }} className='position-absolute end-0 bottom-0' />
                </div>
            }
        </div>
    )
}