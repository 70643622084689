import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

mapboxgl.accessToken = 'pk.eyJ1IjoiZnJpemRhIiwiYSI6ImNsb3ExN3cyeDBnMWMycm12ZWxhOXQ0ejEifQ.J_7FV9WYgfgXHCz5M_8U4g';

export default function AddressSelectionMap({ setNightspotCoords, setNightspotAddress }) {
    const mapContainer = useRef(null);
    const [location, setLocation] = useState(null);
    const [lng, setLng] = useState(15.2551);  // Set your initial longitude here
    const [lat, setLat] = useState(47.5260);  // Set your initial latitude here
    const [zoom, setZoom] = useState(2.25);  // Set your initial zoom level here

    const map = useRef(null);

    useEffect(() => {
        if (map.current) return; // Skip if map is already initialized

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/dark-v10',
            center: [lng, lat],
            zoom: zoom
        });

        const geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            marker: false
        });

        map.current.addControl(geocoder, 'top-left');

        geocoder.on('result', function (event) {
            setLocation(event.result.place_name);

            setNightspotAddress(event.result.place_name);

            // If a marker already exists, remove it
            if (map.current.marker) {
                map.current.marker.remove();
            }

            // Create a new marker at the selected location and add it to the map
            map.current.marker = new mapboxgl.Marker({ draggable: true })
                .setLngLat(event.result.geometry.coordinates)
                .addTo(map.current);

            // When the marker is dragged, update the location state
            map.current.marker.on('dragend', function () {
                const lngLat = map.current.marker.getLngLat();
                setLocation(`${lngLat.lng}, ${lngLat.lat}`);

                setNightspotCoords(prevCoords => ({
                    ...prevCoords,
                    longitude: lngLat.lng,
                    latitude: lngLat.lat
                }));
            });
        });

        return () => map.current.remove();
    }, []);

    return (
        <div className='px-3 pb-4'>
            <label className='form-label'>Nightclub address</label>
            <div ref={mapContainer} style={{ display: 'flex', height: '200px', borderRadius: '10px' }} />
            <input placeholder='Select an address using the map' className='form-control mt-3 w-75' type="text" value={location} disabled readOnly />
        </div>
    );
}
