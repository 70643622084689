import axios from "axios";

export const BaseDomain = process.env.REACT_APP_BASE_URL;
export const BaseURL = BaseDomain + "/api/v1/";


const defaultHeaders = {
    isAuth: true,
    AdditionalParams: {},
    isJsonRequest: true,
};

export const handleLogout = async () => {
    try {
        const response = await ApiGet("user/signout");
        if (response) {
            window.location.href = "/business/login";
        } else {
            console.error("Something went wrong");
        }
    } catch (error) {
        console.error(error);
    }
};

export const getHttpOptions = (options = defaultHeaders) => {
    let headers = {
        Authorization: "",
        "Content-Type": "application/json",
    };

    if (
        Object.prototype.hasOwnProperty.call(options, "isJsonRequest") &&
        options.isJsonRequest
    ) {
        headers["Content-Type"] = "application/json";
    }

    if (
        Object.prototype.hasOwnProperty.call(options, "AdditionalParams") &&
        options.AdditionalParams
    ) {
        headers = { ...headers, ...options.AdditionalParams };
    }

    return { headers, withCredentials: true };
};

export const ApiGet = (
    endpoint,
    header = defaultHeaders
) => {
    return new Promise((resolve, reject) => {
        axios
            .get(BaseURL + endpoint, getHttpOptions(header))
            .then((responseJson) => {
                resolve(responseJson.data);
            })
            .catch((error) => {
                if (
                    error &&
                    Object.prototype.hasOwnProperty.call(error, "response") &&
                    error.response &&
                    Object.hasOwnProperty.call(error.response, "data") &&
                    error.response.data &&
                    Object.prototype.hasOwnProperty.call(
                        error.response.data,
                        "error"
                    ) &&
                    error.response.data.error
                ) {
                    reject(error.response.data);
                } else {
                    reject(error);
                }
            });
    });
};

export const ApiDelete = (endpoint, data) => {
    return new Promise((resolve, reject) => {
        const config = {
            method: "delete",
            maxBodyLength: Infinity,
            url: BaseURL + endpoint,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
            withCredentials: true,
        };
        axios
            .request(config)
            .then((responseJson) => {
                resolve(responseJson.data);
            })
            .catch((error) => {
                if (
                    error &&
                    Object.prototype.hasOwnProperty.call(error, "response") &&
                    error.response &&
                    Object.hasOwnProperty.call(error.response, "data") &&
                    error.response.data &&
                    Object.prototype.hasOwnProperty.call(
                        error.response.data,
                        "error"
                    ) &&
                    error.response.data.error
                ) {
                    if (error.response.status === 401) {
                        handleLogout();
                    }
                    reject(error.response.data);
                } else {
                    reject(error);
                }
            });
    });
};

export const ApiPut = (
    endpoint,
    data,
    headers
) => {
    return new Promise((resolve, reject) => {
        axios
            .put(
                BaseURL + endpoint,
                data,
                getHttpOptions(headers ?? defaultHeaders)
            )
            .then((responseJson) => {
                resolve(responseJson.data);
            })
            .catch((error) => {
                if (
                    error &&
                    Object.prototype.hasOwnProperty.call(error, "response") &&
                    error.response &&
                    Object.hasOwnProperty.call(error.response, "data") &&
                    error.response.data &&
                    Object.prototype.hasOwnProperty.call(
                        error.response.data,
                        "error"
                    ) &&
                    error.response.data.error
                ) {
                    if (error.response.status === 401) {
                        handleLogout();
                    }
                    reject(error.response.data);
                } else {
                    reject(error);
                }
            });
    });
};

export const ApiPatch = (
    endpoint,
    data,
    headers
) => {
    return new Promise((resolve, reject) => {
        axios
            .patch(
                BaseURL + endpoint,
                data,
                getHttpOptions(headers ?? defaultHeaders)
            )
            .then((responseJson) => {
                resolve(responseJson.data);
            })
            .catch((error) => {
                if (
                    error &&
                    Object.prototype.hasOwnProperty.call(error, "response") &&
                    error.response &&
                    Object.hasOwnProperty.call(error.response, "data") &&
                    error.response.data &&
                    Object.prototype.hasOwnProperty.call(
                        error.response.data,
                        "error"
                    ) &&
                    error.response.data.error
                ) {
                    if (error.response.status === 401) {
                        handleLogout();
                    }
                    reject(error.response.data);
                } else {
                    reject(error);
                }
            });
    });
};

export const ApiGetNoAuth = (endpoint) => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                BaseURL + endpoint,
                getHttpOptions({ ...defaultHeaders, isAuth: false })
            )
            .then((responseJson) => {
                resolve(responseJson.data);
            })
            .catch((error) => {
                if (
                    error &&
                    Object.prototype.hasOwnProperty.call(error, "response") &&
                    error.response &&
                    Object.hasOwnProperty.call(error.response, "data") &&
                    error.response.data &&
                    Object.prototype.hasOwnProperty.call(
                        error.response.data,
                        "error"
                    ) &&
                    error.response.data.error
                ) {
                    if (error.response.status === 401) {
                        handleLogout();
                    }
                    reject(error.response.data);
                } else {
                    reject(error);
                }
            });
    });
};

export const ApiPost = (
    endpoint,
    data,
    headers
) => {
    return new Promise((resolve, reject) => {
        return axios
            .post(
                BaseURL + endpoint,
                data,
                getHttpOptions(headers ?? defaultHeaders)
            )
            .then((responseJson) => {
                resolve(responseJson.data);
            })
            .catch((error) => {
                if (
                    error &&
                    Object.prototype.hasOwnProperty.call(error, "response") &&
                    error.response &&
                    Object.hasOwnProperty.call(error.response, "data") &&
                    error.response.data &&
                    Object.prototype.hasOwnProperty.call(
                        error.response.data,
                        "message"
                    ) &&
                    error.response.data.message
                ) {
                    if (error.response.status === 401) {
                        handleLogout();
                    }
                    reject(error.response.data.message);
                } else {
                    reject(error);
                }
            });
    });
};
export const ApiPostNoAuth = (
    endpoint,
    data
) => {
    return new Promise((resolve, reject) => {
        return axios
            .post(
                BaseURL + endpoint,
                data,
                getHttpOptions({ ...defaultHeaders, isAuth: false })
            )
            .then((responseJson) => {
                resolve(responseJson.data);
            })
            .catch((error) => {
                if (
                    error &&
                    Object.prototype.hasOwnProperty.call(error, "response") &&
                    error.response &&
                    Object.hasOwnProperty.call(error.response, "data") &&
                    error.response.data &&
                    Object.prototype.hasOwnProperty.call(
                        error.response.data,
                        "message"
                    ) &&
                    error.response.data.message
                ) {
                    reject(error.response.data.message);
                } else {
                    reject(error);
                }
            });
    });
};
