import React from 'react'
import { Outlet } from 'react-router-dom'
import './frizdaAds.css'

const FrizdaAdsWrapper = () => {
    return (
        <div className='w-100 h-100 overflow-y-auto'>
            <Outlet />
        </div>
    )
}

export default FrizdaAdsWrapper