import React, { useState, useContext } from 'react';
import axios from 'axios';
import { LoggedInContext } from '../businessStates&Auth/businessAuthState';
// ↓ External JSX components
import BarLoader from 'react-spinners/BarLoader';
import FrizdaLogo from '../../styling/images/frizda-badges/frizda-usual.svg';

export default function ChangePassword(props) {

    const { accountId } = useContext(LoggedInContext);
    // let databasePassword;
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [meetsRequirements, setMeetsRequirements] = useState(true);
    const [passwordsAreTheSame, setPasswordsAreTheSame] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [changeSuccesful, setChangeSuccesful] = useState(null);
    const [oldPasswordMatches, setOldPasswordMatches] = useState(true);

    async function changePassword(event) {
        event.preventDefault();
        setLoading(true);

        const passwords = {
            currentPassword: currentPassword,
            newPassword: newPassword
        }

        if (newPassword === currentPassword) {
            setPasswordsAreTheSame(true);
            setTimeout(() => { setPasswordsAreTheSame(false) }, 25000);
        }
        // ↓ Check if newPassword meets requirements
        else if (newPassword.length < 8) {
            setMeetsRequirements(false);
        } else if (!/\d/.test(newPassword)) {
            setMeetsRequirements(false);
        } else if (!/[/*!]/.test(newPassword)) {
            setMeetsRequirements(false);
        } else {
            await axios.put(`https://polar-hamlet-90480-1e735d673784.herokuapp.com/checkPassword/${accountId}`, passwords)
                .then(res => {
                    console.log(res);
                    if (res.status === 200) {
                        setChangeSuccesful(true); setMeetsRequirements(true); setNewPassword(''); setCurrentPassword('');
                    }
                }).catch(err => {
                    if (err.response.status === 401) {
                        setOldPasswordMatches(false);
                        setTimeout(() => { setOldPasswordMatches(true) }, 25000);
                    }
                });
        }

        setLoading(false);
    }


    return (
        <div className='p-5'>
            <p className='fs-2'>Change password</p>

            <form onSubmit={changePassword} className='position-relative'>
                <label className='form-label'>Old password</label>
                <input className='form-control mb-1' type={showPassword ? 'text' : 'password'} onChange={e => setCurrentPassword(e.target.value)} value={currentPassword} disabled={changeSuccesful} />
                {!oldPasswordMatches && <p className='text-danger'>Password is incorrect</p>}

                <label className='form-label mt-4'>New password</label>
                <input className='form-control' type={showPassword ? 'text' : 'password'} onChange={e => setNewPassword(e.target.value)} value={newPassword} disabled={changeSuccesful} />

                {passwordsAreTheSame && <p className='text-warning'>New password can't be the same as entered password</p>}
                {!meetsRequirements && <p className='text-danger'>New password does not meet the requirements</p>}

                <div className='form-check mt-3'>
                    <input className='form-check-input' type='checkbox' onClick={() => setShowPassword(!showPassword)} />
                    <label className='form-check-label'>Show passwords</label>
                </div>

                <div className='row mt-5'>
                    <div className='col-5 d-flex align-items-start gap-3'>
                        <button type='submit' className='btn btn-frizda btn-lg'>Change</button>
                        {loading && <BarLoader color="#B637FB" speedMultiplier={3} width={50} />}
                    </div>
                    <div className='col-7'>
                        <p className='password-requirements'>Password must contain:</p>
                        <ul className={`${!meetsRequirements && 'text-warning'}`}>
                            <li>8 characters</li>
                            <li id='numberRequirement'>1 number</li>
                            <li id='specialCharacterRequirement'>1 special character ( / , * , ! )</li>
                        </ul>
                    </div>
                </div>
            </form>

            {changeSuccesful === true ?
                <div className='border rounded-2 mt-5 text-bg-success d-flex p-5 position-relative'>
                    <p className='fs-4 my-auto'>Your password has succesfully changed</p>
                    <img alt='frizda-logo' src={FrizdaLogo} style={{ width: '50px', margin: '25px' }} className='position-absolute end-0 bottom-0' />
                </div>
                : changeSuccesful === false &&
                <div className='border rounded-2 mt-5 text-bg-warning d-flex flex-column p-5 position-relative'>
                    <p className='fs-4 my-auto'>
                        The server is experiencing high demand right now, try again later.
                    </p>
                    <p className='fs-5 mt-3'>
                        In the name of the Frizda team, we apologise for the arosen inconviniences.
                    </p>
                    <img alt='frizda-logo' src={FrizdaLogo} style={{ width: '50px', margin: '25px' }} className='position-absolute end-0 bottom-0' />
                </div>
            }

        </div>
    )
}