import React from 'react';
import '../src/styling/styles/frizda-map-styling/frizda-map.css' // ← This react component CSS styiling file
import Mapbox from './Mapbox-API';



export default function FrizdaMap(props) {
    return (
        <section id='frizda-map-section'>
            <div className='col-12 upper-filters-map'>

                <div className='col-2 h-100 d-flex justify-content-center align-items-center'>
                    <a className='navbar-brand' href='/'>Frizda</a>
                </div>

            </div>

            <div className='row'>
                <div className='col-2 start-filters-map'>
                    <h4>Filters</h4>
                </div>

                <Mapbox />
            </div>
        </section>
    )
}